/* eslint-disable no-shadow */

export enum FormStatus {
	pending = 'pending',
	contacted = 'contacted',
	inValidation = 'inValidation',
	approved = 'approved',
	uploadReceipt = 'uploadReceipt',
	scheduled = 'scheduled',
	reviewed = 'reviewed',
	completed = 'completed',
	expired = 'expired',
	rejected = 'rejected',
	canceled = 'canceled',
}
export const FormStatusValues: {
	[key in FormStatus]: {
		title: string;
		color: string;
		tooltip: string;
		order: number;
	};
} = {
	[FormStatus.pending]: {
		title: 'En espera',
		color: 'primary_200',
		tooltip: 'Solicitud en proceso con el vendedor',
		order: 1,
	},
	[FormStatus.contacted]: {
		title: 'Contactado',
		color: 'primary_200',
		tooltip: 'Solicitud contactada',
		order: 2,
	},
	[FormStatus.inValidation]: {
		title: 'Validación',
		color: 'yellow_100',
		tooltip: 'Solicitud pendiente de validación de crédito',
		order: 3,
	},
	[FormStatus.approved]: {
		title: 'Aprobada',
		color: 'green_300',
		tooltip: 'Solicitud aprobada para crédito',
		order: 4,
	},
	[FormStatus.rejected]: {
		title: 'Rechazada',
		color: 'red_300',
		tooltip: 'Solicitud rechazada para crédito',
		order: 4,
	},
	[FormStatus.scheduled]: {
		title: 'Agendada',
		color: 'primary_400',
		tooltip: 'Solicitud agendada para entrega',
		order: 5,
	},
	[FormStatus.uploadReceipt]: {
		title: 'Apartado',
		color: 'primary_400',
		tooltip: 'Solicitud con un vehiculo apartado',
		order: 6,
	},
	[FormStatus.reviewed]: {
		title: 'Revisado',
		color: 'primary_400',
		tooltip: 'Solicitud revisada por el area de credito',
		order: 7,
	},
	[FormStatus.completed]: {
		title: 'Completada',
		color: 'secondary_500',
		tooltip: 'Solicitud completada con un crédito',
		order: 8,
	},
	[FormStatus.expired]: {
		title: 'Caducadas',
		color: 'neutral_600',
		tooltip: 'Solicitud expirada por tiempo de inactividad',
		order: 4,
	},
	[FormStatus.canceled]: {
		title: 'Cancelada',
		color: 'neutral_900',
		tooltip: 'Solicitud cancelada por peticion del ozoner',
		order: 4,
	},
};

export enum PartnershipsEnum {
	Uber = 'Uber',
	Didi = 'Didi',
	SinAlianza = 'Sin Alianza',
}

export enum SalesRankEnum {
	diamond = 'diamond',
	platinum = 'platinum',
	gold = 'gold',
	silver = 'silver',
	bronze = 'bronze',
	wood = 'wood',
}

export const salesRankValues: {
	[key in SalesRankEnum]: {
		title: string;
		color: string;
		tooltip: string;
	};
} = {
	[SalesRankEnum.diamond]: {
		title: 'Diamante',
		color: 'diamond_color',
		tooltip:
			'En esta segmentacion estan los ozoners que cargaron por su cuenta el ine, selfie, comprobante de domicilio y al menos un comprobante de ingresos',
	},
	[SalesRankEnum.platinum]: {
		title: 'Platino',
		color: 'platinum_color',
		tooltip:
			'En esta segmentacion estan los ozoners que cargaron por su cuenta el ine, selfie, comprobante de domicilio y que son repartidores',
	},
	[SalesRankEnum.gold]: {
		title: 'Oro',
		color: 'gold_color',
		tooltip:
			'En esta segmentacion estan los ozoners que cargaron por su cuenta el ine, selfie, comprobante de domicilio y al menos un comprobante de ingresos',
	},
	[SalesRankEnum.silver]: {
		title: 'Plata',
		color: 'silver_color',
		tooltip:
			'En esta segmentacion estan los ozoners que cargaron por su cuenta el ine, selfie y comprobante de domicilio',
	},
	[SalesRankEnum.bronze]: {
		title: 'Bronce',
		color: 'bronze_color',
		tooltip:
			'En esta segmentacion estan los ozoners que cargaron por su cuenta el ine y selfie',
	},
	[SalesRankEnum.wood]: {
		title: 'Madera',
		color: 'wood_color',
		tooltip:
			'En esta segmentacion estan los ozoners que no cargaron por su cuenta ni el ine ni la selfie',
	},
};

export enum RejectedReason {
	BCScore = 'BCScore',
	LessThanThreeMonthsEmployed = 'LessThanThreeMonthsEmployed',
	DeliveryPersonUsingOthersAccount = 'DeliveryPersonUsingOthersAccount',
	InformationInconsistencies = 'InformationInconsistencies',
	RejectionScore = 'RejectionScore',
	PaymentCapacity = 'PaymentCapacity',
	Truora = 'Truora',
	Fraud = 'Fraud',
	Zoning = 'Zoning',
	Seon = 'Seon',
	PreviousRecovery = 'PreviousRecovery',
	Unemployment = 'Unemployment',
	Address = 'Address',
	DocumentsFromOtherPeople = 'DocumentsFromOtherPeople',
	Age = 'Age',
	Occupation = 'Occupation',
	OzonersTypeD = 'OzonersTypeD',
	TemporaryResidentForeigners = 'TemporaryResidentForeigners',
	ForgedDocuments = 'ForgedDocuments',
	Trully = 'Trully',
}
export const RejectedReasonValues = {
	[RejectedReason.BCScore]: 'BC Score',
	[RejectedReason.PaymentCapacity]: 'Capacidad de pago',
	[RejectedReason.Unemployment]: 'Desempleo',
	[RejectedReason.Address]: 'Dirección erronea',
	[RejectedReason.DocumentsFromOtherPeople]: 'Documentos de otra persona',
	[RejectedReason.ForgedDocuments]: 'Documentos falsificados',
	[RejectedReason.Age]: 'Edad',
	[RejectedReason.TemporaryResidentForeigners]:
		'Extranjeros con residencia temporal',
	[RejectedReason.Fraud]: 'Fraude',
	[RejectedReason.InformationInconsistencies]:
		'Inconsistencias en la información',
	[RejectedReason.Occupation]: 'Ocupación',
	[RejectedReason.OzonersTypeD]: 'Ozoner tipo D',
	[RejectedReason.PreviousRecovery]: 'Recuperación previa',
	[RejectedReason.RejectionScore]: 'Score Ozon',
	[RejectedReason.Seon]: 'Seon',
	[RejectedReason.LessThanThreeMonthsEmployed]:
		'Tiene menos de 3 meses en el empleo',
	[RejectedReason.DeliveryPersonUsingOthersAccount]:
		'Trabaja desde la cuenta de otra persona',
	[RejectedReason.Trully]: 'Trully',
	[RejectedReason.Truora]: 'Truora',
	[RejectedReason.Zoning]: 'Zonificación',
};

export enum DevolutionReason {
	incompleteData = 'incompleteData',
	incompleteDocuments = 'incompleteDocuments',
	expiredDocuments = 'expiredDocuments',
}

export const DevolutionReasonValues = {
	[DevolutionReason.incompleteData]: 'Informacion incompleta',
	[DevolutionReason.incompleteDocuments]: 'Documentos incompletos',
	[DevolutionReason.expiredDocuments]: 'Documentos fuera de los 3 meses',
};

export enum CanceledReason {
	Profession = 'Profession',
	Zoning = 'Zoning',
	UnverifiableIncome = 'UnverifiableIncome',
	LessThanThreeMonthsEmployed = 'LessThanThreeMonthsEmployed',
	NoResponse = 'NoResponse',
	LossOfInterest = 'LossOfInterest',
}

export const CanceledReasonValues = {
	[CanceledReason.Profession]: 'Por profesión',
	[CanceledReason.Zoning]: 'Por zonificación',
	[CanceledReason.UnverifiableIncome]: 'Por ingresos no verificables',
	[CanceledReason.LessThanThreeMonthsEmployed]: 'Menos de 3 meses en el empleo',
	[CanceledReason.NoResponse]: 'Sin respuesta',
	[CanceledReason.LossOfInterest]: 'Perdida de interés',
};

export enum ContactMethodsEnum {
	email = 'email',
	whatsApp = 'whatsApp',
	call = 'call',
	inSite = 'inSite',
}
export const ContactMethodsValues = {
	[ContactMethodsEnum.email]: 'Email',
	[ContactMethodsEnum.whatsApp]: 'Whatsapp',
	[ContactMethodsEnum.call]: 'Llamada',
	[ContactMethodsEnum.inSite]: 'Presencial',
};

export enum LoanSegmentationEnum {
	ClientesGenerales = 'Clientes generales',
	GoNuts = 'Go Nuts',
	Walmart = 'Walmart',
}

export enum CollectionSegmentationEnum {
	GeneralClients = 'Clientes generales',
	NewPurchases = 'Nuevas Compras',
	WO2023 = 'WO 2023',
	Restructuring = 'Reestructuracion',
	Restructured = 'Reestructurado',
	Clients15Interest = 'Clientes 15 intereses',
	Clients30Interest = 'Clientes 30 Intereses',
}

export enum FinancialFormTypeEnum {
	fullPayment = 'FullPaymentFinancialForm',
	loan = 'LoanFinancialForm',
}

export enum PartnershipPrefixes {
	Walmart = 'W',
}

export enum EconomicActivityEnum {
	empleado = 'empleado',
	repartidor = 'repartidor',
	comerciante = 'comerciante',
	conductor = 'conductor',
	conductorRepartidor = 'conductorRepartidor',
	ayudanteGeneral = 'ayudanteGeneral',
	seguridad = 'seguridad',
	independiente = 'independiente',
	estudiante = 'estudiante',
	policia = 'policia',
	servidorPublico = 'servidorPublico',
	enfermero = 'enfermero',
	mecanico = 'mecanico',
	otro = 'otro',
}

export enum EducationalLevelEnum {
	primary = 'Primaria',
	secondary = 'Secundaria',
	HighSchool = 'Preparatoria',
	AssociateDegree = 'Tecnicatura',
	BachelorDegree = 'Licenciatura',
	MasterDegree = 'Maestria',
}

export enum CivilStatusEnum {
	single = 'Solter@',
	married = 'Casad@',
	Cohabitation = 'Union Libre',
	widower = 'Viud@',
}

export enum LivesWithEnum {
	alone = 'Solo',
	Spouse = 'Esposo (a)',
	friends = 'Amigos',
	parents = 'Padres',
	couple = 'Pareja Estable',
	Siblings = 'Hermanos',
	children = 'Hijos',
}

export const financialFormFields = {
	fullAddress: 'dirección del ozoner',
	loanDuration: 'semanas de credito',
	advancedMoney: 'pago por adelantado',
	rejectedReason: 'motivo de rechazo',
	canceledReason: 'motivo de cancelación',
	devolutionReason: 'motivo de devolución',
	partnership: 'alianza',
	approvedWeeklyPayment: 'cuota maxima aprobada',
	collectionSegmentation: 'segmentacion de cobranza',
	collectionResponsible: 'responsale de cobranza',
	loanSegmentation: 'segmentacion de credito',
	discountId: 'descuento',
	deposit: 'deposito',
	salesRank: 'segmentacion',
	cashiPartnerReferenceId: 'alizanza cashi',
	appointment: 'cita de entrega',
	contactMethod: 'metodo de contacto',
	isDataVerified: 'verificacion de cobranza',
	sellResponsible: 'vendedor asignado',
	phone: 'telefono',
	dependantsCount: 'dependientes',
	civilStatus: 'estado civil',
	educationalLevel: 'nivel de educacion',
	livesWith: 'vive con',
	childrenCount: 'hijos',
	personalReferences: 'referencias personales',
	monthlyIncome: 'ingreso mensual',
	monthlyOutcome: 'egreso mensual',
	companyName: 'empresa',
	economicActivity: 'actividad economico',
	workInDigitalPlatforms: 'trabaja en plataformas digitales',
	assets: 'activos',
	createdAt: 'Fecha de creación',
	updatedAt: 'Fecha de actualización',
	termsOfServiceAcceptance: 'termios de servicio',
	privacyPolicyAcceptance: 'politica de privacidad',
	utm: 'UTM',
};
