export interface postalCodeResponse {
	postalCode: string;
	neighborhood: string;
	neighborhoodType: string;
	municipality: string;
	state: string;
	city: string;
	stateCode: string;
	officePostalCode: string;
	neighborhoodTypeCode: string;
	municipalityCode: string;
	neighborhoodId: string;
	zone: string;
	cityCode: string;
	error?: string;
}

export interface MoffinValidation {
	_id: string;
	formId: string;
	bcScore?: number;
	phoneVerifiedAt?: string;
}

export const stateMapping: any = {
	'01': 'AGU',
	'02': 'BCN',
	'03': 'BCS',
	'04': 'CAM',
	'05': 'COA',
	'06': 'COL',
	'07': 'CHP',
	'08': 'CHH',
	'09': 'CMX',
	'10': 'DUR',
	'11': 'GUA',
	'12': 'GRO',
	'13': 'HID',
	'14': 'JAL',
	'15': 'MEX',
	'16': 'MIC',
	'17': 'MOR',
	'18': 'NAY',
	'19': 'NLE',
	'20': 'OAX',
	'21': 'PUE',
	'22': 'QUE',
	'23': 'ROO',
	'24': 'SLP',
	'25': 'SIN',
	'26': 'SON',
	'27': 'TAB',
	'28': 'TAM',
	'29': 'TLA',
	'30': 'VER',
	'31': 'YUC',
	'32': 'ZAC',
};
