/* eslint-disable no-nested-ternary */
import React, { useState, useContext, useEffect } from 'react';

// -----------------LIBRARIES IMPORTS------------------- //
import {
	Button,
	Input,
	ModalConfirmation,
	Typography,
} from 'design_system/src';

// -----------------DATA IMPORTS------------------- //

import { handleEventGoogle } from 'models/shared/helpers/googleAnalytics';

// -----------------STATIC IMPORTS------------------- //
import '../../../ozoner.scss';
import { ReactComponent as Edit } from 'design_system/src/static/icons/edit-text.svg';
import { ReactComponent as Money } from 'design_system/src/static/icons/money.svg';
import { ReactComponent as Check } from 'design_system/src/static/icons/checkmark.svg';
import { ReactComponent as Eye } from 'design_system/src/static/icons/eye.svg';
import { ReactComponent as Reload } from 'design_system/src/static/icons/reload.svg';
import { ReactComponent as Close } from 'design_system/src/static/icons/close-lg.svg';
import { ReactComponent as AddFiles } from 'design_system/src/static/icons/upload-bracket.svg';

// -----------------COMPONENTS IMPORTS------------------- //
import { Grid } from '@mui/material';
import { toasterOptions } from 'models/shared/enums/toaster.enum';
import Dropzone from 'react-dropzone';
import Swal from 'sweetalert2';
import { priceMXNTotal } from 'models/shared/helpers/prices';
import {
	deleteFileFinancialForm,
	updateFinancialForm,
	uploadFileFinancialForm,
} from 'models/financial-form/controllers/financial-form.controller';
import { toasterNotification } from 'models/shared/helpers/toasterNotification';
import { EvidenceDocumentType } from 'models/shared/types/documents';
import { ozonerContext } from '../../OzonerContext';

const formatName = (name: string) => name.replaceAll(' ', '_');

export function SolicitudDeposit() {
	const { financialForm, updateFormData } = useContext(ozonerContext);

	const [openDeposit, setOpenDeposit] = useState(false);
	const [depositAmount, setDepositAmount] = useState<number | undefined>();

	const changeDeposit = () => {
		if (financialForm?.form) {
			updateFinancialForm(financialForm.form._id, {
				loanApplication: {
					deposit: depositAmount,
				},
			})
				.then((newForm) => {
					toasterNotification({
						msg: 'Deposito actualizado',
						style: 'light',
						toasterType: toasterOptions.success,
					});
					updateFormData(newForm);
				})
				.catch((e) => {
					toasterNotification({
						msg: e ?? 'Ocurrio un error',
						toasterType: toasterOptions.error,
						style: 'light',
					});
				});
		}
	};

	return (
		<>
			<div
				id="deposito-credit"
				className="p_md border_b_solid border_1 border_neutral_400 pos_relative"
			>
				<Typography
					scale="medium"
					weight="400"
					className=""
					textColor="neutral_800"
				>
					Depósito
				</Typography>
				<Typography
					scale="large"
					weight="600"
					textColor="neutral_800"
					className=""
				>
					{financialForm?.form?.loanApplication?.deposit !== undefined
						? priceMXNTotal(financialForm.form.loanApplication.deposit, 2)
						: '      -- MXN'}
				</Typography>
				<Button
					onClick={() => setOpenDeposit(true)}
					variant="icon"
					icon={<Edit />}
					subvariant="edit"
					scale="small"
					className="edit-button-deposit"
				/>
			</div>
			{openDeposit && (
				<ModalConfirmation
					openConfirmation={openDeposit}
					handleClose={() => {
						setOpenDeposit(false);
						setDepositAmount(undefined);
					}}
					icon={<Edit />}
					isConfirmationLoading={false}
					widthDesktop={30}
					actions={[
						{
							label: 'Cancelar',
							color: 'grey',
							action: () => {
								setOpenDeposit(false);
								setDepositAmount(undefined);
							},
						},
						{
							label: 'Guardar',
							color: 'primary',
							action: () => {
								setOpenDeposit(false);
								changeDeposit();
							},
						},
					]}
				>
					<>
						<Typography scale="large" weight="600" className="m_b_md">
							¿Quieres modificar el valor depósito?
						</Typography>
						<Typography
							scale="medium"
							weight="400"
							className="m_b_md text_center"
						>
							Selecciona valor del depósito
						</Typography>

						<Input
							type="number"
							onChange={(e: any) =>
								setDepositAmount(parseInt(e.target.value, 10))
							}
							value={depositAmount}
							title="Valor de depósito"
							icon={<Money />}
							name="amount"
							placeholder="Escribe el valor del depósito aquí"
						/>
					</>
				</ModalConfirmation>
			)}
		</>
	);
}
export function SolicitudAdvanced() {
	const { financialForm, updateFormData } = useContext(ozonerContext);

	const [openAdvance, setOpenAdvance] = useState(false);
	const [advanceAmount, setAdvanceAmount] = useState<any>('');

	const [recipt, setRecipt] = useState<any>();
	const [openConfirmation, setOpenConfirmation] = useState(false);

	useEffect(() => {
		const url = financialForm?.form?.documents?.filter(
			(ele) => ele.typeEvidence === EvidenceDocumentType.advancedmoney,
		);
		if (url && url.length > 0) {
			setRecipt({
				url: url[0].url,
				_id: url[0]._id,
			});
		} else {
			setRecipt(null);
		}
	}, [financialForm.form]);

	const handleClose = () => setOpenConfirmation(!openConfirmation);

	const deleteReceipt = () => {
		handleClose();
		if (financialForm?.form && recipt)
			deleteFileFinancialForm(financialForm.form._id, recipt._id).then(
				(newData) => {
					updateFormData(newData);
				},
			);
	};

	const addReceipt = async (acceptedFiles: any[]) => {
		const file = acceptedFiles[0];
		if (file.size > 10000000) {
			Swal.fire({
				text: 'La imagen no debe ser superior a 10mb',
				icon: 'warning',
				confirmButtonText: 'Aceptar',
			});
		} else if (financialForm?.form) {
			const blob = file.slice(0, file.size, file.type);
			const newFile = new File([blob], formatName(file.name), {
				type: file.type,
			});
			// eslint-disable-next-line no-restricted-syntax
			if (financialForm.form?.documents) {
				await Promise.all(
					financialForm.form.documents
						.filter(
							(ele) => ele.typeEvidence === EvidenceDocumentType.advancedmoney,
						)
						.map((ele) =>
							deleteFileFinancialForm(financialForm.form._id, ele._id),
						),
				);
			}

			uploadFileFinancialForm(
				financialForm.form._id,
				newFile,
				EvidenceDocumentType.advancedmoney,
			)
				.then((newData) => {
					updateFormData(newData);
					toasterNotification({
						msg: 'El comprobante de pago por adelantado se cargo correctamente',
						style: 'light',
						toasterType: toasterOptions.success,
					});
				})
				.catch((err) => {
					toasterNotification({
						msg: err.message,
						style: 'light',
						toasterType: toasterOptions.error,
					});
				});
		}
	};

	const changeAdvance = () => {
		if (financialForm?.form) {
			updateFinancialForm(financialForm.form._id, {
				loanApplication: {
					advancedMoney: advanceAmount,
				},
			})
				.then((newForm) => {
					toasterNotification({
						msg: 'Pago por adelantado actualizado',
						style: 'light',
						toasterType: toasterOptions.success,
					});
					updateFormData(newForm);
				})
				.catch((e) => {
					toasterNotification({
						msg: e ?? 'Ocurrio un error',
						toasterType: toasterOptions.error,
						style: 'light',
					});
				});
		}
	};

	return (
		<>
			<div
				id="advanced-credit"
				className="p_md border_b_solid border_1 border_neutral_400 pos_relative"
			>
				<Typography
					scale="medium"
					weight="400"
					className=""
					textColor="neutral_800"
				>
					Pago por adelantado
				</Typography>
				<Typography
					scale="large"
					weight="600"
					textColor="neutral_800"
					className=""
				>
					{financialForm?.form?.loanApplication?.advancedMoney !== undefined
						? priceMXNTotal(financialForm.form.loanApplication.advancedMoney, 2)
						: '      -- MXN'}
				</Typography>
				<Button
					onClick={() => setOpenAdvance(true)}
					variant="icon"
					icon={<Edit />}
					subvariant="edit"
					scale="small"
					className="edit-button-deposit"
				/>
			</div>
			{financialForm.form.loanApplication?.advancedMoney ? (
				<div
					id="advance-payment-credito"
					className="p_md border_b_solid border_1 border_neutral_400 display_flex flex_col flex_gap_xs"
				>
					<Dropzone onDrop={addReceipt}>
						{({ getRootProps, getInputProps }) =>
							recipt ? (
								<div className="flex_col display_flex h_100_per">
									<div className="p_x_md m_b_sm">
										<div className="flex_center_col flex_gap_xs m_b_sm">
											<div className="p_xxs br_circle bg_primary_200 display_flex">
												<Check className="text_neutral_0 dim_sm" />
											</div>
											<Typography
												scale="small"
												weight="600"
												className="text_center"
											>
												Comprobante de pago por adelantado
											</Typography>
										</div>
										<div className="flex_center flex_gap_lg">
											<Button
												variant="icon"
												subvariant="link"
												icon={<Eye />}
												scale="small"
												onClick={() => window.open(recipt.url, '_blank')}
											>
												Ver Comprobante
											</Button>
											<div {...getRootProps()} className="">
												<input {...getInputProps()} />
												<Button
													className="w_100_per"
													variant="icon"
													subvariant="link"
													icon={<Reload />}
													scale="small"
													color="neutral"
												>
													Cambiar comprobante
												</Button>
											</div>
											<Button
												variant="icon"
												subvariant="link"
												icon={<Close />}
												scale="small"
												onClick={() => handleClose()}
											>
												Borrar Comprobante
											</Button>
											<ModalConfirmation
												handleClose={handleClose}
												openConfirmation={openConfirmation}
												onConfirmationCallBack={() => deleteReceipt()}
												title={
													<Typography
														scale="medium"
														weight="400"
														textColor="neutral_900"
													>
														¿Estás seguro de que quieres
														<span
															style={{
																fontWeight: 'bolder',
															}}
														>
															{' '}
															eliminar el comprobante de pago por adelantado?
														</span>
													</Typography>
												}
												subtitle={
													<Typography
														scale="xsmall"
														weight="400"
														textColor="neutral_900"
													>
														Se borrará y
														<span
															style={{
																fontWeight: 'bolder',
															}}
														>
															{' '}
															no podrá ser recuperado de nuevo.
														</span>
													</Typography>
												}
												copyReject="Cancelar"
												copyConfirm="Eliminar"
											/>
										</div>
									</div>
								</div>
							) : (
								<div className="p_xs h_100_per">
									<div
										{...getRootProps()}
										className=" comprobante_dropzone p_md flex_center_col h_100_per text_primary_300 cursor_pointer"
									>
										<input {...getInputProps()} />
										<AddFiles className="dim_xl m_b_xs border_none" />
										<Typography
											scale="small"
											weight="400"
											className="text_center"
										>
											Comprobante de pago por adelantado
										</Typography>
									</div>
								</div>
							)
						}
					</Dropzone>
				</div>
			) : null}
			{openAdvance && (
				<ModalConfirmation
					openConfirmation={openAdvance}
					handleClose={() => {
						setOpenAdvance(false);
						setAdvanceAmount(null);
					}}
					icon={<Edit />}
					isConfirmationLoading={false}
					widthDesktop={30}
					actions={[
						{
							label: 'Cancelar',
							color: 'grey',
							action: () => {
								setOpenAdvance(false);
								setAdvanceAmount('');
							},
						},
						{
							label: 'Guardar',
							color: 'primary',
							action: () => {
								setOpenAdvance(false);
								changeAdvance();
							},
						},
					]}
				>
					<>
						<Typography scale="large" weight="600" className="m_b_md">
							¿Quieres modificar el valor del pago por adelantado?
						</Typography>
						<Typography
							scale="medium"
							weight="400"
							className="m_b_md text_center"
						>
							Selecciona valor del pago por adelantado
						</Typography>

						<Input
							type="number"
							onChange={(e: any) =>
								setAdvanceAmount(parseInt(e.target.value, 10))
							}
							value={advanceAmount}
							title="Valor de pago por adelantado"
							icon={<Money />}
							name="amount"
							placeholder="Escribe el valor del pago por adelantado aquí"
						/>
					</>
				</ModalConfirmation>
			)}
		</>
	);
}

export function SolicitudApartado() {
	const { financialForm, updateFormData, setOpenVehicle } =
		useContext(ozonerContext);
	const [recipt, setRecipt] = useState<any>();
	const [openConfirmation, setOpenConfirmation] = useState(false);

	useEffect(() => {
		const url = financialForm?.form?.documents?.filter(
			(ele: any) => ele.typeEvidence === EvidenceDocumentType.layawayReceipt,
		);
		if (url && url.length > 0) {
			setRecipt({
				url: url[0].url,
				_id: url[0]._id,
			});
		} else {
			setRecipt(null);
		}
	}, [financialForm.form]);

	const handleClose = () => setOpenConfirmation(!openConfirmation);

	const deleteReceipt = () => {
		handleClose();
		if (financialForm?.form && recipt)
			deleteFileFinancialForm(financialForm.form._id, recipt._id).then(
				(newData) => {
					updateFormData(newData);
				},
			);
	};

	const addReceipt = async (acceptedFiles: any[]) => {
		const file = acceptedFiles[0];
		if (file.size > 10000000) {
			Swal.fire({
				text: 'La imagen no debe ser superior a 10mb',
				icon: 'warning',
				confirmButtonText: 'Aceptar',
			});
		} else if (financialForm?.form) {
			const blob = file.slice(0, file.size, file.type);
			const newFile = new File([blob], formatName(file.name), {
				type: file.type,
			});
			// eslint-disable-next-line no-restricted-syntax
			if (financialForm.form?.documents) {
				await Promise.all(
					financialForm.form.documents
						.filter(
							(ele) => ele.typeEvidence === EvidenceDocumentType.layawayReceipt,
						)
						.map((ele) =>
							deleteFileFinancialForm(financialForm.form._id, ele._id),
						),
				);
			}

			uploadFileFinancialForm(
				financialForm.form._id,
				newFile,
				EvidenceDocumentType.layawayReceipt,
			)
				.then((newData) => {
					updateFormData(newData);
					toasterNotification({
						msg: 'El estado de la moto asociada pasará ha apartado ',
						style: 'light',
						toasterType: toasterOptions.success,
					});
				})
				.catch((err) => {
					if (
						err.statusCode === 400 &&
						err.message === 'Vehicle not available'
					) {
						setOpenVehicle(true);
						toasterNotification({
							msg: 'El vehiculo ya no esta disponible',
							style: 'light',
							toasterType: toasterOptions.error,
						});
					} else {
						toasterNotification({
							msg: err.message,
							style: 'light',
							toasterType: toasterOptions.error,
						});
					}
				});
		}
	};
	return (
		<div
			id="apartado-credito"
			className="p_md border_b_solid border_1 border_neutral_400 display_flex flex_col flex_gap_xs"
		>
			<Dropzone onDrop={addReceipt}>
				{({ getRootProps, getInputProps }) =>
					recipt ? (
						<div className="flex_col display_flex h_100_per">
							<div className="p_x_md m_b_sm">
								<div className="flex_center_col flex_gap_xs m_b_sm">
									<div className="p_xxs br_circle bg_primary_200 display_flex">
										<Check className="text_neutral_0 dim_sm" />
									</div>
									<Typography
										scale="small"
										weight="600"
										className="text_center"
									>
										Comprobante de pago de apartado
									</Typography>
								</div>
								<div className="flex_center flex_gap_lg">
									<Button
										variant="icon"
										subvariant="link"
										icon={<Eye />}
										scale="small"
										onClick={() => window.open(recipt.url, '_blank')}
									>
										Ver Comprobante
									</Button>
									<div {...getRootProps()} className="">
										<input {...getInputProps()} />
										<Button
											className="w_100_per"
											variant="icon"
											subvariant="link"
											icon={<Reload />}
											scale="small"
											color="neutral"
										>
											Cambiar comprobante
										</Button>
									</div>
									<Button
										variant="icon"
										subvariant="link"
										icon={<Close />}
										scale="small"
										onClick={() => handleClose()}
									>
										Borrar Comprobante
									</Button>
									<ModalConfirmation
										handleClose={handleClose}
										openConfirmation={openConfirmation}
										onConfirmationCallBack={() => deleteReceipt()}
										title={
											<Typography
												scale="medium"
												weight="400"
												textColor="neutral_900"
											>
												¿Estás seguro de que quieres
												<span
													style={{
														fontWeight: 'bolder',
													}}
												>
													{' '}
													eliminar el comprobante de pago de apartado?
												</span>
											</Typography>
										}
										subtitle={
											<Typography
												scale="xsmall"
												weight="400"
												textColor="neutral_900"
											>
												Se borrará y
												<span
													style={{
														fontWeight: 'bolder',
													}}
												>
													{' '}
													no podrá ser recuperado de nuevo.
												</span>
											</Typography>
										}
										copyReject="Cancelar"
										copyConfirm="Eliminar"
									/>
								</div>
							</div>
						</div>
					) : (
						<div className="p_xs h_100_per">
							<div
								{...getRootProps()}
								className=" comprobante_dropzone p_md flex_center_col h_100_per text_primary_300 cursor_pointer"
							>
								<input {...getInputProps()} />
								<AddFiles className="dim_xl m_b_xs border_none" />
								<Typography scale="small" weight="400" className="text_center">
									Comprobante de pago apartado
								</Typography>
							</div>
						</div>
					)
				}
			</Dropzone>
		</div>
	);
}
