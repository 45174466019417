/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-plusplus */
import React, { useEffect, useState, createContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

// -----------------LIBRARIES IMPORTS------------------- //
import Swal from 'sweetalert2';
import moment from 'moment';
import 'moment/locale/es';

// -----------------DATA IMPORTS------------------- //

// -----------------STATIC IMPORTS------------------- //
import '../ozoner.scss';
import { ReactComponent as ApproveIcon } from 'design_system/src/static/icons/verified-user.svg';
import { ReactComponent as ContactIcon } from 'design_system/src/static/icons/phone.svg';
import profileDefaultOzoner from 'static/images/default_profile_ozoner.png';
import { Ozoner } from 'models/ozoner/interfaces/ozoner.interface';
import {
	findOneOzoner,
	getOzonerByVehicle,
	updateOzoner,
} from 'models/ozoner/controllers/ozoner.controller';
import { EvidenceDocumentType } from 'models/shared/types/documents';
// import { displayQuota } from './DetailsOzoner';
import { GenericFinancialForm } from 'models/financial-form/interfaces/financial-form.interfaces';
import { findAllFinancialFormsOzoner } from 'models/financial-form/controllers/financial-form.controller';
import { findLoanByOzoner } from 'models/loan/controllers/loan.controller';
import { LoanWithExtraFields } from 'models/loan/interfaces/loan.interface';
import { findRepossessionByLoan } from 'models/repossession/controllers/repossession.controller';
import { Repossession } from 'models/repossession/interfaces/repossession.interface';
import { RepossessionStatus } from 'models/repossession/enums/repossession.enums';
import {
	getQuotaVehicle,
	getVehicleByInternalId,
} from 'models/inventory/controllers/vehicle.controller';
import { getVehicleCurrentPrice } from 'models/shared/helpers/getVehicleCurrentPrice';
import {
	SalePrices,
	VehiclePopulated,
} from 'models/inventory/interfaces/vehicle.interface';
import { FormStatus } from 'models/financial-form/enums/financial-form.enums';
import { findAllOzonios } from 'models/ozonio/controllers/ozonios.controller';
import { Ozonio } from 'models/ozonio/interfaces/ozonio.interface';
import { getMoffinValidation } from 'models/shared/controllers/moffin';
import { MoffinValidation } from 'models/shared/types/moffin';

export interface AvailableForm {
	loan?: LoanWithExtraFields;
	form: GenericFinancialForm;
	vehicle?: VehiclePopulated;
	vehiclePrice: number;
	vehicleAvailable: boolean;
	repossession?: Repossession;
	moffinValidation?: MoffinValidation;
}
interface OzonerContextModel {
	id?: string;
	tab: number;
	setTab: React.Dispatch<React.SetStateAction<number>>;
	initializeContext: () => void;
	setId: React.Dispatch<React.SetStateAction<string | undefined>>;
	loading: boolean;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	ozoner?: Ozoner;
	setOzoner: React.Dispatch<React.SetStateAction<Ozoner | undefined>>;
	financialForms: AvailableForm[];
	vehicle?: VehiclePopulated;
	loans: LoanWithExtraFields[];
	selfie?: string;
	indexForm: number;
	formId?: string;
	setFormId: React.Dispatch<React.SetStateAction<string | undefined>>;
	financialForm: AvailableForm;
	loanTotals?: SalePrices;
	canValidateCurp: boolean;
	validCurp: boolean;
	updateComment: (comment: string) => void;
	updateFormData: (form: GenericFinancialForm) => void;
	updateLoanData: (loan: LoanWithExtraFields) => void;
	handleDetailsVehicle: (e: any, internalId: string) => void;
	ozonios: { [key: string]: Ozonio };
	ozoniosList: { value: string; label: string }[];
	openVehicle: boolean;
	setOpenVehicle: React.Dispatch<React.SetStateAction<boolean>>;
	hasLoan: boolean;
}

export const ozonerContext = createContext<OzonerContextModel>({
	loading: false,
} as any);

// const truoraFiles = ["ine_front", "ine_back", "selfie"];

export const OzonerContextProvider = ({ children }: { children: any }) => {
	//     const location = useLocation();
	const navigate = useNavigate();

	// -----------------LOGIC STATES------------------- //
	const [loading, setLoading] = useState<boolean>(false);
	//     const [loadingModal, setLoadingModal] = useState<boolean>(false);
	const [id, setId] = useState<string | undefined>();
	const [tab, setTab] = useState<number>(0);

	// -----------------INITIAL STATES------------------- //
	const [ozoner, setOzoner] = useState<Ozoner>();
	const [financialForms, setFinancialForms] = useState<AvailableForm[]>([]);
	const [loans, setLoans] = useState<LoanWithExtraFields[]>([]);
	const [indexForm, setIndexForm] = useState<number>(0);
	const [formId, setFormId] = useState<string>();
	const [loanTotals, setLoanTotals] = useState<SalePrices>();

	// -----------------OPEN STATES------------------- //
	const [openVehicle, setOpenVehicle] = useState<boolean>(false);

	//     // -----------------MODAL STATES------------------- //
	//     const [modalOption, setModalOption] = useState("");
	//     const [openModal, setOpenModal] = useState<boolean>(false);

	//     // -----------------ACTIONS STATES------------------- //
	//     const [ozonioReceipt, setOzonioReceipt] = useState("");
	//     const [ozonioReached, setOzonioReached] = useState("");
	//     const [ozonioActive, setOzonioActive] = useState("");
	//     const [ozonioLastChange, setOzonioLastChange] = useState("");
	//     const [ozonioContract, setOzonioContract] = useState("");
	//     const [ozonioComplete, setOzonioComplete] = useState<any>("");
	//     const [ozonioStatusSolicitud, setOzonioStatusSolicitud] = useState("");

	//     // -----------------PLATFORMS STATES------------------- //
	//     const [syncPlatforms, setSyncPlatforms] = useState(false);
	//     const [validTruora, setValidTruora] = useState(false);

	// -----------------DOCUMENTS STATES------------------- //
	const [selfie, setSelfie] = useState<string>(profileDefaultOzoner);
	//     const [validCurp, setValidCurp] = useState(false);
	//     const [canValidateCurp, setCanValidateCurp] = useState(false);
	const [hasLoan, sethasLoan] = useState(false);

	//     // -----------------SOLICITUD STATES------------------- //

	//     // -----------------Norificaciones STATES------------------- //
	//     const [notificationsCount, setNotificationsCount] = useState<any>({
	//         textmsg: 0,
	//         email: 0,
	//         whatsapp: 0,
	//     });
	//     const [notifications, setNotifications] = useState<any[] | undefined>();

	const [ozonios, setOzonios] = useState<{ [key: string]: Ozonio }>({});
	const [ozoniosList, setOzoniosList] = useState<
		{ value: string; label: string }[]
	>([]);

	//     /*
	//      * AdvancedMoney states
	//      * */
	//     const [initialAdvanceMoney, setInitialAdvanceMoney] = useState<
	//         number | undefined
	//     >(undefined);
	//     const [advanceMoneyStatus, setAdvanceMoneyStatus] =
	//         useState<advanceMoneyStatusEnum>(advanceMoneyStatusEnum.default);
	//     const [unformattedValue, setUnformattedValue] = useState<string>("");
	//     // -----------------INITIAL------------------- //

	const initializeContext = () => {
		if (!id) return;
		let ozonerData: Ozoner | undefined;
		let financialFormsData: GenericFinancialForm[] = [];
		let loansData: LoanWithExtraFields[] = [];
		const repossessionsData: { [key: string]: Repossession[] } = {};
		const promises = [];

		promises.push(
			findOneOzoner(id).then((response) => {
				ozonerData = response;
				setOzoner(ozonerData);
				setSelfie(
					ozonerData?.documents?.find(
						(doc) => doc.typeEvidence === EvidenceDocumentType.selfie,
					)?.url ?? profileDefaultOzoner,
				);
			}),
		);

		promises.push(
			findAllFinancialFormsOzoner(id).then((response) => {
				financialFormsData = response;
				setSelfie(
					ozonerData?.documents?.find(
						(doc) => doc.typeEvidence === EvidenceDocumentType.selfie,
					)?.url ?? profileDefaultOzoner,
				);
			}),
		);
		promises.push(
			findLoanByOzoner(id).then((response) => {
				loansData = response;
				const repossessionsPromises: Promise<void | Repossession[]>[] = [];
				setLoans(loansData);
				loansData.forEach((loan) => {
					repossessionsPromises.push(
						findRepossessionByLoan(loan._id).then((repos) => {
							repossessionsData[loan._id] = repos.filter(
								(repossession) =>
									repossession.status === RepossessionStatus.pending,
							);
						}),
					);
				});
				if (response?.length) {
					sethasLoan(true);
				} else {
					sethasLoan(false);
				}
				return Promise.all(repossessionsPromises);
			}),
		);

		Promise.all(promises)
			.then(async () => {
				const availableForms: AvailableForm[] = [];
				let firstRejected = false;

				await Promise.all(
					financialFormsData
						.sort((a, b) => {
							return moment
								.utc(b.createdAt)
								.diff(moment.utc(a.createdAt), 'days');
						})
						.map(async (form) => {
							if (
								[FormStatus.rejected, FormStatus.expired].includes(form.status)
							) {
								if (firstRejected) return;
								firstRejected = true;
							}
							const loan = loansData.find(
								(loanData) => form._id === loanData.financialForm._id,
							);
							let moffinValidation;
							if (form.loanApplication?.moffin?._id)
								moffinValidation = await getMoffinValidation(
									form.loanApplication?.moffin?._id,
								);
							const vehicleId =
								loan?.vehicle?.internalId || form.vehicle?.internalId;
							const duration =
								loan?.duration || form.loanApplication?.loanDuration;
							let currentVehicle;
							let currentPrice;
							let vehicleAvailable = true;
							if (vehicleId) {
								currentVehicle = await getVehicleByInternalId(vehicleId);
								if (duration)
									currentPrice = getVehicleCurrentPrice(
										currentVehicle,
										duration,
									);
								if (!loan)
									await getOzonerByVehicle(form.vehicle?._id ?? '').then(
										(res) => {
											if (res && res._id !== id) vehicleAvailable = false;
										},
									);
							}

							availableForms.push({
								loan,
								form,
								vehicle: currentVehicle,
								vehiclePrice: currentPrice ?? 0,
								vehicleAvailable,
								moffinValidation,
								repossession:
									loan && repossessionsData[loan._id]?.length
										? repossessionsData[loan._id][0]
										: undefined,
							});
						}),
				);
				setFinancialForms(availableForms);
				setLoading(false);
			})
			.catch((e) => {
				// setLoading(false);
				Swal.fire({
					title: 'Error!',
					text: e ? e.message : 'ozoner no valido',
					icon: 'error',
					confirmButtonText: 'Aceptar',
				}).then(() => {
					navigate('/solicitudes');
				});
				throw e;
			});
	};

	useEffect(() => {
		if (formId) {
			const index = financialForms.findIndex(
				(form) => form.form._id === formId,
			);
			if (index >= 0) {
				setIndexForm(index);
			}
		}
	}, [formId, financialForms]);

	useEffect(() => {
		initializeContext();
	}, [id]);

	const updateComment = (comment: string) => {
		if (ozoner)
			updateOzoner(ozoner._id, { comment }).then(() => {
				setOzoner({ ...ozoner, comment });
			});
	};

	const updateFormData = (form: GenericFinancialForm) => {
		const index = financialForms.findIndex(
			(availableForm) => availableForm.form._id === form._id,
		);
		if (index >= 0) {
			const newFinancialForms = [...financialForms];
			newFinancialForms[index].form = form;
			setFinancialForms(newFinancialForms);
		}
	};
	const updateLoanData = (loan: LoanWithExtraFields) => {
		const index = financialForms.findIndex(
			(availableForm) => availableForm.form._id === loan.financialForm._id,
		);
		if (index >= 0) {
			const newFinancialForms = [...financialForms];
			newFinancialForms[index].loan = loan;
			setFinancialForms(newFinancialForms);
		}
	};

	const handleDetailsVehicle = (e: any, internalId: string) => {
		e.stopPropagation();
		navigate(`/inventory/${internalId}`);
	};

	useEffect(() => {
		findAllOzonios('').then((ozoniosListing) => {
			const ozoniosData: { [key: string]: Ozonio } = {};
			setOzoniosList(
				ozoniosListing.data
					.sort((a: any, b: any) => {
						if (a.name < b.name) {
							return -1;
						}
						if (a.name > b.name) {
							return 1;
						}
						return 0;
					})
					.map((ozonio: any) => {
						ozoniosData[ozonio._id] = ozonio;
						return {
							value: ozonio._id,
							label: ozonio.name,
						};
					}),
			);
			setOzonios(ozoniosData);
		});
	}, []);

	useEffect(() => {
		getLoanTotals();
	}, [financialForms, indexForm]);

	const getLoanTotals = () => {
		if (
			financialForms[indexForm] &&
			financialForms[indexForm].vehicle &&
			financialForms[indexForm].form.loanApplication?.loanDuration
		) {
			getQuotaVehicle(financialForms[indexForm].vehicle?.internalId ?? '', {
				duration:
					financialForms[indexForm].form.loanApplication?.loanDuration ?? 0,
				advancedMoney:
					financialForms[indexForm]?.form?.loanApplication?.advancedMoney,
			}).then((totals) => {
				setLoanTotals(totals);
			});
		}
	};
	//     // -----------------Platforms------------------- //

	//     const refreshPlatforms = (data: any, force?: boolean) => {
	//         const p = [];
	//         if (data) {
	//             if (
	//                 data.truoraId &&
	//                 (force ||
	//                     !data.truoraData ||
	//                     !data.truoraData.reduce(
	//                         (acc: boolean, ele: any) =>
	//                             acc &&
	//                             (ele.validation_status === "success" ||
	//                                 ele.status === "completed" ||
	//                                 ele.status === "error"),
	//                         true
	//                     ))
	//             ) {
	//                 p.push(getOzonerTruora(data.truoraId).then(() => fetchData()));
	//             }
	//             if (
	//                 data.palencaId &&
	//                 (!data.palencaData ||
	//                     !Object.keys(data.palencaData).length ||
	//                     force)
	//             ) {
	//                 p.push(getOzonerPalenca(data._id).then(() => fetchData()));
	//             }
	//         }
	//         return Promise.all(p);
	//     };

	//     const triggerTruora = async (data?: Blob, typeDoc?: string) => {
	//         if (ozoner) {
	//             const docs: any = {};
	//             let findAll = true;
	//             await Promise.all(
	//                 truoraFiles.map((fileType) => {
	//                     if (fileType === typeDoc) {
	//                         docs[fileType] = data;
	//                         return new Promise((resolve) => {
	//                             resolve(true);
	//                         });
	//                     }
	//                     const file = ozoner.documents?.filter(
	//                         (doc: any) => doc.typeEvidence === fileType
	//                     );
	//                     if (!file?.length) {
	//                         findAll = false;
	//                         return new Promise((resolve) => {
	//                             resolve(true);
	//                         });
	//                     }
	//                     return fetch(file[0].url, {
	//                         method: "GET",
	//                         cache: "no-cache",
	//                     })
	//                         .then((r) => r.blob())
	//                         .then((blob: Blob) => {
	//                             docs[fileType] = blob;
	//                         })
	//                         .catch((e) => {
	//                             findAll = false;
	//                             toasterNotification({
	//                                 msg: "Ocurrio un error cargando los documentos",
	//                                 style: "light",
	//                                 toasterType: toasterOptions.error,
	//                             });
	//                         });
	//                 })
	//             );
	//             if (findAll) {
	//                 const slackNotifyPayload = {
	//                     ozonerName: ozoner.name,
	//                     email: ozoner.email,
	//                     phone: ozoner.phone,
	//                     requestDate: moment().format("YYYY-MM-DD"),
	//                     // vehicle: vehicle?.internalId || undefined,
	//                     // quota: "658",
	//                     requestId: financialForms?.[indexForm]?._id || " ",
	//                 };

	//                 return validateTruora(docs, ozoner._id, slackNotifyPayload)
	//                     .then(() => getOzonerById(ozoner._id))
	//                     .then((ozone: any) => {
	//                         setOzoner(ozone);
	//                         return refreshPlatforms(ozone, true);
	//                     })
	//                     .then(() => {
	//                         toasterNotification({
	//                             msg: "Se actualizo la informacion de truora satisfactoriamente",
	//                             style: "light",
	//                             toasterType: toasterOptions.success,
	//                         });
	//                     })
	//                     .catch((e) => {
	//                         console.log(e);
	//                         toasterNotification({
	//                             msg: "Fallo la actualizacion de truora",
	//                             style: "light",
	//                             toasterType: toasterOptions.error,
	//                         });
	//                     });
	//             }
	//             toasterNotification({
	//                 msg: "No tiene los documentos necesarios cargados",
	//                 style: "light",
	//                 toasterType: toasterOptions.error,
	//             });
	//         }
	//         return new Promise((resolve) => {
	//             resolve(false);
	//         });
	//     };

	//     const evaluatePlatforms = (ozonerData: TDataozoner) => {
	//         if (ozonerData.truoraData) {
	//             setValidTruora(
	//                 ozonerData.truoraData.reduce(
	//                     (acc: boolean, val: any) =>
	//                         acc &&
	//                         (val.validation_status === "success" ||
	//                             val.status === "completed" ||
	//                             val.status === "delayed"),
	//                     true
	//                 ) && ozonerData.truoraData.length >= 2
	//             );
	//         }
	//         if (!syncPlatforms) {
	//             refreshPlatforms(ozoner);
	//             setSyncPlatforms(true);
	//         }
	//     };

	//     const getOzonerDocuments = (documents: any[]) => {
	//         if (documents?.length) {
	//             const url = documents.filter(
	//                 (obj: any) => obj.typeEvidence === "selfie"
	//             );
	//             if (url.length > 0) {
	//                 setSelfie(url[0].url);
	//             } else {
	//                 setSelfie(profileDefaultOzoner);
	//             }
	//         }
	//     };
	//     useEffect(() => {
	//         if (ozoner) {
	//             evaluatePlatforms(ozoner);

	//             // TODO: search for the documents on all objects
	//             getOzonerDocuments(ozoner.documents);

	//             const correctCurp = ozoner?.truoraData?.filter(
	//                 (ele: any) => ele.type === "document-validation"
	//             )[0]?.details?.document_details?.document_number;
	//             if (correctCurp) {
	//                 setCanValidateCurp(true);
	//                 setValidCurp(correctCurp === ozoner?.curp);
	//             }
	//         }
	//     }, [ozoner]);

	//     const getOzoniosForm = async (historic: any[], formHistoric: any[]) => {
	//         const newOzonios: any = { ...ozonios };
	//         const ozoniosIds = [...historic, ...formHistoric]
	//             .filter(
	//                 (history: any) =>
	//                     history.by && !Object.keys(newOzonios).includes(history.by)
	//             )
	//             .map((history) => history.by);
	//         await Promise.all(
	//             [...new Set(ozoniosIds)].map((ozonioId) => {
	//                 return getOzonioById(ozonioId)
	//                     .then((ozonio: any) => {
	//                         newOzonios[ozonioId] = ozonio;
	//                     })
	//                     .catch((e) => console.log("user not found"));
	//             })
	//         ).then(() => setOzonios(newOzonios));

	//         let status: any = historic.filter((ele: any) => ele.by);
	//         if (status.length) {
	//             status = status[status.length - 1];
	//             setOzonioLastChange(newOzonios[status.by]?.name);
	//         }

	//         status = historic.filter((ele: any) => ele.status === "active");
	//         if (status.length) {
	//             status = status[status.length - 1];
	//             setOzonioActive(newOzonios[status.by]?.name);
	//         }

	//         status = formHistoric.filter(
	//             (ele: any) => ele.status === "uploadReceipt"
	//         );
	//         if (status.length) {
	//             status = status[status.length - 1];
	//             setOzonioReceipt(newOzonios[status.by]?.name);
	//         }
	//         status = formHistoric.filter(
	//             (ele: any) => ele.status === "creditCreation"
	//         );
	//         if (status.length) {
	//             status = status[status.length - 1];
	//             setOzonioContract(newOzonios[status.by]?.name);
	//         }
	//         status = historic.filter((ele: any) => ele.status === "complete");
	//         if (status.length) {
	//             status = status[status.length - 1];
	//             setOzonioComplete(newOzonios[status.by]?.name);
	//         }
	//         status = historic.filter((ele: any) => ele.status === "complete");
	//         if (status.length) {
	//             status = status[status.length - 1];
	//             setOzonioComplete(newOzonios[status.by]?.name);
	//         }

	//         status = formHistoric.filter((ele: any) => ele.status === "reached");
	//         if (status.length) {
	//             status = status[status.length - 1];
	//             setOzonioReached(newOzonios[status.by]?.name);
	//         }
	//         if (financialForms?.[indexForm]?.status !== "new") {
	//             status = formHistoric.filter(
	//                 (ele: any) => ele.status === financialForms?.[indexForm].status
	//             );
	//             if (status.length) {
	//                 status = status[status.length - 1];
	//                 setOzonioStatusSolicitud(newOzonios[status.by]?.name);
	//             }
	//         }
	//     };

	//     // -----------------OTHER ACTIONS------------------- //

	//     const fetchNotifications = (typeN: "textmsg" | "email" | "whatsapp") => {
	//         setNotifications(undefined);
	//         return getOzonerNotifications(ozoner?._id, typeN)
	//             .then((data) => {
	//                 const p = data.map((not: any) =>
	//                     getGroupNotificationsById(not.groupEmail).then((res) => ({
	//                         ...not,
	//                         msg: res.data.body,
	//                     }))
	//                 );
	//                 return Promise.all(p);
	//             })
	//             .then((data: any) => {
	//                 setNotifications(data);
	//             });
	//     };

	//     useEffect(() => {
	//         if (modalOption !== "") {
	//             setOpenModal(true);
	//         }
	//     }, [modalOption]);

	//     const modalData: any = {
	//         reach: {
	//             title: (
	//                 <Typography scale="medium" weight="400" textColor="neutral_900">
	//                     ¿Has{" "}
	//                     <Typography scale="medium" weight="600" component="span">
	//                         contactado al ozoner?
	//                     </Typography>
	//                 </Typography>
	//             ),
	//             subtitle: (
	//                 <Typography scale="xsmall" weight="400" textColor="neutral_900">
	//                     Le marcaste, mandaste un correo o un mensaje al ozoner con
	//                     los datos registrados?{" "}
	//                 </Typography>
	//             ),
	//             action: updateReached,
	//             icon: <ApproveIcon />,
	//         },
	//         unreach: {
	//             title: (
	//                 <Typography scale="medium" weight="400" textColor="neutral_900">
	//                     ¿Deseas{" "}
	//                     <Typography scale="medium" weight="600" component="span">
	//                         cambiar el estado del ozoner?
	//                     </Typography>
	//                 </Typography>
	//             ),
	//             subtitle: (
	//                 <Typography scale="xsmall" weight="400" textColor="neutral_900">
	//                     Puedes volver a cambiar el estado en otro momento
	//                 </Typography>
	//             ),
	//             action: updateReached,
	//             icon: <ApproveIcon />,
	//         },
	//         reject: {
	//             title: (
	//                 <Typography scale="medium" weight="600" textColor="neutral_900">
	//                     ¿Estás seguro de que deseas rechazar el ozoner?
	//                 </Typography>
	//             ),
	//             subtitle: (
	//                 <Typography scale="xsmall" weight="400" textColor="neutral_900">
	//                     Se cambiará el estado del ozoner ha rechazado
	//                 </Typography>
	//             ),
	//             // action: () => updateStatus("rejected"),
	//             icon: <ContactIcon />,
	//         },
	//         approve: {
	//             title: (
	//                 <Typography scale="medium" weight="600" textColor="neutral_900">
	//                     ¿Estás seguro de que quieres crear el contrato en espera?
	//                 </Typography>
	//             ),
	//             subtitle: (
	//                 <Typography scale="xsmall" weight="400" textColor="neutral_900">
	//                     Se creará un contrato de espera para el ozoner, en el módulo
	//                     de crédito
	//                 </Typography>
	//             ),
	//             action: approveContract,
	//             icon: <ContactIcon />,
	//         },
	//         approve_contado: {
	//             title: (
	//                 <Typography scale="medium" weight="600" textColor="neutral_900">
	//                     ¿Estás seguro de que quieres procesar el contrato de
	//                     contado?
	//                 </Typography>
	//             ),
	//             subtitle: (
	//                 <Typography scale="xsmall" weight="400" textColor="neutral_900">
	//                     Se creará un contrato y se liquidara inmediatamente, esta
	//                     acción debe realizarse solo cuando ya se recibio el pago por
	//                     la moto.
	//                 </Typography>
	//             ),
	//             action: approveContado,
	//             icon: <ContactIcon />,
	//         },
	//         layawayReceipt: {
	//             title: (
	//                 <Typography scale="medium" weight="600" textColor="neutral_900">
	//                     Comprobante de pago apartado
	//                 </Typography>
	//             ),
	//             subtitle: (
	//                 <Typography scale="xsmall" weight="400" textColor="neutral_900">
	//                     Comprobante de pago apartado
	//                 </Typography>
	//             ),
	//             action: () => {
	//                 setOpenModal(false);
	//                 handleEventGoogle(
	//                     "BTN_Pb_ozoner_aside_certificate",
	//                     "Panda Ozoners",
	//                     "click in aside certificate ozoner button"
	//                 );
	//             },
	//             icon: <ApproveIcon />,
	//         },
	//         approve_soli: {
	//             title: (
	//                 <Typography scale="medium" weight="400" textColor="neutral_900">
	//                     ¿Has revisado{" "}
	//                     <Typography scale="medium" weight="600" component="span">
	//                         esta solicitud?
	//                     </Typography>
	//                 </Typography>
	//             ),
	//             subtitle: (
	//                 <Typography scale="xsmall" weight="400" textColor="neutral_900">
	//                     Revisaste en su totalidad, la{" "}
	//                     <Typography scale="medium" weight="600" component="span">
	//                         {" "}
	//                         información personal,
	//                     </Typography>{" "}
	//                     los datos
	//                     <Typography scale="medium" weight="600" component="span">
	//                         {" "}
	//                         financieros,
	//                     </Typography>{" "}
	//                     y los
	//                     <Typography scale="medium" weight="600" component="span">
	//                         {" "}
	//                         documentos
	//                     </Typography>
	//                 </Typography>
	//             ),
	//             action: () => updateReview(),
	//         },
	//     };

	const contextValue: OzonerContextModel = useMemo(
		() => ({
			id,
			tab,
			setTab,
			initializeContext,
			setId,
			loading,
			setLoading,
			ozoner,
			setOzoner,
			financialForms,
			loans,
			selfie,
			indexForm,
			formId,
			setFormId,
			financialForm: financialForms?.[indexForm],
			vehicle: financialForms?.[indexForm]?.vehicle,
			loanTotals,
			// TODO: add muffin and truora validation for CURP
			canValidateCurp: true,
			validCurp: true,
			updateComment,
			updateFormData,
			updateLoanData,
			handleDetailsVehicle,
			ozonios,
			ozoniosList,
			openVehicle,
			setOpenVehicle,
			hasLoan,
			// setModalOption,
			// updateCredit,
			// ozonioReached,
			// fetchData,
			// ozonioLastChange,
			// ozonioComplete,
			// ozonioContract,
			// ozonioReceipt,
			// ozonioStatusSolicitud,
			// updateStatusSolicitud,
			// setOpenVehicle,
			// hasLoan,
			// loading,
			// openModal,
			// setOpenModal,
			// modalOption,
			// modalData,
			// getPalencaLink,
			// openCreditChange,
			// setOpenCreditChange,
			// updateCreditTime,
			// notificationsCount,
			// fetchNotifications,
			// validTruora,
			// id,
			// setFormId,
			// financialForms,
			// ozonios,
			// notifications,
			// triggerTruora,
			// refreshPlatforms,
			// initialAdvanceMoney,
			// advanceMoneyStatus,
			// setUnformattedValue,
			// getAgreements,
			// agreementsHist,
			// agreements,
			// canValidateCurp,
			// validCurp,
			// loadingModal,
			// getWebpageLink,
			// handleDetailsVeh
		}),
		[
			id,
			tab,
			loading,
			ozoner,
			financialForms,
			loans,
			selfie,
			indexForm,
			formId,
			loanTotals,
			ozonios,
			ozoniosList,
			openVehicle,
			hasLoan,
			// ozoniosList,
			// loading,
			// indexForm,
			// ozonioReached,
			// ozonioComplete,
			// fetchData,
			// ozonioLastChange,
			// ozonioContract,
			// ozonioReceipt,
			// ozonioStatusSolicitud,
			// updateStatusSolicitud,
			// hasLoan,
			// id,
			// financialForms,
			// ozonios,
			// notifications,
			// initialAdvanceMoney,
			// advanceMoneyStatus,
			// validCurp,
			// canValidateCurp,
			// loadingModal,
		],
	);
	return (
		<ozonerContext.Provider value={contextValue}>
			{children}
		</ozonerContext.Provider>
	);
};
export {};
