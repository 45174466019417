import React from 'react';
import {
	EconomicActivityEnum,
	LivesWithEnum,
} from 'models/financial-form/enums/financial-form.enums';
import { Ozoner } from 'models/ozoner/interfaces/ozoner.interface';
import * as yup from 'yup';

import { ReactComponent as Profile } from 'design_system/src/static/icons/stars-profile.svg';

import { ReactComponent as Close } from 'design_system/src/static/icons/close-circle.svg';
import { ReactComponent as User } from 'design_system/src/static/icons/add-profile.svg';
import { ReactComponent as Phone } from 'design_system/src/static/icons/phone.svg';
import { ReactComponent as Mail } from 'design_system/src/static/icons/mail.svg';
import { ReactComponent as Email } from 'design_system/src/static/icons/email.svg';
import { ReactComponent as Map } from 'design_system/src/static/icons/map.svg';
import { ReactComponent as ID } from 'design_system/src/static/icons/user-card-2.svg';
import { ReactComponent as Calendar } from 'design_system/src/static/icons/date.svg';
import { ReactComponent as Check } from 'design_system/src/static/icons/checkmark.svg';
import { ReactComponent as Moto } from 'design_system/src/static/icons/motorcycle.svg';
import { ReactComponent as Baby } from 'design_system/src/static/icons/baby.svg';
import { ReactComponent as Working } from 'design_system/src/static/icons/working-bag-3.svg';
// import { ReactComponent as Save } from "design_system/src/static/icons/save.svg";
import { ReactComponent as Groups } from 'design_system/src/static/icons/groups.svg';
import { ReactComponent as Ruler } from 'design_system/src/static/icons/ruler-pencil.svg';
import { ReactComponent as Love } from 'design_system/src/static/icons/couple-love.svg';
import { ReactComponent as Files } from 'design_system/src/static/icons/documents.svg';
import { ReactComponent as GPS } from 'design_system/src/static/icons/gps.svg';
import { ReactComponent as Up } from 'design_system/src/static/icons/success-plan.svg';
import { ReactComponent as Down } from 'design_system/src/static/icons/failed-plan.svg';
import { Input } from 'design_system/src';
import { BaseGenericFormInputProps } from './GenericFormInput';

const curpRegex =
	/^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;

export const ozonerValidationSchema = yup.object({
	name: yup.string().required('Campo requerido'),
	lastName: yup.string().required('Campo requerido'),
	email: yup.string().email('Debe ser email').required('Campo requerido'),
	curp: yup
		.string()
		.required('Campo requerido')
		.matches(curpRegex, 'CURP inválido'),
	city: yup.string().required('Campo requerido'),
});
export const ozonerDefaults = {
	city: '',
	name: '',
	lastName: '',
	email: '',
	curp: '',
};

export const ozonerFields: {
	[K in keyof typeof ozonerDefaults]: BaseGenericFormInputProps;
} = {
	name: {
		title: 'Nombre*',
		placeholder: 'Escribe el nombre aquí',
		name: 'name',
		icon: <Profile />,
		className: 'm_b_xs w_100_per',
		size: 6,
	},
	lastName: {
		title: 'Apellidos*',
		placeholder: 'Escribe los apellidos aquí',
		name: 'lastName',
		icon: <Profile />,
		className: 'm_b_xs w_100_per',
		size: 6,
	},
	city: {
		title: 'Ciudad*',
		placeholder: 'Selecciona ciudad',
		name: 'city',
		type: 'select',
		icon: <Profile />,
		className: 'm_b_xs w_100_per',
		size: 6,
	},
	email: {
		title: 'Correo*',
		placeholder: 'Escribe el correo aquí',
		name: 'email',
		type: 'email',
		icon: <Email />,
		className: 'm_b_xs w_100_per',
		size: 6,
	},
	curp: {
		title: 'CURP*',
		placeholder: 'Escribe el CURP aquí',
		name: 'curp',
		icon: <ID />,
		className: 'm_b_xs w_100_per',
		size: 12,
	},
};

export const addressValidationSchema = yup.object({
	zipCode: yup
		.string()
		.required('Campo requerido')
		.matches(/^\d{5}$/, 'Debe ser un número de 5 dígitos.')
		.test('zipCode', 'El codigo postal no existe', function test(value) {
			const { state } = this.parent;
			if (value && value.length === 5 && !state) {
				return this.createError({
					message: 'El codigo postal no existe',
				});
			}
			return true;
		})
		.required('El código es requerido'),
	city: yup.string(),
	state: yup.string().required('Campo requerido'),
	stateCode: yup.string().required('Campo requerido'),
	delegation: yup.string().required('Campo requerido'),
	neighborhood: yup.string().required('Campo requerido'),
	street: yup.string().required('Campo requerido'),
	extNumber: yup.string().required('Campo requerido'),
	intNumber: yup.string(),
});

export const addressDefaults = {
	city: '',
	zipCode: '',
	state: '',
	stateCode: '',
	delegation: '',
	neighborhood: '',
	street: '',
	extNumber: '',
	intNumber: '',
};

export const addressFields: {
	[K in keyof typeof addressDefaults]: BaseGenericFormInputProps;
} = {
	zipCode: {
		title: 'Código postal*',
		placeholder: 'Escribe el código postal',
		name: 'zipCode',
		icon: <Mail />,
		className: 'm_b_xs w_100_per',
		size: 12,
	},
	city: {
		title: 'Ciudad*',
		placeholder: 'Escribe la ciudad',
		name: 'city',
		icon: <GPS />,
		className: 'm_b_xs w_100_per',
		size: 6,
		disabled: true,
	},
	state: {
		title: 'Estado*',
		placeholder: 'Selecciona el estado',
		name: 'state',
		icon: <GPS />,
		className: 'm_b_xs w_100_per',
		size: 6,
		disabled: true,
	},
	stateCode: {
		title: 'Estado*',
		placeholder: 'Selecciona el estado',
		name: 'state',
		icon: <GPS />,
		className: 'm_b_xs w_100_per',
		size: 6,
		disabled: true,
	},
	delegation: {
		title: 'Delegación*',
		disabled: true,
		placeholder: 'Selecciona la delegación',
		name: 'delegation',
		icon: <GPS />,
		className: 'm_b_xs w_100_per',
		size: 6,
	},
	neighborhood: {
		title: 'Colonia*',
		placeholder: 'Selecciona la colonia',
		name: 'neighborhood',
		type: 'select',
		icon: <GPS />,
		className: 'm_b_xs w_100_per',
		size: 6,
	},
	street: {
		title: 'Calle*',
		placeholder: 'Escribe nombre de la calle',
		name: 'street',
		icon: <GPS />,
		className: 'm_b_xs w_100_per',
		size: 6,
	},
	extNumber: {
		title: 'Número Exterior*',
		placeholder: 'Escribe el número exterior',
		name: 'extNumber',
		icon: <GPS />,
		className: 'm_b_xs w_100_per',
		size: 6,
	},
	intNumber: {
		title: 'Número Interior',
		placeholder: 'Escribe el número interior',
		name: 'intNumber',
		icon: <GPS />,
		className: 'm_b_xs w_100_per',
		size: 6,
	},
};

export const relationOptions = [
	{ label: 'Familiar', value: 'Familiar' },
	{ label: 'Pareja', value: 'Pareja' },
	{ label: 'Amigo/a', value: 'Amigo/a' },
	{ label: 'Compañero de trabajo', value: 'Compañero de trabajo' },
	{ label: 'Otro', value: 'Otro' },
];

export const referencesValidationSchema = yup.object({
	ref1name: yup.string().required('Campo requerido'),
	ref1phone: yup.number().required('Campo requerido'),
	ref1relationship: yup.string().required('Campo requerido'),
	ref1other: yup.string().when('ref1relation', {
		is: (value: string) => value === 'Otro',
		then: yup.string().required('Campo requerido'),
		otherwise: yup.string().optional(),
	}),
	ref2name: yup.string().required('Campo requerido'),
	ref2phone: yup.number().required('Campo requerido'),
	ref2relationship: yup.string().required('Campo requerido'),
	ref2other: yup.string().when('ref2relation', {
		is: (value: string) => value === 'Otro',
		then: yup.string().required('Campo requerido'),
		otherwise: yup.string().optional(),
	}),
});

export const referencesDefaults = {
	ref1name: '',
	ref1phone: '',
	ref1relationship: '',
	ref1other: '',
	ref2name: '',
	ref2phone: '',
	ref2relationship: '',
	ref2other: '',
};

export const reference1Fields: {
	[K in keyof Partial<typeof referencesDefaults>]: BaseGenericFormInputProps;
} = {
	ref1name: {
		title: 'Referencia 1',
		placeholder: 'Escribe su nombre completo',
		name: 'ref1name',
		icon: <Profile />,
		className: 'm_b_xs w_100_per',
		size: 6,
	},
	ref1phone: {
		title: '',
		placeholder: 'Escribe su numero telefónico',
		name: 'ref1phone',
		type: 'number',
		className: 'm_b_xs w_100_per',
		size: 6,
	},
	ref1relationship: {
		title: '',
		placeholder: 'Selecciona el parentesco',
		name: 'ref1relationship',
		className: 'm_b_xs w_100_per',
		type: 'select',
		options: relationOptions,
		size: 6,
	},
	ref1other: {
		title: '',
		placeholder: 'Escribe el parentesco',
		name: 'ref1other',
		className: 'm_b_xs w_100_per',
		size: 6,
	},
};

export const reference2Fields: {
	[K in keyof Partial<typeof referencesDefaults>]: BaseGenericFormInputProps;
} = {
	ref2name: {
		title: 'Referencia 2',
		placeholder: 'Escribe su nombre completo',
		name: 'ref2name',
		icon: <Profile />,
		className: 'm_b_xs w_100_per',
		size: 6,
	},
	ref2phone: {
		title: '',
		placeholder: 'Escribe su numero telefónico',
		name: 'ref2phone',
		type: 'number',
		className: 'm_b_xs w_100_per',
		size: 6,
	},
	ref2relationship: {
		title: '',
		placeholder: 'Selecciona el parentesco',
		name: 'ref2relationship',
		className: 'm_b_xs w_100_per',
		type: 'select',
		options: relationOptions,
		size: 6,
	},
	ref2other: {
		title: '',
		placeholder: 'Escribe el parentesco',
		name: 'ref2other',
		className: 'm_b_xs w_100_per',
		size: 6,
	},
};

export const personalValidationSchema = yup.object({
	phone: yup.number().required('Campo requerido'),
	dependantsCount: yup.number().required('Campo requerido'),
	civilStatus: yup.string().required('Campo requerido'),
	educationalLevel: yup.string().required('Campo requerido'),
	livesWith: yup.array().min(1, 'Campo Requerido'),
	childrenCount: yup.number().required('Campo requerido'),
});

export const personalDefaults = {
	phone: '',
	dependantsCount: '',
	civilStatus: '',
	educationalLevel: '',
	livesWith: [],
	childrenCount: '',
};

const education = [
	'Ninguna',
	'Primaria',
	'Secundaria',
	'Preparatoria',
	'Tecnicatura',
	'Licenciatura',
	'Maestria',
];

const children = [
	{ label: '0', value: 0 },
	{ label: '1', value: 1 },
	{ label: '2', value: 2 },
	{ label: '3', value: 3 },
	{ label: '4', value: 4 },
	{ label: '5 o más', value: 5 },
];
const dependant = [
	{ label: '0', value: 0 },
	{ label: '1', value: 1 },
	{ label: '2', value: 2 },
	{ label: '3', value: 3 },
	{ label: '4', value: 4 },
	{ label: '5 o más', value: 5 },
];

const livesWithOptions = [
	{ label: LivesWithEnum.alone, value: LivesWithEnum.alone },
	{ label: LivesWithEnum.Spouse, value: LivesWithEnum.Spouse },
	{ label: LivesWithEnum.friends, value: LivesWithEnum.friends },
	{ label: LivesWithEnum.parents, value: LivesWithEnum.parents },
	{ label: LivesWithEnum.couple, value: LivesWithEnum.couple },
	{ label: LivesWithEnum.Siblings, value: LivesWithEnum.Siblings },
	{ label: LivesWithEnum.children, value: LivesWithEnum.children },
];

const civil = ['Solter@', 'Union Libre', 'Casad@', 'Viud@'];

export const personalFields: {
	[K in keyof typeof personalDefaults]: BaseGenericFormInputProps;
} = {
	phone: {
		title: 'Telefono',
		placeholder: 'Escribe su telefono',
		name: 'phone',
		icon: <Phone />,
		className: 'm_b_xs w_100_per',
		size: 6,
	},
	dependantsCount: {
		title: 'Dependientes económicos*',
		placeholder: 'Personas que viven de su ingreso',
		name: 'dependantsCount',
		type: 'select',
		icon: <Calendar />,
		options: dependant,
		className: 'm_b_xs w_100_per',
		size: 6,
	},
	civilStatus: {
		title: 'Estado Civil*',
		placeholder: 'Selecciona el estado civil',
		name: 'civilStatus',
		className: 'm_b_xs w_100_per',
		icon: <Love />,
		type: 'select',
		options: civil,
		size: 6,
	},
	educationalLevel: {
		title: 'Nivel de estudios*',
		placeholder: 'Su último grado de estudios',
		name: 'educationalLevel',
		className: 'm_b_xs w_100_per',
		icon: <Ruler />,
		type: 'select',
		options: education,
		size: 6,
	},
	livesWith: {
		title: 'Vive Con',
		placeholder: '¿Con quíen vive?',
		name: 'livesWith',
		className: 'm_b_xs w_100_per',
		icon: <Groups />,
		type: 'select',
		options: livesWithOptions,
		variant: 'checkbox',
		size: 6,
	},
	childrenCount: {
		title: 'Hijos*',
		placeholder: 'Cuantos hijos tiene',
		name: 'childrenCount',
		className: 'm_b_xs w_100_per',
		icon: <Baby />,
		type: 'select',
		options: children,
		size: 6,
	},
};

export const financialValidationSchema = yup.object({
	monthlyIncome: yup.number().required('Campo requerido'),
	monthlyOutcome: yup.number().required('Campo requerido'),
	companyName: yup.string().nullable(),
	economicActivity: yup.string().required('Campo requerido'),
	economicActivityOther: yup.string().when('economicActivity', {
		is: (value: string) => value === EconomicActivityEnum.otro,
		then: yup.string().required('Campo requerido'),
		otherwise: yup.string().optional(),
	}),
});

export const financialDefaults = {
	monthlyIncome: '',
	monthlyOutcome: '',
	workInDigitalPlatforms: false,
	companyName: '',
	assets: [],
	assetsOther: '',
	economicActivity: '',
	economicActivityOther: '',
};

const activos = [
	{ label: 'Carro', value: 'carro' },
	{ label: 'Casa', value: 'casa' },
	{ label: 'Moto', value: 'moto' },
	{ label: 'Otros', value: 'otro' },
];

export const financialFields: {
	[K in keyof typeof financialDefaults]: BaseGenericFormInputProps;
} = {
	monthlyIncome: {
		title: 'Ingreso Bruto Mensual*',
		placeholder: 'Escribe el valor de ingresos del ozoner',
		name: 'monthlyIncome',
		icon: <Up className="m_l_xs status_arrows green" />,
		className: 'm_b_xs w_100_per',
		size: 12,
	},
	monthlyOutcome: {
		title: 'Egreso Ordinario Mensual*',
		placeholder: 'Escribe el valor de gastos del ozoner',
		name: 'monthlyOutcome',
		icon: <Down className="m_l_xs status_arrows red" />,
		className: 'm_b_xs w_100_per',
		size: 12,
	},
	companyName: {
		title: 'Empresa',
		placeholder: 'Escribe el nombre de la empresa',
		name: 'companyName',
		icon: <Working />,
		className: 'm_b_xs w_100_per',
		size: 12,
	},
	workInDigitalPlatforms: {
		title: 'Trabaja en plataformas digitales',
		placeholder: 'plataformas digitales',
		name: 'workInDigitalPlatforms',
		className: 'm_b_xs w_100_per',
		size: 12,
	},
	assets: {
		title: 'Activos',
		placeholder: 'Selecciona los activos',
		name: 'assets',
		icon: <Check />,
		type: 'select',
		variant: 'checkbox',
		options: activos,
		className: 'm_b_xs w_100_per',
		size: 6,
	},
	assetsOther: {
		title: 'Cuales?',
		placeholder: 'otros activos',
		name: 'assetsOther',
		className: 'm_b_xs w_100_per',
		size: 6,
	},
	economicActivity: {
		title: 'Ocupación*',
		placeholder: 'Selecciona la ocupacion',
		name: 'economicActivity',
		icon: <Profile />,
		type: 'select',
		options: Object.values(EconomicActivityEnum),
		className: 'm_b_xs w_100_per',
		size: 6,
	},
	economicActivityOther: {
		title: 'Cual?',
		placeholder: 'Escribe la ocupacion',
		name: 'economicActivityOther',
		className: 'm_b_xs w_100_per',
		size: 6,
	},
};
