/* eslint-disable no-plusplus */
import React, { FC, useEffect, useState } from 'react';
import { SideModal, Typography } from 'design_system/src';
import { ReactComponent as Reload } from 'design_system/src/static/icons/reload-square.svg';
import './history.scss';
import moment from 'moment';
import { LightTooltip } from 'components/hocs/tooltip/Tooltip';
import { LoanStatusValues } from 'models/loan/enums/loan.enum';
import { momentLocal } from 'models/shared/helpers/dates';

interface HistoryStatusProps {
	open: boolean;
	setOpen: Function;
	historic: any[];
	ozonios: any;
}
// eslint-disable-next-line no-shadow
enum extraMovesHistoric {
	reached = 'reached',
	creditCreation = 'creditCreation',
	uploadReceipt = 'uploadReceipt',
}

const messages = {
	[extraMovesHistoric.reached]: 'contacto al ozoner',
	[extraMovesHistoric.creditCreation]: 'creo el contrato',
	[extraMovesHistoric.uploadReceipt]: 'cargo el comprobante de apartado',
};

const HistoryStatus: FC<HistoryStatusProps> = ({
	open,
	setOpen,
	historic,
	ozonios,
}) => {
	const [display, setDisplay] = useState<any[]>([]);

	useEffect(() => {
		const newDisplay: any[] = [];
		let prev = historic[0];
		for (let i = 1; i < historic.length; i++) {
			const element = historic[i];
			if (Object.keys(messages).includes(element.status)) {
				newDisplay.push({
					by: element.by ? ozonios[element.by] : { name: 'Sistema' },
					date: element.updatedAt,
					msg: messages[element.status as extraMovesHistoric],
					_id: element._id,
				});
			} else if (Object.keys(LoanStatusValues).includes(element.status)) {
				newDisplay.push({
					old: prev.status,
					new: element.status,
					by: element.by ? ozonios[element.by] : { name: 'Sistema' },
					date: momentLocal(element.updatedAt).format('YYYY-MM-DD'),
					msg: 'cambio el estado',
					_id: element._id,
				});
				prev = element;
			}
		}
		setDisplay(newDisplay.reverse());
	}, [historic, ozonios]);

	return (
		<SideModal open={open} setOpen={setOpen} size="normal">
			<div className="flex_content_col">
				<Typography
					scale="heading4"
					weight="600"
					textColor="primary_300"
					className="text_center flex_header p_b_md"
				>
					Historial de cambios
				</Typography>
				<div className="flex_body historic_status">
					{display.map((change, i) => (
						<div
							className="p_y_lg historic_change bg_neutral_100 p_x_md"
							key={`${i}-${change.msg}`}
						>
							<div className="display_flex flex_justify_between m_b_md">
								<Typography
									scale="xsmall"
									weight="600"
									textColor="neutral_600"
									className=""
								>
									{change.by?.name}
									<Typography
										scale="xsmall"
										weight="400"
										textColor="neutral_1000"
										className=""
										component="span"
									>
										{' '}
										{change.msg}
									</Typography>
								</Typography>
								{change.old ? (
									<div className="display_flex flex_gap_xxxs flex_align_center">
										<LightTooltip
											title={
												(LoanStatusValues as any)[change.old]?.tooltip ??
												change.old
											}
										>
											<div>
												<Typography
													scale="xsmall"
													weight="600"
													className={`p_x_md p_y_xxs br_xs text_center text_neutral_0 pos_relative
                      bg_${
												(LoanStatusValues as any)[change.old]?.color
											}_nocontrast`}
												>
													{(LoanStatusValues as any)[change.old]?.title}
												</Typography>
											</div>
										</LightTooltip>
										<Reload className="dim_md text_neutral_800" />
										<LightTooltip
											title={
												(LoanStatusValues as any)[change.new]?.tooltip ??
												change.new
											}
										>
											<div>
												<Typography
													scale="xsmall"
													weight="600"
													className={`p_x_md p_y_xxs br_xs text_center text_neutral_0 pos_relative
                      bg_${
												(LoanStatusValues as any)[change.new]?.color
											}_nocontrast`}
												>
													{(LoanStatusValues as any)[change.new]?.title}
												</Typography>
											</div>
										</LightTooltip>
									</div>
								) : null}
							</div>
							<Typography scale="xsmall" weight="400" className="">
								Fecha de modificación:{' '}
								<Typography
									scale="small"
									weight="400"
									className=""
									component="span"
								>
									{change.date}
								</Typography>
							</Typography>
						</div>
					))}
				</div>
			</div>
		</SideModal>
	);
};

export default HistoryStatus;
