/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import {
	Form,
	Formik,
	FormikHelpers,
	FormikProps,
	FormikProvider,
	FormikValues,
	useFormik,
} from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';

// icons
import { ReactComponent as Profile } from 'design_system/src/static/icons/stars-profile.svg';

import { ReactComponent as Close } from 'design_system/src/static/icons/close-circle.svg';
import { ReactComponent as User } from 'design_system/src/static/icons/add-profile.svg';
import { ReactComponent as Phone } from 'design_system/src/static/icons/phone.svg';
import { ReactComponent as Mail } from 'design_system/src/static/icons/mail.svg';
import { ReactComponent as Email } from 'design_system/src/static/icons/email.svg';
import { ReactComponent as Map } from 'design_system/src/static/icons/map.svg';
import { ReactComponent as ID } from 'design_system/src/static/icons/user-card-2.svg';
import { ReactComponent as Calendar } from 'design_system/src/static/icons/date.svg';
import { ReactComponent as Check } from 'design_system/src/static/icons/checkmark.svg';
import { ReactComponent as Moto } from 'design_system/src/static/icons/motorcycle.svg';
import { ReactComponent as Baby } from 'design_system/src/static/icons/baby.svg';
import { ReactComponent as Working } from 'design_system/src/static/icons/working-bag-3.svg';
// import { ReactComponent as Save } from "design_system/src/static/icons/save.svg";
import { ReactComponent as Groups } from 'design_system/src/static/icons/groups.svg';
import { ReactComponent as Ruler } from 'design_system/src/static/icons/ruler-pencil.svg';
import { ReactComponent as Love } from 'design_system/src/static/icons/couple-love.svg';
import { ReactComponent as Files } from 'design_system/src/static/icons/documents.svg';
import { ReactComponent as GPS } from 'design_system/src/static/icons/gps.svg';
import { ReactComponent as Up } from 'design_system/src/static/icons/success-plan.svg';
import { ReactComponent as Down } from 'design_system/src/static/icons/failed-plan.svg';

import Divider from '@mui/material/Divider';
import { Button, Input, SideModal, Typography } from 'design_system/src';

// css
import '../ozoner.scss';
import Inventory from 'views/inventory/Inventory';
import { getCities } from 'models/inventory/controllers/vehicle.controller';
import { useNavigate } from 'react-router-dom';
import { VehiclePopulated } from 'models/inventory/interfaces/vehicle.interface';
import {
	createOzoner,
	findOzonerByCurp,
	updateOzoner,
} from 'models/ozoner/controllers/ozoner.controller';
import {
	EconomicActivityEnum,
	FormStatus,
} from 'models/financial-form/enums/financial-form.enums';
import {
	CreateOzonerDto,
	Ozoner,
	UpdateOzonerDto,
} from 'models/ozoner/interfaces/ozoner.interface';
import {
	CreateLoanFinancialFormDto,
	GenericFinancialForm,
} from 'models/financial-form/interfaces/financial-form.interfaces';
import { sanitizeName } from 'models/shared/helpers/utils';
import { toasterNotification } from 'models/shared/helpers/toasterNotification';
import { VehicleStatus } from 'models/inventory/enums/vehicle.enum';
import {
	createFullPaymentFinancialForm,
	createLoanFinancialForm,
	updateFinancialForm,
} from 'models/financial-form/controllers/financial-form.controller';
import { AcceptanceMethodEnum } from 'models/shared/enums/acceptanceMethod.enum';
import { commonErrorHandler } from 'models/shared/helpers/errors';
import { fetchByPostalCode } from 'models/shared/controllers/moffin';
import { stateMapping } from 'models/shared/types/moffin';
import LocationModalEdit from './LocationModalEdit';
import { toasterOptions } from '../../../models/shared/enums/toaster.enum';
import {
	addressDefaults,
	addressFields,
	addressValidationSchema,
	financialDefaults,
	financialFields,
	financialValidationSchema,
	ozonerDefaults,
	ozonerFields,
	ozonerValidationSchema,
	personalDefaults,
	personalFields,
	personalValidationSchema,
	reference1Fields,
	reference2Fields,
	referencesDefaults,
	referencesValidationSchema,
} from './constants';
import { GenericFormInput } from './GenericFormInput';

interface EditProps {
	open: boolean;
	setOpen: Function;
	setResult: Function;
	ozoner?: Ozoner | null;
	form?: GenericFinancialForm | null;
	label?: string;
	sections?: boolean[];
	hasLoan?: boolean;
}

const formatNumber = (number: string) => {
	if (!number.startsWith('521')) {
		return `521${number}`;
	}
	return number;
};
function EditOzoner({
	open,
	setOpen,
	ozoner,
	form,
	setResult,
	label = 'Crear nueva Solicitud',
	sections = [true, true, true, true],
	hasLoan = false,
}: EditProps) {
	const navigate = useNavigate();

	const [openLocation, setOpenLocation] = useState(false);
	const [ozonerData, setOzonerData] = useState<Partial<Ozoner>>(ozonerDefaults);
	const [addressI, setAddressI] =
		useState<typeof addressDefaults>(addressDefaults);
	const [referencesI, setReferencesI] =
		useState<typeof referencesDefaults>(referencesDefaults);
	const [financialI, setFinancialI] =
		useState<typeof financialDefaults>(financialDefaults);
	const [personalI, setPersonalI] =
		useState<typeof personalDefaults>(personalDefaults);

	const [vehicle, setVehicle] = useState<VehiclePopulated>();
	const [loanDuration, setLoanDuration] = useState();
	const [fullPaymentPhone, setFullPaymentPhone] = useState();
	const [fullOpen, setFullOpen] = useState(false);
	const [curpValidation, setCurpValidation] = useState(false);

	const [loading, setLoading] = useState<boolean>(false);
	const [loadingValidation, setLoadingValidation] = useState<boolean>(false);
	const [showErrors, setShowErrors] = useState<boolean>(false);
	const [showRef1OtherField, setShowRef1OtherField] = useState(false);
	const [showRef2OtherField, setShowRef2OtherField] = useState(false);
	const [neighborhoodOptions, setNeighborhoodOptions] = useState<string[]>([]);
	const [loadingCP, setloadingCP] = useState(false);

	const [location, setLocation] = useState<
		| {
				latitude: number;
				longitude: number;
		  }
		| undefined
	>();

	const [cities, setCities] = useState<any[]>([]);

	const ozonerF = useFormik({
		initialValues: ozonerData,
		validateOnMount: true,
		enableReinitialize: true,
		validationSchema: ozonerValidationSchema,
		onSubmit: () => {},
	});
	const addressF = useFormik({
		initialValues: addressI,
		validateOnMount: true,
		enableReinitialize: true,
		validationSchema: addressValidationSchema,
		onSubmit: () => {},
	});
	const referencesF = useFormik({
		initialValues: referencesI,
		validateOnMount: true,
		enableReinitialize: true,
		validationSchema: referencesValidationSchema,
		onSubmit: () => {},
	});
	const personalF = useFormik({
		initialValues: personalI,
		validateOnMount: true,
		enableReinitialize: true,
		validationSchema: personalValidationSchema,
		onSubmit: () => {},
	});
	const financialF = useFormik({
		initialValues: financialI,
		validateOnMount: true,
		enableReinitialize: true,
		validationSchema: financialValidationSchema,
		onSubmit: () => {},
	});

	useEffect(() => {
		if (ozoner) {
			const { name, lastName, email, curp, city } = ozoner;
			setOzonerData({ name, lastName, email, curp, city });
		}
		if (form) {
			if (form.personalInformation?.fullAddress) {
				const { lat, long } = form.personalInformation.fullAddress;
				setAddressI({
					...form.personalInformation.fullAddress,
				});
				if (lat && long) {
					setLocation({
						latitude: lat,
						longitude: long,
					});
				}
			}

			// TODO: Set The Other reference field correcttly
			setReferencesI({
				ref1name:
					form.personalInformation?.personalReferences?.personalReference1
						?.name ?? '',
				ref1phone:
					form.personalInformation?.personalReferences?.personalReference1
						?.phone ?? '',
				ref1relationship:
					form.personalInformation?.personalReferences?.personalReference1
						?.relationship ?? '',
				ref1other:
					form.personalInformation?.personalReferences?.personalReference1
						?.relationship ?? '',
				ref2name:
					form.personalInformation?.personalReferences?.personalReference2
						?.name ?? '',
				ref2phone:
					form.personalInformation?.personalReferences?.personalReference2
						?.phone ?? '',
				ref2relationship:
					form.personalInformation?.personalReferences?.personalReference2
						?.relationship ?? '',
				ref2other:
					form.personalInformation?.personalReferences?.personalReference2
						?.relationship ?? '',
			});
			// TODO:Separate Assets of other assets
			// let assets = form.financialInformation?.assets?.assets || [];
			let assets: any = [];
			if (!Array.isArray(assets)) assets = [assets];
			if (assets?.other) {
				assets = [...assets, 'otro'];
			}
			setFinancialI({
				...(form.financialInformation as any),
				economicActivity: form.financialInformation?.economicActivity?.value,
				economicActivityOther:
					form.financialInformation?.economicActivity?.description,
			});
			setPersonalI(form.personalInformation as any);
			if (
				form.personalInformation?.personalReferences?.personalReference1
					?.relationship === 'Otro'
			) {
				setShowRef1OtherField(true);
			}
			if (
				form.personalInformation?.personalReferences?.personalReference2
					?.relationship === 'Otro'
			) {
				setShowRef2OtherField(true);
			}
		}

		if (!ozoner && !form) {
			setCurpValidation(true);
		}
	}, [ozoner, form]);

	useEffect(() => {
		setLoanDuration(undefined);
	}, [vehicle]);

	useEffect(() => {
		getCities().then((citiesR) => {
			if (citiesR) {
				setCities(citiesR.map((c) => ({ label: c.name, value: c._id })));
			}
		});
	}, []);

	const clearForm = () => {
		setVehicle(undefined);
		setOpen(false);
		setOzonerData(ozonerDefaults);
		setAddressI(addressDefaults);
		setLocation(undefined);
		setReferencesI(referencesDefaults);
		setPersonalI(personalDefaults);
		setFinancialI(financialDefaults);
		setLoanDuration(undefined);
		ozonerF.resetForm();
		addressF.resetForm();
		referencesF.resetForm();
		personalF.resetForm();
		financialF.resetForm();
		setLoading(false);
	};

	const isValidForm = () => {
		let valid = true;
		let msg = '';

		if (vehicle && !loanDuration) {
			valid = false;
			msg = 'Hace falta el tiempo de credito';
		} else if (sections[0] && !ozonerF.isValid) {
			valid = false;
			msg = 'Información basica incompleta';
		} else if (loanDuration === 1) {
			if (!fullPaymentPhone) {
				msg = 'Hace falta el numero de telefono';
				valid = false;
			} else {
				return true;
			}
		} else if (sections[1] && !(addressF.isValid && !!location)) {
			valid = false;
			msg = 'Dirección incompleta';
		} else if (sections[2] && !referencesF.isValid) {
			valid = false;
			msg = 'Referencias personales incompletas';
		} else if (sections[3]) {
			if (!financialF.isValid) {
				valid = false;
				msg = 'Información financiera incompleta';
			} else if (!personalF.isValid) {
				valid = false;
				msg = 'Información personal incompleta';
			}
		}

		if (!valid) {
			toasterNotification({
				msg,
				style: 'light',
				toasterType: toasterOptions.error,
			});
		}

		return valid;
	};

	const onSubmit = () => {
		setLoading(true);
		if (!isValidForm()) {
			setLoading(false);
			setShowErrors(true);
			return;
		}

		let ozonerCreateDTO = {};
		let financialFormCreateDTO: any = {};
		if (sections[0]) {
			ozonerCreateDTO = { ...ozonerF.values };
		}
		if (vehicle) {
			financialFormCreateDTO = {
				vehicle: vehicle._id,
				loanApplication: {
					...form?.loanApplication,
					loanDuration,
				},
			};
		}
		if (sections[1] && location) {
			financialFormCreateDTO = {
				...financialFormCreateDTO,
				personalInformation: {
					...form?.personalInformation,
					...financialFormCreateDTO.personalInformation,
					fullAddress: {
						...addressF.values,
						lat: location.latitude,
						long: location.longitude,
					},
				},
			};
		}
		if (sections[2]) {
			financialFormCreateDTO = {
				...financialFormCreateDTO,
				personalInformation: {
					...form?.personalInformation,
					...financialFormCreateDTO.personalInformation,
					personalReferences: {
						personalReference1: {
							name: sanitizeName(referencesF.values.ref1name),
							phone: formatNumber(`${referencesF.values.ref1phone}`),
							relationship:
								referencesF.values.ref1relationship === 'Otro'
									? referencesF.values.ref1other
									: referencesF.values.ref1relationship,
						},
						personalReference2: {
							name: sanitizeName(referencesF.values.ref2name),
							phone: formatNumber(`${referencesF.values.ref2phone}`),
							relationship:
								referencesF.values.ref2relationship === 'Otro'
									? referencesF.values.ref2other
									: referencesF.values.ref2relationship,
						},
					},
				},
			};
		}
		if (sections[3]) {
			const asset: string[] = financialF.values.assets ?? [];
			const assets = [
				...asset.filter((ele: string) => ele !== 'otro'),
				...(financialF.values.assetsOther && asset.includes('otro')
					? financialF.values.assetsOther
							.split(',')
							.map((ele: string) => ele.trim())
					: []),
			];
			const financialCopy: any = { ...financialF.values };
			financialCopy.monthlyIncome =
				financialCopy.monthlyIncome !== ''
					? parseInt(financialCopy.monthlyIncome, 10)
					: 0;
			financialCopy.monthlyOutcome =
				financialCopy.monthlyOutcome !== ''
					? parseInt(financialCopy.monthlyOutcome, 10)
					: 0;
			delete financialCopy.assetsOther;
			delete financialCopy.economicActivityOther;
			financialFormCreateDTO = {
				...financialFormCreateDTO,
				personalInformation: {
					...form?.personalInformation,
					...financialFormCreateDTO.personalInformation,
					...personalF.values,
				},
				financialInformation: {
					...form?.financialInformation,
					...financialCopy,
					assets,
					economicActivity: {
						value: financialF.values.economicActivity,
						description:
							financialF.values.economicActivity === EconomicActivityEnum.otro
								? financialF.values.economicActivityOther
								: undefined,
					},
				},
			};
		}
		const p: any = [];
		if (!ozoner?._id || !form?._id) {
			financialFormCreateDTO.privacyPolicyAcceptance = {
				acceptedAt: new Date(),
				privacyPolicyVersion: '1.0.0',
				acceptanceMethod: AcceptanceMethodEnum.seller,
			};
			financialFormCreateDTO.termsOfServiceAcceptance = {
				acceptedAt: new Date(),
				termsVersion: '1.0.0',
				acceptanceMethod: AcceptanceMethodEnum.seller,
			};
			financialFormCreateDTO.status = FormStatus.pending;
		}
		const fullPaymentDTO = {
			privacyPolicyAcceptance: {
				acceptedAt: new Date(),
				privacyPolicyVersion: '1.0.0',
				acceptanceMethod: AcceptanceMethodEnum.seller,
			},
			termsOfServiceAcceptance: {
				acceptedAt: new Date(),
				termsVersion: '1.0.0',
				acceptanceMethod: AcceptanceMethodEnum.seller,
			},
			status: FormStatus.pending,
			vehicle: vehicle?._id ?? '',
			phone: fullPaymentPhone,
		};
		if (!ozoner?._id) {
			p.push(
				createOzoner({ ...(ozonerCreateDTO as CreateOzonerDto) }).then(
					(ozonerR) => {
						if (loanDuration === 1 && vehicle) {
							return createFullPaymentFinancialForm({
								...fullPaymentDTO,
								ozoner: ozonerR._id,
							}) as any;
						}
						return createLoanFinancialForm({
							...financialFormCreateDTO,
							ozoner: ozonerR._id,
						} as CreateLoanFinancialFormDto);
					},
				),
			);
		} else if (!form?._id) {
			p.push(
				loanDuration === 1 && vehicle
					? createFullPaymentFinancialForm({
							...fullPaymentDTO,
							ozoner: ozoner._id,
					  })
					: createLoanFinancialForm({
							...financialFormCreateDTO,
							ozoner: ozoner._id,
					  } as CreateLoanFinancialFormDto),
			);
			p.push(updateOzoner(ozoner._id, ozonerCreateDTO as UpdateOzonerDto));
		} else {
			if (Object.keys(ozonerCreateDTO).length) {
				p.push(updateOzoner(ozoner._id, ozonerCreateDTO as UpdateOzonerDto));
			}
			if (Object.keys(financialFormCreateDTO).length) {
				p.push(updateFinancialForm(form._id, financialFormCreateDTO));
			}
		}
		Promise.all(p)
			.then((data) => {
				setResult(data);
				clearForm();
				toasterNotification({
					msg: 'Se actualizó el Ozoner Correctamente',
					toasterType: toasterOptions.success,
					style: 'light',
				});
			})
			.catch((err) => {
				commonErrorHandler(err);
				setLoading(false);
			});
	};

	const handleRelation1Change = (e: any) => {
		if (e.target.value === 'Otro') setShowRef1OtherField(true);
		else setShowRef1OtherField(false);
	};

	const handleRelation2Change = (e: any) => {
		if (e.target.value === 'Otro') setShowRef2OtherField(true);
		else setShowRef2OtherField(false);
	};

	const clearPostalCode = () => {
		addressF.setFieldValue('city', '');
		addressF.setFieldValue('stateCode', '');
		addressF.setFieldValue('state', '');
		addressF.setFieldValue('delegation', '');
		addressF.setFieldValue('neighborhood', '');
		setNeighborhoodOptions([]);
		addressF.setFieldTouched('zipCode', true, true);
	};

	const updatePostalCode = async (value: string) => {
		if (value.length === 5) {
			setloadingCP(true);
			fetchByPostalCode(value)
				.then((response) => {
					setloadingCP(false);
					const { data } = response;
					addressF.setFieldValue('city', data[0].city);
					addressF.setFieldValue('state', data[0].state);
					addressF.setFieldValue('stateCode', stateMapping[data[0].stateCode]);
					addressF.setFieldValue('delegation', data[0].municipality);
					addressF.setFieldValue('neighborhood', '');
					setNeighborhoodOptions(data.map((i) => i.neighborhood));
					addressF.setFieldTouched('zipCode', true, true);
				})
				.catch((e) => {
					setloadingCP(false);
					clearPostalCode();
				});
		} else {
			clearPostalCode();
		}
	};

	const validateCURP = () => {
		if (ozonerF.values.curp) {
			setLoadingValidation(true);
			findOzonerByCurp(ozonerF.values.curp)
				.then((ozonerCurp) => {
					setLoadingValidation(false);
					if (ozonerCurp) {
						navigate(`/ozoner/${ozonerCurp._id}?openEdit=true`);
					} else {
						setCurpValidation(false);
					}
				})
				.catch((e) => {
					setLoadingValidation(false);
					toasterNotification({
						msg: e ?? 'Ocurrio un error',
						toasterType: toasterOptions.error,
						style: 'light',
					});
				});
		}
	};

	return (
		<>
			<SideModal
				open={open}
				setOpen={setOpen}
				fullOpen={fullOpen}
				setFullOpen={setFullOpen}
				size="normal"
			>
				<div className="flex_content_col">
					<div className="flex_header">
						<Typography
							scale="heading4"
							weight="600"
							textColor="neutral_900"
							className="m_b_lg m_t_md"
						>
							{label}
						</Typography>
						<Divider />
					</div>
					{curpValidation ? (
						<>
							<form>
								<Input
									onBlur={ozonerF.handleBlur}
									error={
										!!ozonerF.errors['curp'] &&
										(ozonerF.getFieldMeta('curp').touched || showErrors)
									}
									subtitle={
										// eslint-disable-next-line no-nested-ternary
										hasLoan
											? 'No se puede editar el CURP de un ozoner con crédito'
											: ozonerF.getFieldMeta('curp').touched || showErrors
											? (ozonerF.errors['curp'] as string)
											: undefined
									}
									title="CURP*"
									placeholder="Escribe el CURP aquí"
									name="curp"
									type="text"
									icon={<ID />}
									className="m_y_xs w_100_per"
									value={ozonerF.values.curp}
									onChange={ozonerF.handleChange}
									disabled={hasLoan}
								/>
							</form>
							<Button
								scale="small"
								orientation="right"
								className="w_100_per"
								onClick={() => {
									validateCURP();
								}}
								disabled={
									loadingValidation ||
									ozonerF.values['curp'] === '' ||
									!!ozonerF.errors['curp']
								}
							>
								Verificar Curp
							</Button>
						</>
					) : (
						<>
							<div className="flex_body p_y_lg p_x_sm overflow_x_hidden">
								<form>
									{sections[0] ? (
										<>
											<Typography
												scale="heading4"
												weight="600"
												textColor="neutral_700"
												className="m_b_xl"
											>
												Información basica
											</Typography>
											<Grid
												container
												direction="row"
												rowSpacing={0}
												columnSpacing={6}
												justifyContent="center"
												className=""
											>
												{Object.entries(ozonerFields).map(([key, value]) => (
													<Grid item xs={value.size} key={key}>
														<GenericFormInput
															params={{
																...value,
																form: ozonerF,
																forceDisplayError: showErrors,
																options:
																	key === 'city' ? cities : value.options,
																subtitle:
																	key === 'ozoner' && hasLoan
																		? 'No se puede editar el CURP de un ozoner con crédito'
																		: undefined,
															}}
														/>
													</Grid>
												))}
											</Grid>
										</>
									) : null}
									{!form?._id && sections[3] ? (
										<>
											<div className="flex_center m_b_md">
												<div className="dso_card bg_neutral_0 flex_center_col p_md">
													<Typography
														scale="xsmall"
														weight="600"
														textColor="neutral_900"
														className="text_center m_b_md"
													>
														{!vehicle
															? 'ASIGNAR VEHÍCULO'
															: vehicle?.internalId}
													</Typography>
													<div
														className="dso_card_img m_b_md bg_neutral_200 w_fit display_flex overflow_hidden border_primary_100"
														onClick={() => {
															setFullOpen(true);
														}}
														onKeyPress={() => {
															setFullOpen(true);
														}}
														role="button"
														tabIndex={0}
													>
														{vehicle ? (
															<img
																src={
																	vehicle.images[0]
																		? vehicle.images[0].url
																		: `${process.env.REACT_APP_STORAGE_BASE_URL}public/uploads/generic-moto.png`
																}
																alt=""
																className="cards_photo"
															/>
														) : (
															<Moto className="dim_xl m_lg border_transparent" />
														)}
													</div>
												</div>
											</div>
											{vehicle ? (
												<Input
													title="Tiempo de credito"
													placeholder="Selecciona el tiempo de credito"
													name="creditTime"
													icon={<Moto />}
													className="m_b_xs w_100_per"
													type="select"
													options={Object.values(vehicle?.salePrices ?? []).map(
														(price) => ({
															label: `${price.weeks} semanas`,
															value: price.weeks,
														}),
													)}
													value={loanDuration}
													onChange={(e: any) => {
														setLoanDuration(e.target.value);
													}}
													error={showErrors && !loanDuration}
													subtitle={
														showErrors && !loanDuration
															? ''
															: 'El tiempo de credito es requerido'
													}
												/>
											) : null}
										</>
									) : null}
									{loanDuration === 1 ? (
										<Input
											title="Teléfono"
											placeholder="Ingresa el numero telefonico"
											name="phone"
											type="text"
											icon={<Phone />}
											className="m_b_xs w_100_per"
											value={fullPaymentPhone}
											onChange={(e: any) => {
												setFullPaymentPhone(e.target.value);
											}}
											error={!fullPaymentPhone && showErrors}
											subtitle={
												!fullPaymentPhone && showErrors
													? 'El telefono es requerido'
													: ''
											}
										/>
									) : (
										<>
											{sections[1] ? (
												<>
													{sections[0] ? <Divider variant="middle" /> : null}
													<Typography
														scale="heading4"
														weight="600"
														textColor="neutral_700"
														className="m_y_xl"
													>
														Dirección
													</Typography>
													<Grid
														container
														direction="row"
														rowSpacing={0}
														columnSpacing={6}
														justifyContent="center"
														className=""
													>
														<Grid item xs={6}>
															<GenericFormInput
																params={{
																	...addressFields.zipCode,
																	form: addressF,
																	forceDisplayError: showErrors,
																	onChange: (e) => {
																		updatePostalCode(e.target.value);
																	},
																}}
															/>
															{loadingCP ? (
																<Typography
																	scale="large"
																	weight="600"
																	textColor="neutral_700"
																	className="m_y_sm text_center"
																>
																	Cargando...
																</Typography>
															) : null}
														</Grid>
														{Object.entries(addressFields)
															.filter(
																([key]) =>
																	!['zipCode', 'stateCode'].includes(key),
															)
															.map(([key, value]) => (
																<Grid item xs={value.size} key={key}>
																	<GenericFormInput
																		params={{
																			...value,
																			form: addressF,
																			forceDisplayError: showErrors,
																			options:
																				key === 'neighborhood'
																					? neighborhoodOptions
																					: value.options,
																		}}
																	/>
																</Grid>
															))}
													</Grid>
													<Button
														variant="outline"
														scale="small"
														onClick={() => setOpenLocation(true)}
														className="w_100_per"
													>
														Asignar ubicación exacta
													</Button>
													{location ? (
														<div className="bg_neutral_400 p_x_md p_y_xs flex_center m_t_md">
															<Typography
																scale="small"
																weight="600"
																textColor="neutral_700"
																className=""
															>
																{location.latitude},{location.longitude}
															</Typography>
														</div>
													) : (
														<Typography
															scale="medium"
															weight="600"
															textColor="red_300"
															className=" text_center m_t_md"
														>
															No se ha asignado ubicacion exacta
														</Typography>
													)}
												</>
											) : null}

											{sections[2] ? (
												<>
													{sections[0] || sections[1] ? (
														<Divider variant="middle" />
													) : null}

													<Typography
														scale="heading4"
														weight="600"
														textColor="neutral_700"
														className="m_y_xl"
													>
														Referencias personales
													</Typography>
													<Grid
														container
														direction="row"
														rowSpacing={0}
														columnSpacing={6}
														justifyContent="center"
														className=""
													>
														<Grid item xs={6}>
															{Object.entries(reference1Fields)
																.filter(
																	([key]) =>
																		key !== 'ref1other' || showRef1OtherField,
																)
																.map(([key, value]) => (
																	<GenericFormInput
																		params={{
																			...value,
																			form: referencesF,
																			forceDisplayError: showErrors,
																			onChange:
																				key === 'ref1relationship'
																					? handleRelation1Change
																					: undefined,
																		}}
																	/>
																))}
														</Grid>
														<Grid item xs={6}>
															{Object.entries(reference2Fields)
																.filter(
																	([key]) =>
																		key !== 'ref2other' || showRef2OtherField,
																)
																.map(([key, value]) => (
																	<GenericFormInput
																		params={{
																			...value,
																			form: referencesF,
																			forceDisplayError: showErrors,
																			onChange:
																				key === 'ref2relationship'
																					? handleRelation2Change
																					: undefined,
																		}}
																	/>
																))}
														</Grid>
													</Grid>
												</>
											) : null}
											{sections[3] ? (
												<>
													{sections[0] || sections[1] || sections[2] ? (
														<Divider variant="middle" />
													) : null}
													<Typography
														scale="heading4"
														weight="600"
														textColor="neutral_700"
														className="m_y_xxl"
													>
														Personal
													</Typography>

													<Grid
														container
														direction="row"
														rowSpacing={0}
														columnSpacing={6}
														justifyContent="center"
														className=""
													>
														{Object.entries(personalFields).map(
															([key, value]) => (
																<Grid item xs={value.size}>
																	<GenericFormInput
																		params={{
																			...value,
																			form: personalF,
																			forceDisplayError: showErrors,
																		}}
																	/>
																</Grid>
															),
														)}
													</Grid>

													<Divider variant="middle" />

													<Typography
														scale="heading4"
														weight="600"
														textColor="neutral_700"
														className="m_y_xxl"
													>
														Financiero
													</Typography>

													<Grid
														container
														direction="row"
														rowSpacing={0}
														columnSpacing={6}
														justifyContent="center"
														className=""
													>
														<Grid item xs={12}>
															<GenericFormInput
																params={{
																	...financialFields.monthlyIncome,
																	form: financialF,
																	forceDisplayError: showErrors,
																}}
															/>
														</Grid>
														<Grid item xs={12}>
															<GenericFormInput
																params={{
																	...financialFields.monthlyOutcome,
																	form: financialF,
																	forceDisplayError: showErrors,
																}}
															/>
														</Grid>
														<Grid item xs={12}>
															<GenericFormInput
																params={{
																	...financialFields.companyName,
																	form: financialF,
																	forceDisplayError: showErrors,
																}}
															/>
														</Grid>
													</Grid>
													<Grid
														container
														direction="row"
														rowSpacing={0}
														columnSpacing={6}
														justifyContent="center"
														className=""
													>
														<Grid item sm={6}>
															<GenericFormInput
																params={{
																	...financialFields.economicActivity,
																	form: financialF,
																	forceDisplayError: showErrors,
																}}
															/>
															{financialF.values.economicActivity ===
															EconomicActivityEnum.otro ? (
																<GenericFormInput
																	params={{
																		...financialFields.economicActivityOther,
																		form: financialF,
																		forceDisplayError: showErrors,
																	}}
																/>
															) : null}
														</Grid>
														<Grid item xs={6}>
															<GenericFormInput
																params={{
																	...financialFields.assets,
																	form: financialF,
																	forceDisplayError: showErrors,
																}}
															/>
															{(financialF.values.assets as string[]).includes(
																'otro',
															) ? (
																<GenericFormInput
																	params={{
																		...financialFields.assetsOther,
																		form: financialF,
																		forceDisplayError: showErrors,
																	}}
																/>
															) : null}
														</Grid>
													</Grid>
													<div className="display_flex flex_align_center flex_gap_xxl m_y_xl">
														<div>
															<div className="display_flex flex_gap_xs flex_align_center m_b_xs">
																<Ruler className="dim_lg text_primary_300" />
																<Typography
																	scale="medium"
																	weight="600"
																	textColor="neutral_900"
																>
																	¿El ozoner trabaja en plataformas digitales?
																</Typography>
															</div>
															<Typography
																scale="xsmall"
																weight="400"
																textColor="neutral_900"
															>
																Se enviara un correo al ozoner para la
																validacion de su cuenta
															</Typography>
														</div>
														<div className="flex_center p_x_sm bg_neutral_300 br_xs">
															<Input
																title=""
																name="workInDigitalPlatforms"
																type="slider"
																value={financialF.values.workInDigitalPlatforms}
																onChange={financialF.handleChange}
															/>
														</div>
													</div>
												</>
											) : null}
										</>
									)}
								</form>
							</div>

							<div className="flex_footer">
								<Button
									scale="large"
									orientation="right"
									className="w_100_per"
									type="submit"
									onClick={() => {
										setShowErrors(true);
										onSubmit();
									}}
									disabled={
										loading ||
										(!ozonerF.dirty &&
											!addressF.dirty &&
											!referencesF.dirty &&
											!personalF.dirty &&
											!financialF.dirty &&
											!vehicle)
										// TODO: Fix this
										// &&
										// (!ozoner?.fullAddress || !location || ozoner.fullAddress.lat === location?.latitude )&&
										// (!ozoner?.fullAddress || !location || ozoner.fullAddress.long === location?.longitude )
									}
								>
									Guardar
								</Button>
							</div>
						</>
					)}
				</div>
				{fullOpen && (
					<Inventory
						onClick={(v: any) => {
							setVehicle(v);
							setFullOpen(false);
						}}
						initialFilter={[VehicleStatus.AVAILABLE]}
						availableFilters={{
							available: true,
							delivered: false,
							aside: false,
							lost: false,
							inRecovery: false,
							recovered: false,
							left: false,
							underMaintenance: false,
							draft: false,
						}}
					/>
				)}
			</SideModal>
			{openLocation && (
				<LocationModalEdit
					openModal={openLocation}
					setOpenModal={setOpenLocation}
					addressData={addressF.values}
					saveLocation={setLocation}
					initialLocation={location}
				/>
			)}
		</>
	);
}

export default EditOzoner;
