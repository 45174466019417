/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';
import { Grid } from '@mui/material';
import moment from 'moment';

import {
	Button,
	DateSelect,
	Pager,
	Popup,
	ProgressBar,
	Select,
	Typography,
} from 'design_system/src';

import { ReactComponent as Moto } from 'design_system/src/static/icons/motorcycle.svg';
import { ReactComponent as AddCircle } from 'design_system/src/static/icons/add-circle.svg';
import { ReactComponent as Signal } from 'design_system/src/static/icons/radio-signal.svg';
import { ReactComponent as NoSignal } from 'design_system/src/static/icons/no-radio-signal.svg';
import { ReactComponent as Home } from 'design_system/src/static/icons/temple.svg';
import { ReactComponent as Hotspot } from 'design_system/src/static/icons/fire-camp.svg';
import { ReactComponent as Action } from 'design_system/src/static/icons/more-v.svg';

import { toasterOptions } from 'models/shared/enums/toaster.enum';
import {
	FetchRecoveries,
	FetchRoutes,
	editRoute,
} from 'models/repossession/controllers/repossession.controller';

import { Route } from 'models/repossession/interfaces/route.interface';
import { toasterNotification } from 'models/shared/helpers/toasterNotification';
import {
	RouteStatus,
	RouteStatusValues,
} from 'models/repossession/enums/route.enums';
import { momentLocal } from 'models/shared/helpers/dates';
import { PaginatedDocuments } from 'models/shared/types/pagination';
import { InterestPointType } from 'models/repossession/enums/repossession.enums';
import { RecoveryStatus } from 'models/repossession/enums/recovery.enums';
import {
	getRepossessionsFilter,
	setRepossessionsFilter,
} from 'localStorage/filterStorage';
import motoLoading from '../../../static/icons/loading_moto.gif';
import { tabs } from '../Repossessions.constants';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY || '';

interface RoutesProps {
	ozonios: any[];
	viewTab: number;
	setViewTab: Function;

	setSelecteds: Function;
	setRouteDate: Function;
	setRouteOzonio: Function;
	setOpenRuta: Function;
	setIdRuta: Function;
}

const Routes: React.FC<RoutesProps> = ({
	ozonios,
	viewTab,
	setViewTab,
	setSelecteds,
	setRouteDate,
	setRouteOzonio,
	setOpenRuta,
	setIdRuta,
}) => {
	const navigate = useNavigate();
	const [actualPage, setActualPage] = useState<number>(1);
	const [openAction, setOpenAction] = useState<Route>();

	const [routes, setRoutes] = useState<Route[]>([]);
	const [datesFilter, setDatesFilter] = useState({
		initial: new Date(),
		last: new Date(),
		option: -1,
	});
	const [hasDatesFilter, setHasDatesFilter] = useState(false);
	const [ozonioFilter, setOzonioFilter] = useState('');
	const [count, setCount] = useState(0);
	const [totalPages, setTotalPages] = useState<number>(0);

	const fetchRoutes = () => {
		FetchRoutes(
			hasDatesFilter
				? {
						start: datesFilter.initial,
						end: datesFilter.last,
				  }
				: undefined,
			ozonioFilter,
		).then((resp: PaginatedDocuments<Route>) => {
			const newRoutes = resp.data;
			newRoutes.forEach((route) => {
				let routeSignal = 0;
				let routeNoSignal = 0;
				let address = 0;
				let hotspots = 0;
				let completed = 0;
				route.recoveries.forEach((recovery) => {
					if (recovery.interestPoint?.type === InterestPointType.address) {
						address += 1;
					} else if (
						recovery.interestPoint?.type === InterestPointType.hotspot
					) {
						hotspots += 1;
					} else if (
						recovery.interestPoint?.type === InterestPointType.signalVehicle
					) {
						routeSignal += 1;
					} else {
						routeNoSignal += 1;
					}
					if (recovery.status === RecoveryStatus.recovered) {
						completed += 1;
					}
				});
				// eslint-disable-next-line no-param-reassign
				route.totals = {
					signal: routeSignal,
					noSignal: routeNoSignal,
					address,
					hotspots,
					completed,
				};
			});
			setRoutes(newRoutes);
			setTotalPages(resp.pages);
			setCount(resp.count);
		});
	};

	const editRuta = async (ruta: Route) => {
		const recoveries = await FetchRecoveries(ruta._id);

		setOpenAction(undefined);
		setViewTab(1);
		setSelecteds(recoveries.data.sort((a, b) => a.index - b.index));
		setRouteDate(momentLocal(ruta.dateEstimate).format('YYYY-MM-DD'));
		setRouteOzonio(ruta.teamLeader._id);
		setOpenRuta(true);
		setIdRuta(ruta._id);
	};

	const selectRuta = (ruta: Route) => {
		navigate(`/route/${ruta._id}`);
	};
	const cancelRoute = () => {
		setOpenAction(undefined);
		if (openAction) {
			editRoute(openAction._id, { status: RouteStatus.canceled })
				.then(() => {
					fetchRoutes();
					toasterNotification({
						msg: 'Se cancelo la ruta',
						toasterType: toasterOptions.success,
						style: 'light',
					});
				})
				.catch(() => {
					toasterNotification({
						msg: 'Ocurrio un error',
						toasterType: toasterOptions.error,
						style: 'light',
					});
				});
		}
	};

	useEffect(() => {
		const information = getRepossessionsFilter();
		if (information?.datesFilter) {
			setDatesFilter({
				...information.datesFilter,
				initial: new Date(information.datesFilter.initial),
				last: new Date(information.datesFilter.last),
			});

			setHasDatesFilter(true);
		} else {
			const weekDate = new Date();
			weekDate.setDate(weekDate.getDate() + 8);
			setDatesFilter({
				initial: new Date(),
				last: weekDate,
				option: -1,
			});
			setHasDatesFilter(true);
		}
		if (information.filterOzonio) {
			setOzonioFilter(information.filterOzonio);
		}
		fetchRoutes();
	}, []);
	useEffect(() => {
		fetchRoutes();
	}, [hasDatesFilter, datesFilter, ozonioFilter]);

	useEffect(() => {
		setRepossessionsFilter({
			viewTab,
			datesFilter: hasDatesFilter ? datesFilter : null,
			filterOzonio: ozonioFilter,
		});
	}, [viewTab, datesFilter, hasDatesFilter, ozonioFilter]);

	return (
		<>
			{/* Loader */}
			<div className="w_100_per bg_neutral_0 flex_header p_x_lg p_y_md">
				<div className="display_flex flex_align_center flex_justify_between m_b_md">
					<Typography
						scale="heading3"
						weight="600"
						className="text_primary_300"
					>
						Rutas
					</Typography>
					<div className="display_flex flex_align_center flex_gap_sm p_x_xs p_y_xxs bg_neutral_300 br_xs">
						{tabs.map((tab, i) => (
							<Typography
								scale="medium"
								key={tab}
								weight={i === viewTab ? '600' : '400'}
								textColor={i === viewTab ? 'neutral_1000' : 'neutral_700'}
								className={`p_x_md p_y_xxs br_xs cursor_pointer ${
									i === viewTab ? 'bg_neutral_0 shadow_soft' : ''
								}`}
								onClick={() => setViewTab(i)}
							>
								{tab}
							</Typography>
						))}
					</div>
				</div>
				<div className="display_flex flex_align_center flex_gap_md">
					<Select
						name="sortVehicles"
						options={[{ label: 'Todos los encargados', value: '' }, ...ozonios]}
						className="w_20_vp"
						placeholder="Selecciona un encargado"
						value={ozonioFilter}
						onChange={(e: any) => setOzonioFilter(e.target.value)}
					/>
					<DateSelect
						value={datesFilter}
						hasValue={hasDatesFilter}
						setHasValue={setHasDatesFilter}
						placeholder="Fecha de creación"
						setValue={(e: any) => {
							setDatesFilter(e);
							setHasDatesFilter(true);
						}}
					/>
				</div>
			</div>
			{!routes ? (
				<div className="loader_map flex_center">
					<Typography
						weight="600"
						scale="medium"
						textColor="neutral_0"
						className="m_b_xs"
					>
						Cargando la información...
					</Typography>
					<img src={motoLoading} alt="" className="h_xxxl" />
				</div>
			) : null}
			<div className="flex_body p_x_xxxl overflow_x_hidden bg_neutral_300">
				<Grid
					container
					direction="row"
					spacing={3}
					className="h_100_per p_t_md spaced"
				>
					{routes?.map((route, i) => (
						<Grid item xs={6} sm={3} md={3} key={i}>
							<div className="br_sm bg_neutral_0">
								<div className="display_flex flex_justify_between p_md p_b_xs border_b_solid border_neutral_300 border_1">
									<div>
										<Typography
											weight="600"
											scale="medium"
											textColor="neutral_1000"
											className=""
										>
											{momentLocal(route.dateEstimate)
												.locale('es')
												.format('dddd, D MMMM')}
										</Typography>
										<Typography
											weight="400"
											scale="small"
											textColor="neutral_600"
											className=""
										>
											{route.teamLeader.name}
										</Typography>
									</div>
									<div className="pos_relative">
										<Action
											className="dim_md cursor_pointer border_transparent"
											tabIndex={0}
											role="button"
											onKeyDown={() =>
												setOpenAction(openAction ? undefined : route)
											}
											onClick={() =>
												setOpenAction(openAction ? undefined : route)
											}
										/>
										<Popup
											open={openAction?._id === route._id}
											setOpen={(action: boolean) =>
												setOpenAction(action ? route : undefined)
											}
											placementX="left-inset"
											className="bg_neutral_100 br_xxs"
										>
											<div
												className="p_x_md p_y_xs action_option cursor_pointer"
												tabIndex={0}
												role="button"
												onKeyDown={() => editRuta(route)}
												onClick={() => editRuta(route)}
											>
												<Typography
													scale="medium"
													weight="400"
													className="text_no_break"
												>
													Editar ruta
												</Typography>
											</div>
											{/* <div className="p_x_md p_y_xs action_option cursor_pointer" tabIndex={0} role="button" onKeyDown={()=>{}} onClick={()=>{}}>
                            <Typography scale="medium" weight="400" className="text_no_break">Duplicar ruta</Typography>
                            </div> */}
											<div
												className="p_x_md p_y_xs action_option cursor_pointer"
												tabIndex={0}
												role="button"
												onKeyDown={() => cancelRoute()}
												onClick={() => cancelRoute()}
											>
												<Typography
													scale="medium"
													weight="400"
													className="text_no_break"
												>
													Cancelar ruta
												</Typography>
											</div>
										</Popup>
									</div>
								</div>
								<div className="p_md p_y_xs border_b_solid border_neutral_300 border_1">
									<div className="display_flex flex_gap_xs flex_align_center">
										<Moto className="dim_md" />
										<Typography
											weight="400"
											scale="medium"
											textColor="neutral_1000"
											className=""
										>
											{route.recoveries.length} motos a recuperar
										</Typography>
									</div>
									<div className="flex_center">
										<Typography
											weight="600"
											scale="small"
											textColor={
												RouteStatusValues[route.status].textColor as any
											}
											className={`bg_${
												RouteStatusValues[route.status].color
											}_nocontrast br_xl p_y_xxs p_x_sm m_y_xs`}
										>
											{RouteStatusValues[route.status].title}
										</Typography>
									</div>
									<div className="display_flex flex_justify_between">
										<div className="display_flex flex_gap_xs flex_align_center text_green_300">
											<Signal className="dim_md" />
											<Typography weight="600" scale="medium" className="">
												{route.totals?.signal ?? 0} con señal
											</Typography>
										</div>
										<div className="display_flex flex_gap_xs flex_align_center text_red_300">
											<NoSignal className="dim_md" />
											<Typography weight="600" scale="medium" className="">
												{route.totals?.noSignal ?? 0} sin señal
											</Typography>
										</div>
									</div>
									<div className="display_flex flex_justify_between m_t_xs">
										<div className="display_flex flex_gap_xs flex_align_center text_yellow_100">
											<Home className="dim_md" />
											<Typography weight="600" scale="medium" className="">
												{route.totals?.address ?? 0} Hogares
											</Typography>
										</div>
										<div className="display_flex flex_gap_xs flex_align_center text_primary_300">
											<Hotspot className="dim_md" />
											<Typography weight="600" scale="medium" className="">
												{route.totals?.hotspots ?? 0} Hotspots
											</Typography>
										</div>
									</div>
								</div>
								<div className="p_md">
									<Typography
										weight="400"
										scale="small"
										textColor="neutral_600"
										className=""
									>
										Recuperaciones completadas
									</Typography>
									<div className=" p_y_xs display_flex flex_align_center flex_gap_md m_b_xs">
										<Typography
											scale="small"
											weight="600"
											textColor="primary_300"
											className=""
										>
											{route.totals?.completed}/{route.recoveries?.length}{' '}
										</Typography>
										<ProgressBar
											containerClassName="bg_neutral_400 h_sm"
											fillClassName="bg_primary_300 h_sm"
											percentage={
												((route.totals?.completed ?? 0) * 100) /
												route.recoveries.length
											}
										/>
									</div>
									<Button
										scale="small"
										className="w_100_per"
										onClick={() => selectRuta(route)}
									>
										Ver ruta
									</Button>
								</div>
							</div>
						</Grid>
					))}
				</Grid>
			</div>
			<div className="display_flex flex_align_center flex_justify_between flex_footer bg_neutral_0 p_x_xxxl p_y_md">
				<Button
					scale="small"
					icon={<AddCircle />}
					className="w_30_per"
					onClick={() => setViewTab(0)}
				>
					Crear nueva ruta
				</Button>
				<Pager
					totalPages={totalPages}
					setPage={setActualPage}
					page={actualPage}
					count={count}
					className="w_fit"
				/>
			</div>
		</>
	);
};

export default Routes;
