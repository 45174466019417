/* eslint-disable no-shadow */

export enum OzonerStatus {
	lead = 'lead',
	inactive = 'inactive',
	active = 'active',
}

export const OzonerStatusValues = {
	lead: {
		title: 'Lead',
		color: 'yellow_100',
	},
	active: {
		title: 'Activo',
		color: 'green_300',
	},
	inactive: {
		title: 'Inactivo',
		color: 'neutral_700',
	},
};

export const ozonerFields = {
	name: 'Nombre',
	lastName: 'Apellido',
	city: 'Ciudad',
	curp: 'CURP',
	email: 'Correo electrónico',
	createdOn: 'Creado en',
	status: 'Estado',
	geolocations: 'Geolocalizaciones',
	account: 'Cuenta',
	comment: 'Comentario',
	rfc: 'RFC',
	fiscalStatus: 'Estatus fiscal',
	image: 'Imagen',
	documents: 'Documentos',
	createdAt: 'Fecha de creación',
	updatedAt: 'Fecha de actualización',
};
