/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */

import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { ReactComponent as Moto } from 'design_system/src/static/icons/motorcycle.svg';
import { ReactComponent as Chat } from 'design_system/src/static/icons/group-chat.svg';
import { ReactComponent as CircleAdd } from 'design_system/src/static/icons/add-circle.svg';
import { ReactComponent as Close } from 'design_system/src/static/icons/close-circle.svg';
import { ReactComponent as Document } from 'design_system/src/static/icons/document-tick.svg';
import { ReactComponent as Info } from 'design_system/src/static/icons/info.svg';

import { ReactComponent as Mail } from 'design_system/src/static/icons/mail.svg';
import { ReactComponent as GPS } from 'design_system/src/static/icons/gps.svg';
import { ReactComponent as Calc } from 'design_system/src/static/icons/calculator.svg';
import { ReactComponent as Time } from 'design_system/src/static/icons/user-card-2.svg';

import {
	Button,
	ModalConfirmation as Modal,
	Typography,
	Input,
	CheckboxInput,
} from 'design_system/src';
import 'moment/locale/es';
import Swal from 'sweetalert2';
import * as yup from 'yup';
import { useFormik } from 'formik';

import {
	estimateRestructureLoan,
	reestructureLoan,
	uploadFileLoan,
} from 'models/loan/controllers/loan.controller';
import { EvidenceDocumentType } from 'models/shared/types/documents';
import { updateOzoner } from 'models/ozoner/controllers/ozoner.controller';
import { updateFinancialForm } from 'models/financial-form/controllers/financial-form.controller';
import {
	CreateCreditRepossession,
	CreateCreditRepossessionDevolution,
	EditRepossession,
} from 'models/repossession/controllers/repossession.controller';
import { RepossessionStatus } from 'models/repossession/enums/repossession.enums';
import { commonErrorHandler } from 'models/shared/helpers/errors';
import { LoanStatus } from 'models/loan/enums/loan.enum';
import { priceFormatFinancial } from 'models/shared/helpers/prices';
import { fetchByPostalCode } from 'models/shared/controllers/moffin';
import { stateMapping } from 'models/shared/types/moffin';
import { ozonerContext } from '../OzonerContext';
import DevolutionLoan from '../modals/DevolutionLoan';

const addressVS = yup.object({
	zipCode: yup
		.string()
		.required('Campo requerido')
		.matches(/^\d{5}$/, 'Debe ser un número de 5 dígitos.')
		.test('zipCode', 'El codigo postal no existe', function test(value) {
			const { state } = this.parent;
			if (value && value.length === 5 && !state) {
				return this.createError({
					message: 'El codigo postal no existe',
				});
			}
			return true;
		})
		.required('El código es requerido'),
	city: yup.string(),
	state: yup.string().required('Campo requerido'),
	stateCode: yup.string().required('Campo requerido'),
	delegation: yup.string().required('Campo requerido'),
	neighborhood: yup.string().required('Campo requerido'),
	street: yup.string().required('Campo requerido'),
	extNumber: yup.string().required('Campo requerido'),
	intNumber: yup.string(),
});

const formatName = (name: string) => name.replaceAll(' ', '_');

let petition = 0;
const CollectionOzoner = () => {
	const { ozoner, financialForm, initializeContext, updateLoanData } =
		useContext(ozonerContext);

	const [openRestructure, setOpenRestructure] = useState(false);
	const [restructureFetching, setRestructureFetching] = useState(false);
	const [restructureInfo, setRestructureInfo] = useState<any>();
	const [restructureLoading, setRestructureLoading] = useState(false);
	const [newPayment, setNewPayment] = useState<number>();
	const [newDuration, setNewDuration] = useState<number>();

	const [neighborhoodOptions, setNeighborhoodOptions] = useState<string[]>([]);
	const [loadingCP, setloadingCP] = useState(false);

	const addressF = useFormik({
		initialValues: {
			zipCode: '',
			city: '',
			stateCode: '',
			state: '',
			delegation: '',
			neighborhood: '',
			street: '',
			extNumber: '',
			intNumber: '',
		},
		validateOnMount: true,
		enableReinitialize: true,
		validationSchema: addressVS,
		onSubmit: () => {},
	});

	const [openRepossessionModal, setOpenRepossessionModal] = useState(false);
	const [repossessionFile, setRepossessionFile] = useState<File>();
	const [repossessionComment, setRepossessionComment] = useState('');
	const [openRepossessionCancelModal, setOpenRepossessionCancelModal] =
		useState(false);
	const [repossessionIsDevolution, setRepossessionIsDevolution] =
		useState(false);
	const [useOzonerAddress, setUseOzonerAddress] = useState(true);
	const [hasValidAddress, setHasValidAddress] = useState(false);

	useEffect(() => {
		addressVS
			.validate(financialForm.form?.personalInformation?.fullAddress)
			.then(() => setHasValidAddress(true))
			.catch((e) => setHasValidAddress(false));
	}, [repossessionIsDevolution, useOzonerAddress]);

	useEffect(() => {
		setRepossessionComment(ozoner?.comment ?? '');
		if (financialForm.form?.personalInformation?.fullAddress) {
			addressF.setValues(financialForm.form.personalInformation.fullAddress);
		}
	}, [financialForm]);

	const closeRepossessionModal = () => {
		setOpenRepossessionModal(false);
		setOpenRepossessionCancelModal(false);
		setRepossessionFile(undefined);
		setRepossessionComment(ozoner?.comment ?? '');
		setRepossessionIsDevolution(false);
		setUseOzonerAddress(true);
		addressF.resetForm();
	};
	const createRepossission = () => {
		if (repossessionFile && financialForm.loan && ozoner) {
			const p = [];
			p.push(
				uploadFileLoan(
					financialForm.loan._id,
					repossessionFile,
					EvidenceDocumentType.other,
					repossessionIsDevolution
						? 'Evidencia de devolucion'
						: 'Evidencia recuperacion',
				),
			);
			p.push(updateOzoner(ozoner._id, { comment: repossessionComment }));
			if (repossessionIsDevolution) {
				let addressData;
				if (useOzonerAddress) {
					if (hasValidAddress) {
						addressData = {
							zipCode: `${financialForm.form?.personalInformation?.fullAddress.zipCode}`,
							street: `${financialForm.form?.personalInformation?.fullAddress.street}`,
							extNumber: `${financialForm.form?.personalInformation?.fullAddress.extNumber}`,
						};
					} else {
						p.push(
							updateFinancialForm(financialForm.form._id, {
								personalInformation: {
									fullAddress: {
										...financialForm.form.personalInformation?.fullAddress,
										...addressF.values,
									},
								},
							}),
						);
						addressData = {
							zipCode: `${addressF.values.zipCode}`,
							street: `${addressF.values.street}`,
							extNumber: `${addressF.values.extNumber}`,
						};
					}
				} else {
					addressData = {
						zipCode: `${addressF.values.zipCode}`,
						street: `${addressF.values.street}`,
						extNumber: `${addressF.values.extNumber}`,
					};
				}
				p.push(
					CreateCreditRepossessionDevolution(
						financialForm.loan._id,
						addressData,
					),
				);
			} else {
				p.push(CreateCreditRepossession(financialForm.loan._id));
			}

			Promise.all(p).then(() => {
				closeRepossessionModal();
				initializeContext();
			});
		}
	};
	const cancelRepossission = () => {
		if (financialForm.repossession && ozoner) {
			const p = [];
			p.push(updateOzoner(ozoner._id, { comment: repossessionComment }));
			p.push(
				EditRepossession(financialForm.repossession._id, {
					status: RepossessionStatus.canceled,
					comment: 'cancelado por cobranza',
				}),
			);

			Promise.all(p).then(() => {
				closeRepossessionModal();
				initializeContext();
			});
		}
	};

	const handleFile = (e: any) => {
		const file = e.target.files[0];
		if (file.size > 10000000) {
			Swal.fire({
				text: 'La imagen no debe pesar mas de 10mb',
				icon: 'warning',
				confirmButtonText: 'Aceptar',
			});
		} else if (ozoner) {
			const blob = file.slice(0, file.size, file.type);
			const newFile = new File([blob], formatName(file.name), {
				type: file.type,
			});
			setRepossessionFile(newFile);
		}
	};

	const clearPostalCode = () => {
		addressF.setFieldValue('city', '');
		addressF.setFieldValue('stateCode', '');
		addressF.setFieldValue('state', '');
		addressF.setFieldValue('delegation', '');
		addressF.setFieldValue('neighborhood', '');
		setNeighborhoodOptions([]);
		addressF.setFieldTouched('zipCode', true, true);
	};

	const updatePostalCode = async (value: string) => {
		if (value.length === 5) {
			setloadingCP(true);
			fetchByPostalCode(value)
				.then((response) => {
					setloadingCP(false);
					const info = response?.data;
					addressF.setFieldValue('city', info[0].city);
					addressF.setFieldValue('state', info[0].state);
					addressF.setFieldValue('stateCode', stateMapping[info[0].stateCode]);
					addressF.setFieldValue('delegation', info[0].municipality);
					addressF.setFieldValue('neighborhood', '');
					setNeighborhoodOptions(info.map((i) => i.neighborhood));
					addressF.setFieldTouched('zipCode', true, true);
				})
				.catch((e) => {
					setloadingCP(false);
					clearPostalCode();
				});
		} else {
			clearPostalCode();
		}
	};
	const closeRestructure = () => {
		setOpenRestructure(false);
		setRestructureInfo(undefined);
		setNewDuration(undefined);
		setNewPayment(undefined);
		setRestructureFetching(false);
		setRestructureLoading(false);
	};

	const fetchReestructure = (value: string, type: boolean) => {
		petition = Date.now();
		const request = petition;
		setRestructureInfo(undefined);
		if (value && financialForm.loan) {
			const values = {
				[type ? 'paymentCapacity' : 'weeks']: parseInt(value, 10),
			};
			setRestructureFetching(true);
			estimateRestructureLoan(financialForm.loan._id, values)
				.then((result) => {
					if (petition === request) {
						setRestructureFetching(false);
						setRestructureInfo(result);
						setNewPayment(result.newPaymentWeek);
						setNewDuration(result.newDuration);
					}
				})
				.catch((e) => {
					setRestructureFetching(false);
					commonErrorHandler(e);
				});
		}
	};
	const applyRestructure = () => {
		if (newPayment && newDuration && financialForm.loan) {
			const values = {
				paymentCapacity: parseInt(`${newPayment}`, 10),
			};
			setRestructureLoading(true);
			reestructureLoan(financialForm.loan._id, values)
				.then((newLoan) => {
					setRestructureLoading(false);
					closeRestructure();
					updateLoanData(newLoan);
				})
				.catch((e) => {
					setRestructureLoading(false);
					commonErrorHandler(e);
				});
		}
	};

	// window.open(`https://app7.porcobrar.com/portal/${porcobrarId}`, "_blank");

	return financialForm.loan ? (
		<>
			<div className="">
				{![
					LoanStatus.completed,
					LoanStatus.devolution,
					LoanStatus.repossession,
				].includes(financialForm.loan?.status) && (
					<div
						id="repossession-cobranza"
						className="p_md border_b_solid border_1 border_neutral_400"
					>
						<Typography scale="medium" weight="400" className=" m_b_xs">
							Generar una recuperacion
						</Typography>
						{financialForm.repossession &&
						financialForm.repossession.status !== RepossessionStatus.canceled &&
						financialForm.repossession.status !==
							RepossessionStatus.completed ? (
							<>
								<div className="flex_center flex_gap_md bg_red_200 text_red_300 br_xs p_y_xs w_100_per m_b_md">
									<Info className="dim_lg" />
									<Typography scale="small" weight="600" className="">
										Vehículo en recuperación
									</Typography>
								</div>
								<Button
									scale="small"
									variant="outline"
									className="w_100_per"
									icon={<Close />}
									onClick={() => setOpenRepossessionCancelModal(true)}
								>
									Cancelar recuperación
								</Button>
							</>
						) : (
							<Button
								scale="small"
								variant="outline"
								className="w_100_per"
								icon={<Moto />}
								onClick={() => setOpenRepossessionModal(true)}
							>
								Recuperar el vehiculo
							</Button>
						)}
					</div>
				)}
				{![
					LoanStatus.completed,
					LoanStatus.devolution,
					LoanStatus.repossession,
				].includes(financialForm.loan.status) && (
					<>
						<div
							id="restructure-cobranza"
							className="p_md border_b_solid border_1 border_neutral_400"
						>
							<Typography scale="medium" weight="400" className=" m_b_xs">
								Re-estructurar crédito
							</Typography>

							<Button
								scale="small"
								variant="outline"
								className="w_100_per"
								onClick={() => setOpenRestructure(true)}
							>
								Re-estructurar
							</Button>
						</div>

						<DevolutionLoan />
					</>
				)}
			</div>
			{openRepossessionModal && (
				<Modal
					openConfirmation={openRepossessionModal}
					handleClose={closeRepossessionModal}
					// onConfirmationCallBack={addAgreement}
					// eslint-disable-next-line react/jsx-no-useless-fragment
					icon={<Moto className="text_primary_300" />}
					widthDesktop={40}
					isConfirmationLoading={false}
					actions={[
						{
							label: 'Cancelar',
							color: 'grey',
							action: closeRepossessionModal,
						},
						{
							label: 'Aceptar',
							color: 'primary',
							action: () => createRepossission(),
							disabled:
								!repossessionFile ||
								(repossessionIsDevolution &&
									((useOzonerAddress &&
										!hasValidAddress &&
										!addressF.isValid) ||
										(!useOzonerAddress &&
											(!addressF.isValid || !addressF.dirty)))),
						},
					]}
				>
					<>
						<Typography scale="large" weight="600" className="m_b_xs">
							Deseas recuperar el vehículo
						</Typography>
						<Typography
							scale="medium"
							weight="400"
							className="m_b_md"
							textColor="neutral_700"
						>
							Al activar esta opción el equipo de recuperaciones podrá ver con
							prioridad este vehículo e iniciara un proceso de recuperación
							adjunta un pantallazo del contacto del Ozoner y llena las
							observaciones para la recuperación.
						</Typography>

						<div className="display_flex flex_align_center flex_justify_between flex_gap_xxl">
							<Typography scale="medium" weight="600" textColor="neutral_900">
								¿Es una devolución voluntaria del Ozoner?
							</Typography>
							<div className="flex_center bg_neutral_300 br_xs">
								<Input
									title=""
									name="isDevolution"
									type="slider"
									labels
									value={repossessionIsDevolution}
									onChange={(e: any) =>
										setRepossessionIsDevolution(e.target.value)
									}
								/>
							</div>
						</div>
						{repossessionIsDevolution && (
							<>
								<div className="flex_center">
									<CheckboxInput
										className="m_b_lg flex_grow_0"
										checked={useOzonerAddress}
										value=""
										label="¿La devolución es en el domicilio del Ozoner?"
										onChange={() => {
											if (!useOzonerAddress) {
												addressF.setValues(
													financialForm.form?.personalInformation
														?.fullAddress ?? {
														city: '',
														zipCode: '',
														state: '',
														stateCode: '',
														delegation: '',
														neighborhood: '',
														street: '',
														extNumber: '',
														intNumber: '',
													},
												);
											} else {
												addressF.resetForm();
											}
											setUseOzonerAddress(!useOzonerAddress);
										}}
									/>
								</div>
								{!hasValidAddress ? (
									<div
										id="info-soli-ozoner"
										className="p_xs flex_center_col bg_red_200 flex_gap_xs br_sm shadow_soft border_1 border_solid border_neutral_400 m_b_lg"
									>
										<div className="flex_center flex_gap_xs">
											<Info className="dim_lg text_red_300" />
											<Typography
												scale="small"
												weight="600"
												textColor="red_300"
												className="text_center"
											>
												La dirección del ozoner esta incompleta, llenar
												correctamente antes de continuar
											</Typography>
										</div>
									</div>
								) : null}
								{!useOzonerAddress || !hasValidAddress ? (
									<Grid
										container
										direction="row"
										rowSpacing={0}
										columnSpacing={6}
										justifyContent="center"
										className=""
									>
										<Grid item xs={6}>
											<Input
												onBlur={(e) => {
													addressF.handleBlur(e);
												}}
												error={
													!!addressF.errors.zipCode &&
													addressF.getFieldMeta('zipCode').touched
												}
												subtitle={
													addressF.getFieldMeta('zipCode').touched
														? (addressF.errors.zipCode as string)
														: undefined
												}
												title="Código postal*"
												name="zipCode"
												type="text"
												className="m_b_xs w_100_per"
												icon={<Mail />}
												placeholder="Escribe el código postal"
												value={addressF.values.zipCode}
												onChange={(e: any) => {
													addressF.handleChange(e);
													updatePostalCode(e.target.value);
												}}
											/>
											{loadingCP ? (
												<Typography
													scale="large"
													weight="600"
													textColor="neutral_700"
													className="m_y_sm text_center"
												>
													Cargando...
												</Typography>
											) : null}
										</Grid>
										<Grid item xs={6}>
											<Input
												onBlur={addressF.handleBlur}
												error={
													!!addressF.errors.city &&
													addressF.getFieldMeta('city').touched
												}
												subtitle={
													addressF.getFieldMeta('city').touched
														? (addressF.errors.city as string)
														: undefined
												}
												title="Ciudad*"
												name="city"
												icon={<GPS />}
												disabled
												placeholder="Selecciona el estado"
												type="text"
												className="m_b_xs w_100_per"
												value={addressF.values.city}
												onChange={(e: any) => {
													addressF.handleChange(e);
												}}
											/>
										</Grid>
										<Grid item xs={6}>
											<Input
												onBlur={addressF.handleBlur}
												error={
													!!addressF.errors.state &&
													addressF.getFieldMeta('state').touched
												}
												subtitle={
													addressF.getFieldMeta('state').touched
														? (addressF.errors.state as string)
														: undefined
												}
												title="Estado*"
												name="state"
												icon={<GPS />}
												disabled
												placeholder="Selecciona el estado"
												type="text"
												className="m_b_xs w_100_per"
												value={addressF.values.state}
												onChange={(e: any) => {
													addressF.handleChange(e);
												}}
											/>
										</Grid>
										<Grid item xs={6}>
											<Input
												onBlur={addressF.handleBlur}
												error={
													!!addressF.errors.delegation &&
													addressF.getFieldMeta('delegation').touched
												}
												subtitle={
													addressF.getFieldMeta('delegation').touched
														? (addressF.errors.delegation as string)
														: undefined
												}
												title="Delegación*"
												name="delegation"
												icon={<GPS />}
												placeholder="Selecciona la delegac"
												type="text"
												disabled
												className="m_b_xs w_100_per"
												value={addressF.values.delegation}
												onChange={(e: any) => {
													addressF.handleChange(e);
												}}
											/>
										</Grid>
										<Grid item xs={6}>
											<Input
												onBlur={addressF.handleBlur}
												error={
													!!addressF.errors.neighborhood &&
													addressF.getFieldMeta('neighborhood').touched
												}
												subtitle={
													addressF.getFieldMeta('neighborhood').touched
														? (addressF.errors.neighborhood as string)
														: undefined
												}
												title="Colonia*"
												name="neighborhood"
												icon={<GPS />}
												placeholder="Selecciona la colonia"
												type="select"
												options={neighborhoodOptions}
												className="m_b_xs w_100_per"
												value={addressF.values.neighborhood}
												onChange={(e: any) => {
													addressF.handleChange(e);
												}}
											/>
										</Grid>
										<Grid item xs={6}>
											<Input
												onBlur={addressF.handleBlur}
												error={
													!!addressF.errors.street &&
													addressF.getFieldMeta('street').touched
												}
												subtitle={
													addressF.getFieldMeta('street').touched
														? (addressF.errors.street as string)
														: undefined
												}
												title="Calle*"
												name="street"
												icon={<GPS />}
												placeholder="Escribe nombre de la calle "
												type="text"
												className="m_b_xs w_100_per"
												value={addressF.values.street}
												onChange={(e: any) => {
													addressF.handleChange(e);
												}}
											/>
										</Grid>
										<Grid item xs={6}>
											<Input
												onBlur={addressF.handleBlur}
												error={
													!!addressF.errors.extNumber &&
													addressF.getFieldMeta('extNumber').touched
												}
												subtitle={
													addressF.getFieldMeta('extNumber').touched
														? (addressF.errors.extNumber as string)
														: undefined
												}
												title="N´´umero Exterior* "
												name="extNumber"
												icon={<GPS />}
												placeholder="Escribe el numero exterior"
												type="text"
												className="m_b_xs w_100_per"
												value={addressF.values.extNumber}
												onChange={(e: any) => {
													addressF.handleChange(e);
												}}
											/>
										</Grid>
										<Grid item xs={6}>
											<Input
												onBlur={addressF.handleBlur}
												error={
													!!addressF.errors.intNumber &&
													addressF.getFieldMeta('intNumber').touched
												}
												subtitle={
													addressF.getFieldMeta('intNumber').touched
														? (addressF.errors.intNumber as string)
														: undefined
												}
												title="Número Interior"
												name="intNumber"
												icon={<GPS />}
												placeholder="Escribe el número interior"
												type="text"
												className="m_b_xs w_100_per"
												value={addressF.values.intNumber}
												onChange={(e: any) => {
													addressF.handleChange(e);
												}}
											/>
										</Grid>
									</Grid>
								) : null}
							</>
						)}
						<Typography scale="medium" weight="600" className="m_b_xs">
							Evidencia de recuperación
						</Typography>
						{repossessionFile ? (
							<div className="w_100_per bg_neutral_0 dso_card border_green_300 border_solid p_sm flex_center cursor_pointer flex_justify_between pos_relative document_card_loaded p_r_xxxl">
								<div className="border_solid border_1 border_neutral_700 br_md h_95_per w_40_per overflow_hidden">
									<img
										src={URL.createObjectURL(repossessionFile)}
										alt="Documento de evidencia"
										className="image"
									/>
								</div>
								<div className="flex_center_col flex_justify_between flex_gap_md">
									<Document className="dim_xl text_green_300" />

									<Typography
										scale="small"
										weight="600"
										className="text_center"
										textColor="green_300"
									>
										Evidencia subida
									</Typography>
								</div>
								<input
									type="file"
									onChange={(e: any) => handleFile(e)}
									className="input-file"
								/>
							</div>
						) : (
							<div className="w_100_per bg_neutral_0 dso_card border_primary_200 border_dashed p_sm flex_center_col cursor_pointer flex_justify_between pos_relative document_card">
								<CircleAdd className="dim_xl text_primary_300" />

								<Typography
									scale="small"
									weight="600"
									className="text_center"
									textColor="primary_300"
								>
									Añadir Evidencia
								</Typography>
								<input
									type="file"
									onChange={(e: any) => handleFile(e)}
									className="input-file"
								/>
							</div>
						)}

						<Typography
							scale="large"
							weight="600"
							textColor="neutral_900"
							className="m_y_md"
						>
							Observaciones del ozoner :
						</Typography>
						<textarea
							className="observaciones w_100_per p_md border_1 border_solid border_neutral_400"
							placeholder="Agregue todas las observaciones del ozoner que se detecten"
							value={repossessionComment}
							onChange={(e: any) => setRepossessionComment(e.target.value)}
						/>
					</>
				</Modal>
			)}
			{openRepossessionCancelModal && (
				<Modal
					openConfirmation={openRepossessionCancelModal}
					handleClose={closeRepossessionModal}
					// onConfirmationCallBack={addAgreement}
					// eslint-disable-next-line react/jsx-no-useless-fragment
					icon={<Moto className="text_primary_300" />}
					widthDesktop={40}
					isConfirmationLoading={false}
					actions={[
						{
							label: 'Cancelar',
							color: 'grey',
							action: closeRepossessionModal,
						},
						{
							label: 'Aceptar',
							color: 'primary',
							action: () => cancelRepossission(),
						},
					]}
				>
					<>
						<Typography scale="large" weight="600" className="m_b_xs">
							Deseas cancelar la recuperación del vehículo
						</Typography>
						<Typography
							scale="medium"
							weight="400"
							className="m_b_md"
							textColor="neutral_700"
						>
							El equipo de recuperaciones no podrá ver con este vehículo ni
							recuperarlo
						</Typography>

						<Typography
							scale="large"
							weight="600"
							textColor="neutral_900"
							className="m_y_md"
						>
							Observaciones del ozoner :
						</Typography>
						<textarea
							className="observaciones w_100_per p_md border_1 border_solid border_neutral_400"
							placeholder="Agregue todas las observaciones del ozoner que se detecten"
							value={repossessionComment}
							onChange={(e: any) => setRepossessionComment(e.target.value)}
						/>
					</>
				</Modal>
			)}

			{openRestructure && (
				<Modal
					openConfirmation={openRestructure}
					handleClose={closeRestructure}
					icon={<Calc className="text_primary_300" />}
					isConfirmationLoading={false}
					widthDesktop={40}
					actions={[
						{
							label: 'Cancelar',
							color: 'grey',
							action: closeRestructure,
						},
						{
							label: 'Reestructurar Crédito',
							color: 'primary',
							disabled: restructureLoading || restructureFetching,
							action: applyRestructure,
						},
					]}
				>
					<div className="">
						<Typography scale="large" weight="600" className="m_b_md">
							Re-estructurar crédito
						</Typography>
						<Typography scale="small" weight="400" className="m_b_md">
							Simula la re-estructuración del crédito según la capacidad del
							Ozoner, pon un valor de pago o un numero de cuotas para empezar la
							simulación
						</Typography>
						<div className="display_flex flex_gap_md flex_align_center">
							<Input
								type="number"
								name="newPayment"
								title="Capacidad de Pago"
								placeholder="0 OMX"
								value={newPayment}
								icon={<Chat />}
								onChange={(e: any) => {
									setNewPayment(e.target.value);
									fetchReestructure(e.target.value, true);
								}}
								className="flex_basis_0 flex_grow_1"
							/>
							<Input
								className="flex_basis_0 flex_grow_1"
								type="number"
								icon={<Time />}
								name="newDurarion"
								title="Numero de cuotas"
								placeholder="0 cuotas"
								value={newDuration}
								onChange={(e: any) => {
									setNewDuration(e.target.value);
									fetchReestructure(e.target.value, false);
								}}
							/>
						</div>
						{restructureInfo ? (
							<>
								<div className="bg_neutral_0 p_xs display_flex flex_align_center flex_justify_between shadow_soft m_b_xs">
									<Typography scale="medium" weight="400" className="">
										Deuda Total Actual
									</Typography>
									<Typography
										scale="large"
										weight="600"
										className=""
										textColor="primary_300"
									>
										{priceFormatFinancial(restructureInfo.debt)}
									</Typography>
								</div>
								<div className="display_flex flex_align_center flex_justify_between flex_gap_md">
									<div className="bg_neutral_0 shadow_soft flex_basis_0 flex_grow_1 p_xs">
										<Typography scale="medium" weight="400" className="m_b_xs">
											Deuda Total Nueva
										</Typography>
										<Typography
											scale="large"
											weight="600"
											className=""
											textColor="red_300"
										>
											{priceFormatFinancial(restructureInfo.newLoanAmount)}
										</Typography>
									</div>
									<div className="bg_neutral_0 shadow_soft flex_basis_0 flex_grow_1 p_xs">
										<Typography scale="medium" weight="400" className="m_b_xs">
											Pago Excedente
										</Typography>
										<Typography
											scale="large"
											weight="600"
											className=""
											textColor="green_300"
										>
											{priceFormatFinancial(restructureInfo.excess)}
										</Typography>
									</div>
								</div>
								{restructureLoading ? (
									<Typography
										scale="large"
										weight="600"
										className="p_y_md text_center w_100_per"
									>
										Realizando re-estructuracion, por favor espere...
									</Typography>
								) : null}
							</>
						) : restructureFetching ? (
							<Typography
								scale="large"
								weight="600"
								className="p_y_md text_center w_100_per"
							>
								Cargando simulacion...
							</Typography>
						) : (
							<Typography
								scale="large"
								weight="600"
								className="p_y_md text_center w_100_per"
							>
								No hay datos para simular
							</Typography>
						)}
					</div>
				</Modal>
			)}
		</>
	) : null;
};

export default CollectionOzoner;
