/* eslint-disable no-undef-init */
import React, { useEffect, useRef, useState } from 'react';

import moment from 'moment';
import 'mapbox-gl/dist/mapbox-gl.css';

import { handleEventGoogle } from 'models/shared/helpers/googleAnalytics';
import mapboxgl from 'mapbox-gl';

import {
	FetchRepossessionsGPS,
	FetchRoutes,
} from 'models/repossession/controllers/repossession.controller';
import { LightTooltip } from 'components/hocs/tooltip/Tooltip';

import { DateSelect, Select, Typography } from 'design_system/src';
import { useSelector } from 'react-redux';
import { RootState, store } from 'store';
import { loadAllLocations, loadData } from 'store/actions/repossessions';
import {
	getRepossessionsFilter,
	setRepossessionsFilter,
} from 'localStorage/filterStorage';
import { FetchVehiclesLocations } from 'models/geozon/controllers/gps.controller';

import './recovery.scss';
import {
	InterestPointStatus,
	InterestPointType,
	LocationType,
	RepossessionStatus,
} from 'models/repossession/enums/repossession.enums';
import { RecoveryStatus } from 'models/repossession/enums/recovery.enums';
import {
	InterestPoint,
	Repossession,
} from 'models/repossession/interfaces/repossession.interface';
import { PaginatedDocuments } from 'models/shared/types/pagination';
import { Route } from 'models/repossession/interfaces/route.interface';
import { findAllOzonios } from 'models/ozonio/controllers/ozonios.controller';
import RecoveryRoutes from './routes/Routes';
import {
	baseFilterOptions,
	locationTypesOptions,
	tabs,
	typesOptions,
} from './Repossessions.constants';
import RecoveryMap from './map/RecoveryMap';

export type TDetailsRepossession = {
	repossession: Repossession;
	interestPoint: InterestPoint;
};

export type TDetailsLocation = {
	type: string;
	coordinates: [number, number];
	updatedAt: string;
	isTrackGPS: boolean;
	statusCredit: string;
	internalId: string;
	credit: {
		_id: string;
		status: string;
		code: string;
		internalId: string;
	};
};

export type TDetailsMap = TDetailsRepossession | TDetailsLocation;

export type featureProperties = {
	repossession: Repossession;
	interestPoint: InterestPoint;
	id: string;
	primary: boolean;
};

export const Repossessions = () => {
	const [query, setQuery] = useState<string>('');
	const [viewTab, setViewTab] = useState(0);
	const [filterOptions, setFilterOptions] = useState(baseFilterOptions);
	const [filters, setFilters] = useState<string[]>([]);
	const [locationsFilter, setLocationsFilter] = useState<LocationType[]>([]);
	const [types, setTypes] = useState<string[]>([
		InterestPointType.signalVehicle,
		InterestPointType.noSignalVehicle,
		InterestPointType.address,
		InterestPointType.hotspot,
	]);

	const [idRuta, setIdRuta] = useState();
	const [ozonios, setOzonios] = useState<any[]>([]);
	const [routeOzonio, setRouteOzonio] = useState<string>('');
	const [routeDate, setRouteDate] = useState<string>(
		moment().format('YYYY-MM-DD'),
	);
	const [openRuta, setOpenRuta] = useState(false);

	const [selecteds, setSelecteds] =
		useState<{ repossession: Repossession; interestPoint: InterestPoint }[]>();
	const [details, setDetails] = useState<TDetailsMap>();

	const { lastUpdate, data, recoveryTotals } = useSelector(
		(state: RootState) => state.repossessionReducer,
	);
	const { allLocations } = useSelector(
		(state: RootState) => state.locationsReducer,
	);

	const map = useRef<any>(null);

	useEffect(() => {
		if (filters.length >= filterOptions.length) {
			setFilters([]);
		}
	}, [filters]);
	useEffect(() => {
		if (locationsFilter.length >= locationTypesOptions.length) {
			setLocationsFilter([]);
		}
	}, [locationsFilter]);
	useEffect(() => {
		if (recoveryTotals) {
			setFilterOptions(
				baseFilterOptions.map((filter) => ({
					value: filter.value,
					label: `${filter.label} (${
						(recoveryTotals as any)?.[filter.value] ?? 0
					})`,
				})),
			);
		}
	}, [recoveryTotals]);

	const toogleTypes = (value: string) => {
		const set = new Set(types);

		if (set.has(value)) {
			set.delete(value);
		} else {
			set.add(value);
		}

		setTypes(Array.from(set));
	};

	const fetch = (force?: boolean) => {
		if (force || Date.now() - lastUpdate > 3600000) {
			FetchRepossessionsGPS('', 1, -1, '', '', `${RepossessionStatus.pending}`)
				.then((res) => {
					store.dispatch(
						loadData(res.data, {
							...res.totals,
							count: Object.values(res.totals).reduce(
								(acc: any, ele: any) => acc + ele,
								0,
							),
						}) as any,
					);
				})
				.catch((e) => {
					console.log('Error fetch recovery', e);
				});
			FetchVehiclesLocations().then((res: any) => {
				store.dispatch(loadAllLocations(res) as any);
			});
		}
	};

	const updateLocationsSource = () => {
		if (allLocations?.length) {
			const features = allLocations
				.filter((position: any) => position.coordinates)
				.filter((position: any) => {
					if (!position.internalId?.toUpperCase().includes(query.toUpperCase()))
						return false;
					return true;
				})
				.filter((position: any) => {
					if (details) {
						if ((details as any).internalId === position.internalId)
							return true;
						return false;
					}
					if (locationsFilter.length) {
						const daysGap = Math.round(
							(Date.now() - new Date(position.updatedAt).getTime()) /
								(1000 * 60 * 60 * 24),
						);
						if (
							locationsFilter.includes(LocationType.gpsTraker) &&
							position.isTrackGPS
						) {
							return true;
						}
						if (
							locationsFilter.includes(LocationType.signalVehicle) &&
							position.type === 'Point' &&
							!position.isTrackGPS
						) {
							return true;
						}
						return false;
					}
					return true;
				})
				.map((position: any) => {
					const daysGap = Math.round(
						(Date.now() - new Date(position.updatedAt).getTime()) /
							(1000 * 60 * 60 * 24),
					);
					let type: any =
						position.type === 'Point' && !position.isTrackGPS
							? InterestPointType.signalVehicle
							: InterestPointType.noSignalVehicle;
					if (position.type === 'AnahuacPoint') type = 'AnahuacPoint';
					return {
						type: 'Feature',
						properties: {
							title: position.internalId,
							description: '',
							position: JSON.stringify(position),
							isTrackGPS: !!position.isTrackGPS,
							type,
							id: `${position.type}_${position.internalId}`,
						},
						geometry: {
							coordinates: [...position.coordinates],
							type: 'Point',
						},
					};
				});
			map.current.getSource('allvehicles')?.setData({
				type: 'FeatureCollection',
				features,
			});
		}
	};

	const updateRepossessionsSource = () => {
		if (data && Object.keys(data).length) {
			const allFeatures = { ...data };

			const aditionalPoints = [...(selecteds ?? [])];
			let detailsId: string | undefined = undefined;
			let detailsRepossessionId: string;
			if (details && (details as TDetailsRepossession).interestPoint) {
				const { repossession, interestPoint } = details as TDetailsRepossession;
				let detailsInterestPointId = interestPoint._id;
				if (!detailsInterestPointId || !detailsInterestPointId.length) {
					detailsInterestPointId = `tmp${interestPoint.type}`;
				}

				detailsRepossessionId = repossession._id;
				detailsId = `${detailsInterestPointId}${detailsRepossessionId}`;
				aditionalPoints.push({
					repossession,
					interestPoint: { ...interestPoint, _id: detailsInterestPointId },
				});
			}
			aditionalPoints.forEach((point) => {
				const id = `${
					point.interestPoint._id ?? `tmp${point.interestPoint.type}`
				}${point.repossession._id}`;
				if (!(id in allFeatures)) {
					allFeatures[id] = {
						...point,
						id,
						primary: false,
					};
				}
			});

			const selectedsIds = selecteds?.map(
				(item) =>
					`${item.interestPoint._id ?? `tmp${item.interestPoint.type}`}${
						item.repossession._id
					}`,
			);

			const features = Object.values(allFeatures)
				.filter(({ repossession, interestPoint, id, primary }) => {
					if (
						!repossession.vehicleId?.toUpperCase().includes(query.toUpperCase())
					)
						return false;
					if (openRuta) {
						if (id !== undefined && selectedsIds?.includes(id)) return true;
						return false;
					}
					if (id === detailsId) return true;
					if (detailsId) {
						if (repossession._id === detailsRepossessionId) {
							if (
								interestPoint.status === InterestPointStatus.expired &&
								id !== undefined &&
								!selectedsIds?.includes(id)
							)
								return false;

							return true;
						}
						return false;
					}
					if (interestPoint.status === InterestPointStatus.expired)
						return false;
					if (!primary || interestPoint.type === InterestPointType.hotspot)
						return false;
					if (filters.length && !filters.includes(repossession.policy))
						return false;
					if (!types.includes(interestPoint.type)) return false;
					return true;
				})
				.map(({ repossession, interestPoint, id }) => {
					return {
						type: 'Feature',
						properties: {
							title: repossession.vehicleId,
							description: '',
							policy: repossession.policy,
							repossession: JSON.stringify(repossession),
							interestPoint: JSON.stringify(interestPoint),
							type: interestPoint.type,
							id,
							isTrackGPS: interestPoint.isTrackGPS,
						},
						geometry: {
							coordinates: [...interestPoint.location.coordinates],
							type: 'Point',
						},
					};
				});

			map.current.getSource('vehicles')?.setData({
				type: 'FeatureCollection',
				features,
			});
			if (detailsId) {
				const bounds = new mapboxgl.LngLatBounds();
				features.forEach((point) => {
					bounds.extend(point.geometry.coordinates as any);
				});

				bounds.getEast();
				const size = (bounds.getEast() - bounds.getWest()) * 2.2;
				bounds.extend({
					lng: bounds.getEast() - size,
					lat: bounds.getNorth(),
				});

				map.current.fitBounds(bounds, {
					padding: 40,
				});
			}
		}
	};
	const updateSource = () => {
		updateLocationsSource();
		updateRepossessionsSource();
	};

	useEffect(() => {
		updateSource();
	}, [
		filters,
		data,
		allLocations,
		types,
		selecteds,
		query,
		openRuta,
		locationsFilter,
	]);
	useEffect(() => {
		handleEventGoogle(
			'VIEW_Panda_repossessions',
			'Panda',
			'page view to the repossessions map view',
		);

		const information = getRepossessionsFilter();
		if (information.viewTab) {
			setViewTab(information.viewTab);
		}
		findAllOzonios('').then((ozons) => {
			setOzonios(
				ozons.data.map((ozonio: any) => ({
					value: ozonio._id,
					label: ozonio.name,
				})),
			);
		});
	}, []);

	const getFilters = () => {
		switch (viewTab) {
			case 0:
				return (
					<div className="display_flex flex_align_center flex_gap_xl">
						<Select
							fixedPlaceholder
							variant="checkbox"
							placeholder="Estado"
							options={locationTypesOptions}
							value={locationsFilter}
							className="policy_select"
							onChange={(e: any) => {
								setLocationsFilter(e.target.value);
							}}
						/>
					</div>
				);
			case 1:
				return (
					<div className="display_flex flex_align_center flex_gap_xl">
						<div className="display_flex flex_align_center flex_gap_sm">
							{typesOptions.map((option, index) => (
								<LightTooltip
									title={option.title}
									key={`${option.title}_index`}
								>
									<div
										role="button"
										tabIndex={0}
										onKeyDown={() => {
											toogleTypes(option.value);
										}}
										onClick={() => {
											toogleTypes(option.value);
										}}
										className={`cursor_pointer p_xs br_xs display_flex flex_align_center flex_gap_md ${
											types.includes(option.value)
												? 'bg_primary_50 text_primary_300'
												: ''
										}`}
									>
										{option.icon}
									</div>
								</LightTooltip>
							))}
						</div>
						<Select
							fixedPlaceholder
							variant="checkbox"
							placeholder="Politicas"
							options={filterOptions}
							value={filters}
							className="policy_select"
							onChange={(e: any) => {
								setFilters(e.target.value);
							}}
						/>
					</div>
				);
			default:
				return null;
		}
	};

	return (
		<div className="dim_100_per flex_content_col inner_container bg_neutral_300">
			{viewTab <= 1 && (
				<div className="w_100_per bg_neutral_0 flex_header p_x_lg p_y_md">
					<div className="display_flex flex_align_center flex_justify_between m_b_md">
						<Typography
							scale="heading3"
							weight="600"
							className="text_primary_300"
						>
							Recuperaciones
						</Typography>
						<div className="display_flex flex_align_center flex_gap_sm p_x_xs p_y_xxs bg_neutral_300 br_xs">
							{tabs.map((tab, i) => (
								<Typography
									scale="medium"
									key={tab}
									weight={i === viewTab ? '600' : '400'}
									textColor={i === viewTab ? 'neutral_1000' : 'neutral_700'}
									className={`p_x_md p_y_xxs br_xs cursor_pointer ${
										i === viewTab ? 'bg_neutral_0 shadow_soft' : ''
									}`}
									onClick={() => setViewTab(i)}
								>
									{tab}
								</Typography>
							))}
						</div>
					</div>
					{getFilters()}
				</div>
			)}
			<div
				className={`flex_grow_1 overflow_hidden pos_relative ${
					viewTab <= 1 ? '' : 'display_none'
				}`}
			>
				<RecoveryMap
					query={query}
					setQuery={setQuery}
					loadingData={!data}
					allLocations={allLocations}
					ozonios={ozonios}
					selecteds={selecteds}
					setSelecteds={setSelecteds}
					map={map}
					routeDate={routeDate}
					setRouteDate={setRouteDate}
					routeOzonio={routeOzonio}
					setRouteOzonio={setRouteOzonio}
					openRuta={openRuta}
					setOpenRuta={setOpenRuta}
					idRuta={idRuta}
					viewTab={viewTab}
					setIdRuta={setIdRuta}
					setViewTab={setViewTab}
					updateSource={updateSource}
					isFullMap={!viewTab}
					details={details}
					setDetails={setDetails}
					fetch={fetch}
				/>
			</div>
			{viewTab === 2 ? (
				<RecoveryRoutes
					viewTab={viewTab}
					ozonios={ozonios}
					setViewTab={setViewTab}
					setSelecteds={setSelecteds}
					setRouteDate={setRouteDate}
					setRouteOzonio={setRouteOzonio}
					setOpenRuta={setOpenRuta}
					setIdRuta={setIdRuta}
				/>
			) : null}
		</div>
	);
};
