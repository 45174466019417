/* eslint-disable no-nested-ternary */
import React, { useState, useContext, useEffect } from 'react';

// -----------------LIBRARIES IMPORTS------------------- //
import {
	Button,
	Input,
	ModalConfirmation,
	Typography,
} from 'design_system/src';

// -----------------DATA IMPORTS------------------- //

import { handleEventGoogle } from 'models/shared/helpers/googleAnalytics';

// -----------------STATIC IMPORTS------------------- //
import '../../../ozoner.scss';
import { ReactComponent as Reload } from 'design_system/src/static/icons/reload.svg';
import { ReactComponent as Money } from 'design_system/src/static/icons/money.svg';

// -----------------COMPONENTS IMPORTS------------------- //
import { useNavigate } from 'react-router-dom';
import { VehiclePopulated } from 'models/inventory/interfaces/vehicle.interface';
import { FormStatus } from 'models/financial-form/enums/financial-form.enums';
import { priceFormatFinancial } from 'models/shared/helpers/prices';
import { updateFinancialForm } from 'models/financial-form/controllers/financial-form.controller';
import { UpdateFinancialFormDto } from 'models/financial-form/interfaces/financial-form.interfaces';
import { toasterNotification } from 'models/shared/helpers/toasterNotification';
import { toasterOptions } from 'models/shared/enums/toaster.enum';
import { ozonerContext } from '../../OzonerContext';

export function VehicleSection() {
	const {
		financialForm,
		vehicle,
		setOpenVehicle,
		handleDetailsVehicle,
		updateFormData,
		loanTotals,
	} = useContext(ozonerContext);

	const [creditOptions, setCreditOptions] =
		useState<{ value: number; label: string }[]>();
	const [selectedCreditTime, setSelectedCreditTime] = useState(0);
	const [openCreditChange, setOpenCreditChange] = useState<boolean>(false);

	useEffect(() => {
		if (vehicle) {
			setCreditOptions(
				Object.keys(vehicle.salePrices).map((key) => {
					const weeks = parseInt(key, 10);
					const label =
						weeks === 1
							? 'Venta de contado'
							: `${weeks} semanas (${Math.round(weeks * 0.23)} meses)`;
					return {
						value: weeks,
						label,
					};
				}),
			);
		} else {
			setCreditOptions([]);
		}
	}, [financialForm.form, vehicle]);

	const updateCredit = (e: any) => {
		setSelectedCreditTime(e.target.value);
		setOpenCreditChange(true);
	};
	const updateCreditTime = () => {
		if (financialForm?.form && selectedCreditTime) {
			const upload: UpdateFinancialFormDto = {
				loanApplication: { loanDuration: selectedCreditTime },
			};
			if (selectedCreditTime === 1 && upload.loanApplication) {
				upload.loanApplication.deposit = 0;
				upload.loanApplication.advancedMoney = 0;
			}
			updateFinancialForm(financialForm.form._id, upload)
				.then((newForm) => {
					toasterNotification({
						msg: 'Cambio de semanas exitoso',
						style: 'light',
						toasterType: toasterOptions.success,
					});
					updateFormData(newForm);
					setOpenCreditChange(false);
				})
				.catch((e) => {
					toasterNotification({
						msg: e ?? 'Ocurrio un error',
						toasterType: toasterOptions.error,
						style: 'light',
					});
				});
			handleEventGoogle(
				'BTN_Pb_request_revive',
				'Panda Solicitudes',
				'click in revive request button',
			);
		}
	};

	const changeCreditLabel = () => {
		if (vehicle && financialForm.form) {
			const old = financialForm.form.loanApplication?.loanDuration
				? vehicle.salePrices[financialForm.form.loanApplication.loanDuration]
				: undefined;
			const newC = vehicle.salePrices[selectedCreditTime];
			return old ? (
				<Typography scale="medium" weight="400" textColor="neutral_700">
					Pasará de pagar
					<Typography scale="medium" weight="600" component="span">
						{` $${old.paymentWeek} por ${old.weeks} semanas `}
					</Typography>
					a pagar
					<Typography scale="medium" weight="600" component="span">
						{` $${newC.paymentWeek} por ${newC.weeks} semanas `}
					</Typography>
				</Typography>
			) : (
				<Typography scale="medium" weight="400" textColor="neutral_700">
					Empezara a pagar
					<Typography scale="medium" weight="600" component="span">
						{` $${newC.paymentWeek} por ${newC.weeks} semanas `}
					</Typography>
				</Typography>
			);
		}
		return <div />;
	};

	return (
		<>
			<div
				id="vehicle-soli-ozoner"
				className=" border_b_solid border_1 border_neutral_400 p_x_sm p_b_md p_t_xl w_100_per"
			>
				{vehicle ? (
					<>
						<div className="display_flex flex_gap_md flex_align_center">
							<div className="br_xs overflow_hidden foto_moto">
								<img
									src={
										vehicle.images[0]
											? vehicle.images[0].url
											: `${process.env.REACT_APP_STORAGE_BASE_URL}public/uploads/generic-moto.png`
									}
									alt="moto detail"
								/>
							</div>
							<div className="flex_grow_1 flex_center_col">
								<Typography
									scale="small"
									weight="600"
									className="inventory_link cursor_pointer"
									onClick={(e) => handleDetailsVehicle(e, vehicle.internalId)}
								>
									{vehicle.internalId}
								</Typography>

								<Typography scale="xxsmall" weight="400" className="m_b_xxs">
									{vehicle.brand && vehicle.model
										? `${vehicle.brand.name} ${vehicle.model.name} ${
												vehicle.suffix ?? ''
										  }`
										: 'no definido'}
								</Typography>
								{!financialForm?.loan &&
									financialForm?.form.status !== FormStatus.expired &&
									financialForm?.form.status !== FormStatus.canceled && (
										<div className="display_flex flex_gap_sm">
											<div className="flex_grow_1 flex_center_col">
												<Button
													scale="small"
													icon={<Reload />}
													variant="principal"
													onClick={() => {
														setOpenVehicle(true);
													}}
												>
													Cambiar Moto
												</Button>
											</div>
										</div>
									)}
							</div>
						</div>
						<div className="m_t_md">
							{creditOptions && (
								<Input
									title="Plan escogido"
									placeholder="Selecciona un plan"
									icon={<Money />}
									onChange={updateCredit}
									value={
										financialForm?.form?.loanApplication?.loanDuration ??
										undefined
									}
									type="select"
									name="creditTime"
									options={creditOptions}
								/>
							)}
						</div>
					</>
				) : !financialForm?.loan &&
				  financialForm?.form.status !== FormStatus.expired &&
				  financialForm?.form.status !== FormStatus.canceled ? (
					<Button
						scale="small"
						icon={<Reload />}
						variant="principal"
						onClick={() => {
							setOpenVehicle(true);
							handleEventGoogle(
								'BTN_Pb_request_assign_vehicle',
								'Panda Solicitudes',
								'click in assign vehicle to request button',
							);
						}}
						className="w_100_per p_b_md"
					>
						Asignar Moto
					</Button>
				) : null}
			</div>
			{vehicle && (
				<div
					id="vehicle-advance-ozoner"
					className=" border_b_solid border_1 border_neutral_400 p_x_sm p_y_sm w_100_per display_flex flex_gap_sm flex_align_center"
				>
					<div className="bg_green_200 p_xs br_xxs display_flex ">
						<Money className="dim_md text_green_300" />
					</div>
					<Typography
						scale="small"
						weight="600"
						textColor="neutral_800"
						className="text_no_break"
					>
						Cuota semanal:
					</Typography>
					<Typography
						scale="medium"
						weight="600"
						textColor="neutral_800"
						className="text_no_break"
					>
						{loanTotals?.paymentWeek
							? priceFormatFinancial(loanTotals.paymentWeek)
							: '--'}
					</Typography>
				</div>
			)}
			{openCreditChange && (
				<ModalConfirmation
					openConfirmation={openCreditChange}
					handleClose={() => setOpenCreditChange(false)}
					onConfirmationCallBack={updateCreditTime}
					title={
						<Typography scale="large" weight="400">
							¿Seguro que quieres{' '}
							<Typography scale="large" weight="600" component="span">
								cambiar el plan de pago del ozoner?
							</Typography>
						</Typography>
					}
					subtitle={changeCreditLabel()}
					copyConfirm="Aceptar cambio"
					copyReject="Cancelar"
					icon={<Reload />}
				/>
			)}
		</>
	);
}
