/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';

import {
	Button,
	Typography,
	Knob,
	ProgressBar,
	ModalConfirmation,
	Input,
	Select,
} from 'design_system/src';
import { Grid } from '@mui/material';
import 'moment/locale/es';

import '../../ozoner.scss';
import { ReactComponent as Edit } from 'design_system/src/static/icons/edit-text.svg';
import { ReactComponent as User } from 'design_system/src/static/icons/verified-user.svg';
import { ReactComponent as Money } from 'design_system/src/static/icons/money.svg';
import { ReactComponent as Calendar } from 'design_system/src/static/icons/date.svg';
import { ReactComponent as Tag } from 'design_system/src/static/icons/price-tag-rotate.svg';
import { ReactComponent as Discount } from 'design_system/src/static/icons/discount.svg';
import { ReactComponent as Wallet } from 'design_system/src/static/icons/wallet.svg';

import { LightTooltip } from 'components/hocs/tooltip/Tooltip';
import { toasterOptions } from 'models/shared/enums/toaster.enum';
import {
	settleDebt,
	updateLoan,
} from 'models/loan/controllers/loan.controller';
import {
	LoanStatus,
	LoanStatusFlags,
	LoanStatusFlagsValues,
	LoanStatusValues,
} from 'models/loan/enums/loan.enum';
import { toasterNotification } from 'models/shared/helpers/toasterNotification';
import { commonErrorHandler } from 'models/shared/helpers/errors';
import {
	priceFormatFinancial,
	priceMXNTotal,
} from 'models/shared/helpers/prices';
import LoanPaymentsTable from 'components/hocs/loanPaymentsTable/LoanPaymentsTable';
import { momentLocal } from 'models/shared/helpers/dates';
import { ozonerContext } from '../OzonerContext';
import CollectionOzoner from './CollectionOzoner';
import HistoryStatus from '../statusHistory/HistoryStatus';

const classLevel: any = {
	AAA: { value: 100, color: 'green_300' },
	AA: { value: 80, color: 'green_300' },
	A: { value: 60, color: 'green_300' },
	B: { value: 40, color: 'yellow_100' },
	C: { value: 20, color: 'red_300' },
};

function LoanOzoner() {
	// -----------------DATA STATES------------------- //
	const [endedDate, setEndedDate] = useState<any>('');
	const [loading, setLoading] = useState<boolean>(false);

	// -----------------OPEN STATES------------------- //
	const [openEnded, setOpenEnded] = useState(false);
	const [openTags, setOpenTags] = useState(false);
	const [editTags, setEditTags] = useState<string[]>([]);

	const [isConfirmationLoading, setIsConfirmationLoading] = useState(false);

	const [openSettle, setOpenSettle] = useState(false);
	const [writeOffAmount, setWriteOffAmount] = useState(0);
	const [writeOffDiscount, setWriteOffDiscount] = useState(0);
	const [loadingSettle, setLoadingSettle] = useState(false);

	const [openStatusHistory, setOpenStatusHistory] = useState<boolean>(false);

	const {
		ozoner,
		vehicle,
		financialForm,
		updateLoanData,
		handleDetailsVehicle,
		ozonios,
	} = useContext(ozonerContext);

	const closeSettle = () => {
		setOpenSettle(false);
		setWriteOffDiscount(0);
	};

	useEffect(() => {
		setWriteOffAmount(financialForm?.loan?.totalDebt ?? 0);
	}, [financialForm]);

	const changeEndedDate = () => {
		if (financialForm?.loan) {
			updateLoan(financialForm?.loan._id, { endedAt: endedDate })
				.then((newLoan) => {
					updateLoanData(newLoan);
					toasterNotification({
						toasterType: toasterOptions.success,
						msg: 'Fecha actualizada',
						style: 'light',
					});
				})
				.catch((e) => {
					setLoading(false);
					commonErrorHandler(e);
				});
		}
	};
	const updateTags = () => {
		if (financialForm?.loan) {
			setIsConfirmationLoading(true);
			const up: any = {};
			Object.keys(LoanStatusFlagsValues).forEach((key) => {
				up[key] = editTags.includes(key);
			});
			updateLoan(financialForm?.loan._id, up)
				.then((newLoan) => {
					setIsConfirmationLoading(false);
					updateLoanData(newLoan);
					setOpenTags(false);
					setEditTags([]);
					toasterNotification({
						toasterType: toasterOptions.success,
						msg: 'Etiquetas actualizadas',
						style: 'light',
					});
				})
				.catch((e) => {
					setLoading(false);
					commonErrorHandler(e);
				});
		}
	};

	const settleLoan = () => {
		const data = {
			writeOffAmount,
			writeOffDiscount: writeOffDiscount / 100,
		};
		if (financialForm?.loan?._id) {
			setLoadingSettle(true);
			settleDebt(financialForm.loan._id, data)
				.then((newLoan) => {
					setLoadingSettle(false);
					updateLoanData(newLoan);
					toasterNotification({
						toasterType: toasterOptions.success,
						msg: 'Fecha actualizada',
						style: 'light',
					});
				})
				.catch((e) => {
					setLoadingSettle(false);
					commonErrorHandler(e);
				});
		}
	};

	return (
		<>
			{ozoner && financialForm?.loan && (
				<Grid
					container
					direction="row"
					spacing={3}
					className="h_100_per p_t_md"
				>
					<Grid item xs={3}>
						<div className="bg_neutral_0 border_neutral_400 border_solid border_1 br_sm">
							<div
								id="base-loan"
								className="p_md border_b_solid border_1 border_neutral_400 "
							>
								<div className="m_b_md display_flex flex_gap_md flex_align_center">
									<Button
										variant="icon"
										scale="small"
										icon={<Tag />}
										subvariant="edit"
										onClick={() => {
											setOpenTags(true);
											setEditTags(
												Object.keys(LoanStatusFlagsValues).filter(
													(key) =>
														financialForm?.loan?.[key as LoanStatusFlags],
												),
											);
										}}
									/>

									<div className="display_flex flex_align_center flex_wrap flex_gap_xs">
										{Object.entries(LoanStatusFlagsValues)
											.filter(
												([key]) =>
													financialForm?.loan?.[key as LoanStatusFlags],
											)
											.map(([, value]) => (
												<LightTooltip title={value.tooltip}>
													<div>
														<Typography
															scale="xxsmall"
															weight="600"
															className="br_xxs p_x_xs p_y_xxxs text_center text_red_300 pos_relative bg_red_200_nocontrast text_no_break"
														>
															{value.title}
														</Typography>
													</div>
												</LightTooltip>
											))}
										{Object.keys(LoanStatusFlagsValues).filter(
											(key) => financialForm?.loan?.[key as LoanStatusFlags],
										).length === 0 ? (
											<Typography scale="medium" weight="400" className="">
												Crédito sin etiquetas
											</Typography>
										) : null}
									</div>
								</div>
								<Typography
									scale="medium"
									weight="400"
									className="m_b_xs"
									textColor="neutral_800"
								>
									CURP
								</Typography>
								<Typography
									scale="medium"
									weight="600"
									className="m_b_md"
									textColor="neutral_800"
								>
									{ozoner.curp || ''}
								</Typography>
								<Button
									variant="outline"
									scale="small"
									className="w_100_per"
									onClick={() => setOpenStatusHistory(true)}
								>
									Historial de estados
								</Button>
							</div>
							<CollectionOzoner />
							{financialForm.loan.endedAt ? (
								<div
									id="finalizacion-ozoner"
									className="p_md border_b_solid border_1 border_neutral_400 pos_relative"
								>
									<Typography
										scale="medium"
										weight="400"
										className="m_b_xs"
										textColor="neutral_800"
									>
										Fecha de finalizacion del contrato
									</Typography>
									<Typography
										scale="medium"
										weight="600"
										className="m_b_md"
										textColor="neutral_800"
									>
										{momentLocal(financialForm.loan.endedAt).format(
											'DD/MM/yyyy',
										)}
									</Typography>
									<Button
										onClick={() => setOpenEnded(true)}
										variant="icon"
										icon={<Edit />}
										subvariant="edit"
										scale="small"
										className="edit-button-deposit"
									/>
								</div>
							) : null}
							<div
								id="validate-soli-ozoner"
								className="border_b_solid border_1 border_neutral_400 display_flex flex_col"
							>
								<div className="display_flex">
									<div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
										<Typography
											scale="xxsmall"
											weight="400"
											textColor="neutral_600"
											className="text_no_break text_center m_b_xxs"
										>
											Deposito
										</Typography>
										<Typography
											scale="small"
											weight="600"
											textColor="green_300"
											className="text_no_break text_center"
										>
											{priceFormatFinancial(
												financialForm?.loan?.deposit ?? 0,
												0,
											)}
										</Typography>
									</div>
									<div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
										<Typography
											scale="xxsmall"
											weight="400"
											textColor="neutral_600"
											className="text_no_break text_center m_b_xxs"
										>
											Pago por adelantado
										</Typography>
										<Typography
											scale="small"
											weight="600"
											textColor="secondary_500"
											className="text_no_break text_center"
										>
											{priceFormatFinancial(
												financialForm?.form?.loanApplication?.advancedMoney ??
													0,
												0,
											)}
										</Typography>
									</div>
								</div>
								<div className="display_flex">
									<div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
										<Typography
											scale="xxsmall"
											weight="400"
											textColor="neutral_600"
											className="text_no_break text_center m_b_xxs"
										>
											Tiempo
										</Typography>
										<Typography
											scale="small"
											weight="600"
											textColor="neutral_1000"
											className="text_no_break text_center"
										>
											{financialForm?.loan?.duration} Semanas
										</Typography>
									</div>
									<div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
										<Typography
											scale="xxsmall"
											weight="400"
											textColor="neutral_600"
											className="text_no_break text_center m_b_xxs"
										>
											Cuota
										</Typography>
										<Typography
											scale="small"
											weight="600"
											textColor="primary_300"
											className="text_no_break text_center"
										>
											{priceFormatFinancial(
												financialForm?.loan?.weeklyPayment ?? 0,
												0,
											)}
										</Typography>
									</div>
								</div>
								<div className="display_flex">
									<div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
										<Typography
											scale="xxsmall"
											weight="400"
											textColor="neutral_600"
											className="text_no_break text_center m_b_xxs"
										>
											Total credito sin deposito
										</Typography>
										<Typography
											scale="small"
											weight="600"
											textColor="green_300"
											className="text_no_break text_center"
										>
											{priceFormatFinancial(
												(financialForm?.loan?.duration ?? 0) *
													(financialForm?.loan?.weeklyPayment ?? 0),
												0,
											)}
										</Typography>
									</div>
								</div>
								<div className="display_flex">
									<div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
										<Typography
											scale="xxsmall"
											weight="400"
											textColor="neutral_600"
											className="text_no_break text_center m_b_xxs"
										>
											Responsable cobranza
										</Typography>
										<Typography
											scale="small"
											weight="600"
											textColor="green_300"
											className="text_no_break text_center"
										>
											{financialForm?.loan?.collectionResponsible
												? ozonios[
														financialForm.loan.collectionResponsible as any
												  ].name
												: '--'}
										</Typography>
									</div>
								</div>
								<div className="display_flex">
									<div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
										<Typography
											scale="xxsmall"
											weight="400"
											textColor="neutral_600"
											className="text_no_break text_center m_b_xxs"
										>
											Segmentacion cobranza
										</Typography>
										<Typography
											scale="small"
											weight="600"
											textColor="green_300"
											className="text_no_break text_center"
										>
											{financialForm?.form.loanApplication
												?.collectionSegmentation ?? '--'}
										</Typography>
									</div>
								</div>
								<div className="display_flex ">
									<div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
										<Typography
											scale="xxsmall"
											weight="400"
											textColor="neutral_600"
											className="text_no_break text_center m_b_xxs"
										>
											Alianza
										</Typography>
										<Typography
											scale="small"
											weight="600"
											textColor="neutral_900"
											className="text_no_break text_center"
										>
											{financialForm?.form?.loanApplication?.partnership ??
												'--'}
										</Typography>
									</div>
									<div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
										<Typography
											scale="xxsmall"
											weight="400"
											textColor="neutral_600"
											className="text_no_break text_center m_b_xxs"
										>
											Segmentacion Crédito
										</Typography>
										<Typography
											scale="small"
											weight="600"
											textColor="neutral_900"
											className="text_no_break text_center"
										>
											{financialForm?.form?.loanApplication?.loanSegmentation}
										</Typography>
									</div>
								</div>
								{vehicle && (
									<div className="display_flex flex_gap_md flex_align_center border_solid border_1 border_neutral_400 p_xs">
										<div className="br_xs overflow_hidden foto_moto">
											<img
												src={
													vehicle.images[0]
														? vehicle.images[0].url
														: `${process.env.REACT_APP_STORAGE_BASE_URL}public/uploads/generic-moto.png`
												}
												alt="moto detail"
											/>
										</div>
										<div className="flex_grow_1 flex_center_col">
											<Typography
												scale="small"
												weight="600"
												className="inventory_link cursor_pointer"
												onClick={(e) =>
													handleDetailsVehicle(e, vehicle.internalId)
												}
											>
												{vehicle.internalId}
											</Typography>

											<Typography
												scale="xxsmall"
												weight="400"
												className="m_b_xxs"
											>
												{vehicle.brand && vehicle.model
													? `${vehicle.brand.name} ${vehicle.model.name} ${
															vehicle.suffix ?? ''
													  }`
													: 'no definido'}
											</Typography>
										</div>
									</div>
								)}
							</div>
						</div>
					</Grid>

					<Grid item xs={9}>
						<Grid container direction="row" spacing={3} className="m_b_md">
							<Grid item xs={4}>
								<div className="br_xxs bg_neutral_0 flex_align_center p_x_md p_y_xs dim_100_per display_flex flex_gap_md border_1 border_neutral_400 border_solid ">
									<Calendar className="dim_xxl text_primary_100 border_transparent" />
									<div className="flex_grow_3">
										<Typography scale="medium" weight="600">
											{momentLocal(financialForm?.loan?.createdAt).format(
												'DD/MM/yyyy',
											)}
										</Typography>
										<Typography scale="xsmall" weight="400">
											Fecha de activación
										</Typography>
									</div>
								</div>
							</Grid>
							<Grid item xs={4}>
								<div className="br_xxs bg_neutral_0 flex_align_center p_x_md p_y_xs dim_100_per display_flex flex_gap_md border_1 border_neutral_400 border_solid ">
									<div
										className={`br_circle dim_xxl bg_${
											LoanStatusValues[financialForm.loan.status].color
										}`}
									/>
									<div className="flex_grow_3">
										<div className="display_flex flex_align_center flex_gap_xs">
											<Typography scale="medium" weight="600">
												{LoanStatusValues[financialForm.loan.status].title}{' '}
											</Typography>
											<Typography
												scale="small"
												weight="600"
												textColor="red_300"
											>
												{[LoanStatus.current, LoanStatus.writeOff].includes(
													financialForm.loan.status,
												) && financialForm.loan.dpd
													? `(${financialForm.loan.dpd} dias de mora)`
													: ''}
											</Typography>
										</div>
										<Typography scale="xsmall" weight="400">
											Estado de credito{' '}
											{financialForm?.loan?.statusHistoric?.length
												? `desde:
										${momentLocal(
											financialForm?.loan?.statusHistoric[
												financialForm.loan.statusHistoric.length - 1
											].updatedAt,
										).format('DD/MM/yyyy')}`
												: ''}
										</Typography>
									</div>
								</div>
							</Grid>
							<Grid item xs={4}>
								<div className="br_xxs bg_neutral_0 flex_align_center p_x_md p_y_xs dim_100_per display_flex flex_gap_md border_1 border_neutral_400 border_solid ">
									<Knob
										value={
											classLevel[financialForm?.loan?.loanClassification]
												?.value ?? 0
										}
										variant="gauge"
										symbol="%"
										color={
											classLevel[financialForm?.loan?.loanClassification]
												?.color ?? 'red_300'
										}
										className="dim_xxxl border_transparent"
									/>
									<div className="flex_grow_3">
										<Typography scale="medium" weight="600" className="">
											Crédito {financialForm?.loan?.code}{' '}
										</Typography>
										<Typography
											scale="xsmall"
											weight="400"
											className=""
											textColor={
												classLevel[financialForm?.loan?.loanClassification]
													?.color ?? 'neutral_600'
											}
										>
											Clasificación del Ozoner:{' '}
											{financialForm?.loan?.loanClassification ?? 'N/D'}
										</Typography>
									</div>
								</div>
							</Grid>
						</Grid>
						<div className="display_flex flex_gap_md flex_align_stretch m_b_md">
							<div className="br_xxs border_neutral_400 border_solid border_1 flex_basis_0 flex_grow_1 bg_neutral_0 p_y_md p_x_lg display_flex flex_gap_xl flex_align_center">
								<Knob
									value={financialForm?.loan?.paidQuotas}
									color="green_300"
									total={financialForm?.loan?.duration}
									variant="classic"
									className="dim_xxxl border_transparent flex_shrink_0"
								/>
								<div>
									<Typography scale="medium" weight="600" className="">
										Cuotas pagadas
									</Typography>
									<Typography scale="xsmall" weight="400" className="">
										{financialForm?.loan?.paidQuotas} cuotas por pagar
									</Typography>
								</div>
							</div>
							<div className="br_xxs border_neutral_400 border_solid border_1 flex_basis_0 flex_grow_1 bg_neutral_0 p_y_md p_x_lg display_flex flex_gap_xl flex_align_center">
								<Knob
									value={financialForm?.loan?.lateQuotas}
									color="red_300"
									total={
										financialForm.loan.duration - financialForm.loan.paidQuotas
									}
									variant="classic"
									className="dim_xxxl border_transparent flex_shrink_0"
								/>
								<div>
									<Typography scale="medium" weight="600" className="">
										Cuotas vencidas
									</Typography>
									<Typography scale="xsmall" weight="400" className="">
										{priceFormatFinancial(financialForm?.loan?.lateAmount, 0)}{' '}
										en mora
									</Typography>
								</div>
							</div>
							{[LoanStatus.writeOff].includes(financialForm?.loan.status) ? (
								financialForm?.loan.writeOffAmount ? (
									<div className="br_xxs border_neutral_400 border_solid border_1 flex_basis_0 flex_grow_1 bg_neutral_0 p_y_xs p_x_md">
										<Typography
											scale="medium"
											weight="400"
											className=""
											textColor="neutral_800"
										>
											Valor de cuota
										</Typography>
										<Typography
											scale="large"
											weight="600"
											textColor="primary_300"
											className=""
										>
											{priceMXNTotal(financialForm.loan.weeklyPayment, 2)}
										</Typography>
									</div>
								) : (
									<div className="br_xxs border_neutral_400 border_solid border_1 flex_basis_0 flex_grow_1 bg_neutral_0 p_y_xs p_x_md display_flex flex_align_center">
										<Button
											scale="small"
											icon={<Wallet />}
											variant="outline"
											className="w_100_per"
											onClick={() => setOpenSettle(true)}
										>
											Liquidar crédito
										</Button>
									</div>
								)
							) : null}
						</div>
						{financialForm?.loan.writeOffAmount ? (
							<div className="display_flex flex_gap_md flex_align_stretch m_b_md">
								<div className="br_xxs border_neutral_400 border_solid border_1 flex_basis_0 flex_grow_1 bg_neutral_0 p_y_xs p_x_md">
									<Typography
										scale="medium"
										weight="400"
										className=""
										textColor="neutral_800"
									>
										Monto castigado
									</Typography>
									<Typography
										scale="large"
										weight="600"
										textColor="neutral_800"
										className=""
									>
										{priceMXNTotal(financialForm.loan.writeOffAmount, 0)}
									</Typography>
								</div>
								<div className="br_xxs border_neutral_400 border_solid border_1 flex_basis_0 flex_grow_1 bg_neutral_0 p_y_xs p_x_md">
									<Typography
										scale="medium"
										weight="400"
										className=""
										textColor="neutral_800"
									>
										Monto Liquidado
									</Typography>
									<Typography
										scale="large"
										weight="600"
										textColor="green_300"
										className=""
									>
										{priceMXNTotal(
											financialForm.loan.writeOffAmount *
												(1 - financialForm.loan.writeOffDiscount),
											0,
										)}
									</Typography>
								</div>
								<div className="br_xxs border_neutral_400 border_solid border_1 flex_basis_0 flex_grow_1 bg_neutral_0 p_y_xs p_x_md">
									<Typography
										scale="medium"
										weight="400"
										className=""
										textColor="neutral_800"
									>
										Valor del descuento
									</Typography>
									<div className="flex_center flex_justify_between">
										<Typography
											scale="large"
											weight="600"
											textColor="neutral_800"
											className=""
										>
											{Math.round(financialForm.loan.writeOffDiscount * 100)}%
										</Typography>
										<LightTooltip
											title={
												LoanStatusValues[
													financialForm.loan?.status as keyof typeof LoanStatus
												]?.tooltip ?? financialForm.loan?.status
											}
										>
											<div>
												<Typography
													scale="medium"
													weight="600"
													className="br_xs p_x_md p_y_xxxs text_center text_neutral_0 pos_relative
                                        bg_neutral_1000_nocontrast text_no_break"
												>
													Write Off Liquidado
												</Typography>
											</div>
										</LightTooltip>
									</div>
								</div>
							</div>
						) : (
							<div className="display_flex flex_gap_md flex_align_stretch m_b_md">
								<div className="br_xxs border_neutral_400 border_solid border_1 flex_basis_0 flex_grow_1 bg_neutral_0 p_y_xs p_x_md">
									<Typography
										scale="medium"
										weight="400"
										className=""
										textColor="neutral_800"
									>
										Deuda total
									</Typography>
									<Typography
										scale="large"
										weight="600"
										textColor="red_300"
										className=""
									>
										{priceMXNTotal(financialForm.loan.totalDebt, 2)}
									</Typography>
								</div>
								<div className="br_xxs border_neutral_400 border_solid border_1 flex_basis_0 flex_grow_1 bg_neutral_0 p_y_xs p_x_md">
									<Typography
										scale="medium"
										weight="400"
										className=""
										textColor="neutral_800"
									>
										Valor de cuota
									</Typography>
									<Typography
										scale="large"
										weight="600"
										textColor="primary_300"
										className=""
									>
										{priceMXNTotal(financialForm.loan.weeklyPayment, 2)}
									</Typography>
								</div>
								<div className="br_xxs border_neutral_400 border_solid border_1 flex_basis_0 flex_grow_1 bg_neutral_0 p_y_xs p_x_md">
									<Typography
										scale="medium"
										weight="400"
										className=""
										textColor="neutral_800"
									>
										Deuda saldada
									</Typography>
									<Typography
										scale="large"
										weight="600"
										textColor="green_300"
										className=""
									>
										{priceMXNTotal(financialForm.loan.totalPaid, 2)}
									</Typography>
									<div className="display_flex flex_align_center flex_gap_md">
										<Typography
											scale="small"
											weight="600"
											textColor="green_300"
											className=""
										>
											{Math.round(
												(Number(financialForm.loan.totalPaid) * 100) /
													Number(financialForm.loan.totalLoan),
											)}
											%
										</Typography>
										<ProgressBar
											containerClassName="bg_neutral_400 h_sm"
											fillClassName="bg_green_300 h_sm"
											percentage={
												(Number(financialForm.loan.totalPaid) * 100) /
												Number(financialForm.loan.totalLoan)
											}
										/>
									</div>
								</div>
							</div>
						)}

						<div className="w-100-per">
							<div className="m_y_xl">
								<LoanPaymentsTable
									loading={loading}
									loan={financialForm?.loan}
								/>
							</div>
						</div>
					</Grid>
				</Grid>
			)}
			{openSettle && (
				<ModalConfirmation
					openConfirmation={openSettle}
					handleClose={closeSettle}
					icon={<User />}
					isConfirmationLoading={false}
					widthDesktop={30}
					actions={[
						{
							label: 'Cancelar',
							color: 'grey',
							action: closeSettle,
						},
						{
							label: 'Liquidar',
							color: 'primary',
							disabled: loadingSettle,
							action: () => {
								settleLoan();
							},
						},
					]}
				>
					<>
						<Typography scale="large" weight="600" className="m_b_lg">
							Liquidación de crédito
						</Typography>
						<Typography scale="medium" weight="400" className="m_b_lg">
							Selecciona el porcentaje de descuento para la liquidación de este
							crédito
						</Typography>

						<Input
							type="number"
							onChange={(e: any) =>
								setWriteOffAmount(parseInt(e.target.value, 10))
							}
							value={writeOffAmount}
							title="Monto castigado"
							icon={<Money />}
							name="amount"
							placeholder="Escribe el monto castigado"
						/>
						<Input
							type="number"
							onChange={(e: any) =>
								setWriteOffDiscount(parseInt(e.target.value, 10))
							}
							value={writeOffDiscount}
							title="Porcentaje de descuento"
							icon={<Discount />}
							name="discount"
							placeholder="Escribe el valor de descuento"
						/>
						<div className="flex_center flex_justify_between">
							<Typography scale="large" weight="400" className="m_b_lg">
								Total a pagar:
							</Typography>
							<Typography scale="large" weight="400" className="m_b_lg">
								{priceMXNTotal(
									writeOffAmount * (1 - writeOffDiscount / 100),
									0,
								)}
							</Typography>
						</div>
					</>
				</ModalConfirmation>
			)}
			{/* {openStatusChange && (
				<ModalConfirmation
					openConfirmation={openStatusChange}
					handleClose={() => {
						setOpenStatusChange(false);
						setSubstatusChange('');
					}}
					icon={<Refresh className="text_primary_300" />}
					isConfirmationLoading={false}
					actions={[
						{
							label: 'Cancelar y volver',
							color: 'grey',
							action: () => {
								setOpenStatusChange(false);
								setSubstatusChange('');
							},
						},
						{
							label: 'Cambiar estado',
							color: 'primary',
							loading: changeStatusLoading,
							action: () => {
								updateStatus(statusChange);
							},
						},
					]}
					title={
						<Typography scale="large" weight="400">
							¿Estás seguro de que{' '}
							<Typography scale="large" weight="600" component="span">
								deseas modificar el estado del loano?{' '}
							</Typography>
						</Typography>
					}
					subtitle={
						<>
							<Typography scale="medium" weight="400" textColor="neutral_700">
								El estado del loano
								<Typography
									scale="medium"
									weight="600"
									component="span"
								>{` pasará de ${
									(LoanStatusValues as any)[financialForm.loan.status]?.title ??
									''
								} a ${
									(LoanStatusValues as any)[statusChange]?.title
								}.`}</Typography>
							</Typography>
							{statusChange === 'devolution' ||
							statusChange === 'repossession' ? (
								<Typography
									scale="medium"
									weight="600"
									textColor="red_300"
									className="m_t_md"
								>
									*Ten en cuenta que pasar el ozoner a este estado eliminara el
									contrato y no se podra devolver al estado activo
								</Typography>
							) : null}
						</>
					}
				/>
			)} */}

			{openEnded && (
				<ModalConfirmation
					openConfirmation={openEnded}
					handleClose={() => {
						setOpenEnded(false);
						setEndedDate(null);
					}}
					icon={<Calendar className="text_primary_300" />}
					isConfirmationLoading={false}
					widthDesktop={30}
					actions={[
						{
							label: 'Cancelar',
							color: 'grey',
							action: () => {
								setOpenEnded(false);
								setEndedDate(null);
							},
						},
						{
							label: 'Guardar',
							color: 'primary',
							action: () => {
								setOpenEnded(false);
								changeEndedDate();
							},
						},
					]}
				>
					<>
						<Typography scale="large" weight="600" className="m_b_md">
							Fecha de finalización de contrato{' '}
						</Typography>
						<Typography scale="medium" weight="400" className="m_b_md">
							Esta fecha hace referencia al momento en el que el contrato
							finalizo o se da por finalizado
						</Typography>

						<Input
							type="date"
							onChange={(e: any) => setEndedDate(e.target.value)}
							value={endedDate}
							title=""
							name="endedAt"
							placeholder="Escribe el valor del depósito aquí"
							className="date_select_ended"
						/>
					</>
				</ModalConfirmation>
			)}
			{openTags && (
				<ModalConfirmation
					openConfirmation={openTags}
					handleClose={() => {
						setOpenTags(false);
						setEditTags([]);
					}}
					icon={<Tag className="text_primary_300" />}
					isConfirmationLoading={isConfirmationLoading}
					widthDesktop={30}
					actions={[
						{
							label: 'Cancelar',
							color: 'grey',
							action: () => {
								setOpenTags(false);
								setEditTags([]);
							},
						},
						{
							label: 'Guardar',
							color: 'primary',
							disabled: isConfirmationLoading,
							action: () => {
								updateTags();
							},
						},
					]}
				>
					<div className="h_30_vp">
						<Typography scale="large" weight="600" className="m_b_md">
							Clasificación de crédito{' '}
						</Typography>
						<Typography scale="medium" weight="400" className="m_b_md">
							Asigne una clasificación para este crédito
						</Typography>

						<Select
							fixedPlaceholder
							variant="checkbox"
							placeholder="Etiqueta"
							options={Object.keys(LoanStatusFlagsValues).map((key) => ({
								value: key,
								label:
									LoanStatusFlagsValues[key as LoanStatusFlags]?.title ?? key,
							}))}
							value={editTags}
							onChange={(e: any) => {
								setEditTags(e.target.value);
							}}
						/>
					</div>
				</ModalConfirmation>
			)}
			{openStatusHistory && financialForm?.loan?._id && (
				<HistoryStatus
					historic={financialForm.loan.statusHistoric ?? []}
					open={openStatusHistory}
					setOpen={setOpenStatusHistory}
					ozonios={ozonios}
				/>
			)}
		</>
	);
}
export default LoanOzoner;
export {};
