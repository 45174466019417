import { axiosErrorHandler, get } from 'models/shared/helpers/axiosInstance';
import { buildPathWithQueryParams } from 'models/shared/helpers/utils';
import {
	BaseDashboardDto,
	DashboardLoansByStatusDto,
	DashboardVehiclesByStatusDto,
} from '../interfaces/dashboard.interface';

export const getActiveUsers = async () => {
	try {
		const url = 'v1/dashboard/activeUsers';
		const res = await get(url);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};
export const firstPaymentDefault = async () => {
	try {
		const url = 'v1/dashboard/firstPaymentDefault';
		const res = await get(url);
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};
export const getVehiclesByStatus = async (
	dto: DashboardVehiclesByStatusDto,
) => {
	try {
		if (dto) {
			const res = await get(
				buildPathWithQueryParams('v1/dashboard/getVehiclesByStatus', dto),
			);
			return res.data;
		}
		return null;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};
export const getLoansByStatus = async (dto: DashboardLoansByStatusDto) => {
	try {
		if (dto) {
			const res = await get(
				buildPathWithQueryParams('v1/dashboard/getLoansByStatus', dto),
			);
			return res.data;
		}
		return null;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};
export const getInventoryStatus = async () => {
	try {
		const res = await get('v1/dashboard/getInventory');
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};
export const getLoans = async () => {
	try {
		const res = await get('v1/dashboard/getloans');
		return res.data;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};
export const paymentAnalysis = async (dto: BaseDashboardDto) => {
	try {
		if (dto) {
			const res = await get(
				buildPathWithQueryParams('v1/dashboard/getPaymentsAnalysis', dto),
			);
			return res.data;
		}
		return null;
	} catch (err) {
		throw axiosErrorHandler(err);
	}
};
