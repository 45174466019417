/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from 'react';

// -----------------LIBRARIES IMPORTS------------------- //
import { Typography } from 'design_system/src';
import '../../../ozoner.scss';
import { ReactComponent as Fail } from 'design_system/src/static/icons/close-circle.svg';
import { ReactComponent as Success } from 'design_system/src/static/icons/tick-circle.svg';

import { RequireRole } from 'components/layouts/main/sidebar/Sidebar';
import { EvidenceDocumentType } from 'models/shared/types/documents';
import { Roles } from 'models/ozonio/enums/ozonio.enum';
import { ozonerContext } from '../../OzonerContext';
import {
	DevolutionAction,
	ScheduleAction,
	UpdateQuota,
} from './SolicitudActions';
import { VehicleSection } from './VehicleSection';
import {
	SolicitudAdvanced,
	SolicitudApartado,
	SolicitudDeposit,
} from './SolicitudNumberInputs';

const validationItem = (
	valid: boolean,
	validLabel: string,
	invalidLabel: string,
) => (
	<>
		{valid ? (
			<Success className="dim_lg text_green_300" />
		) : (
			<Fail className="dim_lg text_red_300" />
		)}
		<Typography
			scale="xsmall"
			weight="400"
			textColor={valid ? 'green_300' : 'red_300'}
			className="text_no_break"
		>
			{valid ? validLabel : invalidLabel}
		</Typography>
	</>
);

function ApprovedSection() {
	const { financialForm, vehicle, loanTotals } = useContext(ozonerContext);

	const [hasLoanTime, sethasLoanTime] = useState(false);
	const [hasAdvancedPayment, setHasAdvancedPayment] = useState(false);
	const [hasRecipt, setHasRecipt] = useState(false);
	const [correctQuota, setCorrectQuota] = useState(false);

	const [canSchedule, setCanSchedule] = useState(false);

	useEffect(() => {
		setHasAdvancedPayment(false);
		setHasRecipt(false);
		sethasLoanTime(!!financialForm?.form?.loanApplication?.loanDuration);

		financialForm?.form?.documents?.forEach((doc) => {
			if (doc.typeEvidence === EvidenceDocumentType.layawayReceipt) {
				setHasRecipt(true);
			}
			if (doc.typeEvidence === EvidenceDocumentType.advancedmoney) {
				setHasAdvancedPayment(true);
			}
		});
	}, [financialForm.form, vehicle]);

	useEffect(() => {
		setCorrectQuota(
			!!loanTotals?.paymentWeek &&
				loanTotals.paymentWeek <=
					(financialForm?.form?.loanApplication?.approvedWeeklyPayment ?? 0),
		);
	}, [loanTotals]);

	useEffect(() => {
		setCanSchedule(
			!!vehicle &&
				hasLoanTime &&
				correctQuota &&
				financialForm?.form?.loanApplication?.deposit !== undefined &&
				hasRecipt &&
				(!financialForm?.form?.loanApplication?.advancedMoney ||
					hasAdvancedPayment),
		);
	}, [
		hasLoanTime,
		hasAdvancedPayment,
		hasRecipt,
		correctQuota,
		financialForm,
		vehicle,
	]);

	return (
		<>
			<RequireRole authRoles={[Roles.ADMIN, Roles.CREDIT_ANALYST]}>
				<div
					id="validate-soli-ozoner"
					className="p_md border_b_solid border_1 border_neutral_400 display_flex flex_col flex_gap_md"
				>
					<UpdateQuota />
				</div>
			</RequireRole>
			<VehicleSection />
			<SolicitudDeposit />
			<SolicitudAdvanced />
			{vehicle && <SolicitudApartado />}
			<div
				id="validate-soli-ozoner"
				className="p_md border_b_solid border_1 border_neutral_400 display_flex flex_col flex_gap_md"
			>
				<div className="display_flex flex_gap_xs flex_align_center">
					{validationItem(!!vehicle, 'Moto asignada', 'Moto sin asignar')}
				</div>
				<div className="display_flex flex_gap_xs flex_align_center">
					{validationItem(
						hasLoanTime,
						'Plan de pagos seleccionado',
						'Plan de pagos sin asignar',
					)}
				</div>
				<div className="display_flex flex_gap_xs flex_align_center">
					{validationItem(
						correctQuota,
						'Valor de cuota apropiada',
						'Cuota demasiado alta',
					)}
				</div>
				<div className="display_flex flex_gap_xs flex_align_center">
					{validationItem(
						financialForm?.form?.loanApplication?.deposit !== undefined,
						'Valor de depósito asignado',
						'Valor de depósito sin asignar',
					)}
				</div>
				{financialForm?.form?.loanApplication?.advancedMoney !== undefined &&
					financialForm.form.loanApplication?.advancedMoney > 0 && (
						<div className="display_flex flex_gap_xs flex_align_center">
							{validationItem(
								hasAdvancedPayment,
								'Comprobante pago adelantado adjunto',
								'Comprobante pago adelantado sin subir',
							)}
						</div>
					)}
				<div className="display_flex flex_gap_xs flex_align_center">
					{validationItem(
						hasRecipt,
						'Comprobante de Apartado adjunto',
						'Comprobante de Apartado sin subir',
					)}
				</div>
				<ScheduleAction disable={!canSchedule} />
				<DevolutionAction />
			</div>
		</>
	);
}
export default ApprovedSection;
