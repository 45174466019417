import axios from 'axios';
import { MoffinValidation, postalCodeResponse } from '../types/moffin';
import { get } from '../helpers/axiosInstance';

export const fetchByPostalCode = async (cp: string) => {
	const config = axios.create({
		baseURL: process.env.REACT_APP_MOFFIN_BASE_URL,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Token ${process.env.REACT_APP_MOFFIN_TOKEN}`,
		},
	});

	return config.get<postalCodeResponse[]>(`api/v1/postal-codes/${cp}`);
};

export const getMoffinValidation = async (
	formId: string,
): Promise<MoffinValidation> => {
	try {
		const res = await get<MoffinValidation>(`/v1/moffin/${formId}`);
		return res.data;
	} catch (err: any) {
		throw err.response.data.message;
	}
};
