import React, { useEffect, useRef, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl, { LngLatBounds } from 'mapbox-gl';

import { ReactComponent as Back } from 'design_system/src/static/icons/left-sm.svg';
import { ReactComponent as Moto } from 'design_system/src/static/icons/motorcycle.svg';
import { ReactComponent as Signal } from 'design_system/src/static/icons/radio-signal.svg';
import { ReactComponent as NoSignal } from 'design_system/src/static/icons/no-radio-signal.svg';
import { ReactComponent as Home } from 'design_system/src/static/icons/temple.svg';
import { ReactComponent as Hotspot } from 'design_system/src/static/icons/fire-camp.svg';
import { ReactComponent as List } from 'design_system/src/static/icons/bulleted-list-2.svg';
import { ReactComponent as GPS } from 'design_system/src/static/icons/gps.svg';
import profileDefaultOzoner from 'static/images/default_profile_ozoner.png';

import { Button, ProgressBar, Typography } from 'design_system/src';

import './recovery.scss';
import {
	FetchRecoveries,
	FetchRoute,
	editRecovery,
} from 'models/repossession/controllers/repossession.controller';
import { toasterOptions } from 'models/shared/enums/toaster.enum';
import { Ozoner } from 'models/ozoner/interfaces/ozoner.interface';
import { RecoveryPopulated } from 'models/repossession/interfaces/recovery.interface';
import {
	RecoveryStatus,
	RecoveryStatusValues,
} from 'models/repossession/enums/recovery.enums';
import { Route } from 'models/repossession/interfaces/route.interface';
import {
	InterestPoint,
	Repossession,
} from 'models/repossession/interfaces/repossession.interface';
import { InterestPointType } from 'models/repossession/enums/repossession.enums';
import { PaginatedDocuments } from 'models/shared/types/pagination';
import { toasterNotification } from 'models/shared/helpers/toasterNotification';
import { findOneOzoner } from 'models/ozoner/controllers/ozoner.controller';
import { OzonerStatusValues } from 'models/ozoner/enums/ozoner.enums';
import { priceFormatFinancial } from 'models/shared/helpers/prices';
import { momentLocal } from 'models/shared/helpers/dates';
import { findOneLoan } from 'models/loan/controllers/loan.controller';
import { LoanWithExtraFields } from 'models/loan/interfaces/loan.interface';
import { createMarker } from './markers';

let markers: { [id: string]: mapboxgl.Marker } = {};
let visibleMarkers: { [id: string]: mapboxgl.Marker } = {};
let firstUpdate = true;
export const RouteDetailMobile = () => {
	const { _id } = useParams();

	const navigate = useNavigate();

	const [route, setRoute] = useState<Route>();
	const [recoveries, setRecoveries] = useState<RecoveryPopulated[]>();
	const [totals, setTotals] = useState<{ [id in RecoveryStatus]: number }>();
	const [ozonersData, setOzonersData] = useState<{
		[key: string]: Ozoner;
	}>({});
	const [ozonersSelfies, setOzonersSelfies] = useState<{
		[key: string]: string;
	}>({});

	const [lng, setLng] = useState(-99.12766);
	const [lat, setLat] = useState(19.42847);
	const [zoom, setZoom] = useState(10);
	const [boundsFixed, setBoundsFixed] = useState<LngLatBounds>();
	const [lineRoute, setLineRoute] = useState();

	const mapContainer = useRef<any>(null);
	const [view, setView] = useState(false);
	const [shouldUpdateSource, setShouldUpdateSource] = useState(false);

	const [detailsId, setDetailsId] = useState<string>();
	const [details, setDetails] = useState<RecoveryPopulated | undefined>();

	const [loans, setLoans] = useState<{ [key: string]: LoanWithExtraFields }>(
		{},
	);
	const [loan, setLoan] = useState<LoanWithExtraFields | null>();

	useEffect(() => {
		const selected = recoveries?.filter(
			(recovery) => recovery._id === detailsId,
		);
		if (selected?.length) {
			setDetails(selected[0]);
			if (selected[0].repossession.loan in loans) {
				setLoan(loans[selected[0].repossession.loan]);
			} else {
				findOneLoan(selected[0].repossession.loan).then((loanData) => {
					setLoan(loanData);
					setLoans({
						...loans,
						[selected[0].repossession.loan]: loanData,
					});
				});
			}
		}
	}, [detailsId]);

	const openLocation = (location: {
		repossession: Repossession;
		interestPoint: InterestPoint;
		status: RecoveryStatus;
	}) => {
		window.open(
			`https://www.google.com/maps/search/?api=1&query=${location.interestPoint.location.coordinates[1]},${location.interestPoint.location.coordinates[0]}`,
			'_blank',
		);
	};

	const typeRecovery = {
		[InterestPointType.address]: (
			<div className="display_flex flex_gap_xs flex_align_center m_b_xs">
				<Home className="dim_lg text_yellow_100" />
				<Typography scale="small" weight="600">
					Hogar Ozoner
				</Typography>
			</div>
		),
		[InterestPointType.hotspot]: (
			<div className="display_flex flex_gap_xs flex_align_center m_b_xs">
				<Hotspot className="dim_lg text_primary_300" />
				<Typography scale="small" weight="600">
					Hotspot
				</Typography>
			</div>
		),
		[InterestPointType.signalVehicle]: (
			<div className="display_flex flex_gap_xs flex_align_center m_b_xs">
				<Moto className="dim_lg text_green_300" />
				<Signal className="dim_lg text_green_300" />
				<Typography scale="small" weight="600">
					Ultima ubicacion
				</Typography>
			</div>
		),
		[InterestPointType.noSignalVehicle]: (
			<div className="display_flex flex_gap_xs flex_align_center m_b_xs">
				<Moto className="dim_lg text_red_300" />
				<NoSignal className="dim_lg text_red_300" />
				<Typography scale="small" weight="600">
					Ultima ubicacion
				</Typography>
			</div>
		),
	};

	const fetchData = () => {
		if (_id) {
			FetchRoute(_id).then((resp: Route) => {
				setRoute(resp);
			});

			FetchRecoveries(_id).then(
				(recoveriesData: PaginatedDocuments<RecoveryPopulated>) => {
					let mainData = recoveriesData.data.sort((a, b) => a.index - b.index);
					let canChange = true;
					mainData = mainData.map((recovery) => {
						const res = {
							...recovery,
							canChangeStatus: canChange,
						};
						if (
							!(
								recovery.status === RecoveryStatus.canceled ||
								recovery.status === RecoveryStatus.failed ||
								recovery.status === RecoveryStatus.recovered
							)
						) {
							canChange = false;
						}
						return res;
					});
					setRecoveries(mainData);
				},
			);
		}
	};

	useEffect(() => {
		return () => {
			visibleMarkers = {};
			markers = {};
			firstUpdate = true;
		};
	}, []);

	const changeStatus = (id: string, status: RecoveryStatus) => {
		if (recoveries) {
			editRecovery(id, {
				status,
			})
				.then(() => {
					fetchData();
					toasterNotification({
						msg: 'Se cambio de estado la recuperacion',
						toasterType: toasterOptions.success,
						style: 'light',
					});
				})
				.catch(() => {
					fetchData();
					toasterNotification({
						msg: 'Ocurrio un error',
						toasterType: toasterOptions.error,
						style: 'light',
					});
				});
		}
	};

	const map = useRef<any>(null);

	const updateSource = () => {
		if (recoveries?.length) {
			const bounds = new mapboxgl.LngLatBounds();
			const lineString: any = {
				type: 'Feature',
				geometry: {
					type: 'LineString',
					coordinates: [],
				},
			};

			const features = recoveries.map(
				({ repossession, interestPoint, _id: id }) => {
					return {
						type: 'Feature',
						properties: {
							title: repossession.vehicleId,
							description: '',
							policy: repossession.policy,
							repossession: JSON.stringify(repossession),
							interestPoint: JSON.stringify(interestPoint),
							type: interestPoint.type,
							id,
							isTrackGPS: interestPoint.isTrackGPS,
						},
						geometry: {
							coordinates: [...interestPoint.location.coordinates],
							type: 'Point',
						},
					};
				},
			);
			features.forEach((feat) => {
				bounds.extend(feat.geometry.coordinates as any);
				lineString.geometry.coordinates.push([...feat.geometry.coordinates]);
			});
			setBoundsFixed(bounds);
			setLineRoute(lineString);
			if (!details) {
				map.current.fitBounds(bounds, {
					padding: 40,
				});
				map.current.getSource('line-route')?.setData(lineString);
			}

			map.current.getSource('recoveries')?.setData({
				type: 'FeatureCollection',
				features,
			});
		}
	};
	const goToVehicle = (id: string, interestPoint: InterestPoint) => {
		map.current.flyTo({
			center: {
				lat: interestPoint.location.coordinates[1] - 0.0045,
				lng: interestPoint.location.coordinates[0],
			},
			zoom: 15,
		});
		setDetailsId(id);
	};
	useEffect(() => {
		if (recoveries) {
			const calculateTotals: { [id in RecoveryStatus]: number } = {
				[RecoveryStatus.pending]: 0,
				[RecoveryStatus.onWay]: 0,
				[RecoveryStatus.inSite]: 0,
				[RecoveryStatus.recovered]: 0,
				[RecoveryStatus.failed]: 0,
				[RecoveryStatus.canceled]: 0,
			};

			recoveries.forEach((recovery) => {
				if (detailsId === recovery._id) {
					setDetails(recovery);
				}
				calculateTotals[recovery.status as any as RecoveryStatus] += 1;
			});
			setTotals(calculateTotals);
			updateSource();
			const ozoners: { [key: string]: Ozoner } = {};
			const selfies: { [key: string]: string } = {};
			Promise.all(
				recoveries.map((recovery) =>
					findOneOzoner(recovery?.repossession?.ozoner._id).then(
						(ozonerResponse) => {
							if (ozonerResponse) {
								ozoners[ozonerResponse._id] = ozonerResponse;
								if (ozonerResponse.documents?.length) {
									const url = ozonerResponse.documents.filter(
										(obj: any) => obj.typeEvidence === 'selfie',
									);
									if (url.length > 0) {
										selfies[ozonerResponse._id] = url[0].url;
									}
								}
							}
						},
					),
				),
			).then(() => {
				setOzonersData(ozoners);
				setOzonersSelfies(selfies);
			});
		}
	}, [recoveries]);
	const updateMarkers = (adjustView = true) => {
		const features = map.current.querySourceFeatures('recoveries');
		if (features) {
			const newMarkers: any = {};
			Object.values(visibleMarkers).forEach((marker: any) => {
				marker.remove();
			});

			features.forEach((feature: any) => {
				const coords = feature.geometry.coordinates;
				const props = feature.properties;
				const { id } = props;

				let marker = markers[id];

				if (!marker) {
					const el: any = createMarker(feature, () => {
						goToVehicle(id, JSON.parse(props.interestPoint));
					});
					if (el) {
						marker = new mapboxgl.Marker({
							element: el,
							anchor: props.cluster ? 'center' : 'bottom',
						}).setLngLat(coords);
						markers[id] = marker;
					}
				}
				newMarkers[id] = marker;
				marker.addTo(map.current);
			});
			visibleMarkers = newMarkers;
		}
	};

	const goPrevious = (index: number) => {
		const previous = recoveries?.filter(
			(recovery) => recovery.index === index - 1,
		);
		if (previous?.length) {
			goToVehicle(previous[0]._id, previous[0].interestPoint);
		}
	};
	useEffect(() => {
		if (map.current) return;
		// eslint-disable-next-line no-param-reassign
		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: 'mapbox://styles/mapbox/streets-v12',
			center: [lng, lat],
			zoom,
		});
		map.current.addControl(new mapboxgl.NavigationControl(), 'bottom-left');
		map.current.addControl(
			new mapboxgl.GeolocateControl({
				positionOptions: {
					enableHighAccuracy: true,
				},
				trackUserLocation: true,
				showUserHeading: true,
			}),
			'bottom-left',
		);

		map.current.on('load', () => {
			map.current.on('sourcedata', (e: any) => {
				if (e.sourceId === 'recoveries' && firstUpdate) {
					setShouldUpdateSource(true);
					firstUpdate = false;
				}
			});
			map.current.addSource('line-route', {
				type: 'geojson',
				data: {
					type: 'FeatureCollection',
					features: [],
				},
			});
			map.current.addSource('recoveries', {
				type: 'geojson',
				data: {
					type: 'FeatureCollection',
					features: [],
				},
			});
			map.current.addLayer({
				id: 'interest_points',
				type: 'circle',
				source: 'recoveries',
				paint: {
					'circle-color': '#01EBF9',
					'circle-opacity': 0.8,
					'circle-radius': 6,
				},
			});

			map.current.addLayer({
				id: 'line',
				type: 'line',
				source: 'line-route',
				layout: {
					'line-cap': 'square',
					'line-join': 'round',
					// "line-miter-limit": 2,
				},
				paint: {
					'line-color': '#32E0C1',
					'line-width': 4,
				},
			});
			map.current.on('render', () => {
				updateMarkers();
			});
		});
		map.current.on('move', () => {
			setLng(map.current.getCenter().lng.toFixed(4));
			setLat(map.current.getCenter().lat.toFixed(4));
			setZoom(map.current.getZoom().toFixed(2));
		});
	});

	useEffect(() => {
		if (shouldUpdateSource) {
			updateSource();
			setShouldUpdateSource(false);
		}
	}, [shouldUpdateSource]);

	useEffect(() => {
		fetchData();
	}, [_id]);
	useEffect(() => {
		if (details) {
			const lineString: any = {
				type: 'Feature',
				geometry: {
					type: 'LineString',
					coordinates: [],
				},
			};
			map.current.getSource('line-route')?.setData(lineString);
		} else if (lineRoute) {
			map.current.getSource('line-route')?.setData(lineRoute);
		}
	}, [details]);

	const goBack = () => {
		if (details) {
			setDetails(undefined);
			setLoan(undefined);
			if (boundsFixed) {
				map.current.fitBounds(boundsFixed, {
					padding: 40,
				});
			}
		} else {
			navigate(-1);
		}
	};
	return (
		<>
			{details ? null : (
				<div className="options_bar display_flex flex_gap_xs">
					<div
						className={`border_1 border_solid cursor_pointer border_${
							view ? 'neutral_600' : 'primary_300'
						} p_xs br_xs display_flex`}
						tabIndex={0}
						onClick={() => setView(false)}
						onKeyDown={() => setView(false)}
						role="button"
					>
						<List
							className={`dim_lg border_transparent text_${
								view ? 'neutral_600' : 'primary_300'
							}`}
						/>
					</div>
					<div
						className={`border_1 border_solid cursor_pointer border_${
							!view ? 'neutral_600' : 'primary_300'
						} p_xs br_xs display_flex`}
						tabIndex={0}
						onClick={() => setView(true)}
						onKeyDown={() => setView(true)}
						role="button"
					>
						<GPS
							className={`dim_lg border_transparent text_${
								!view ? 'neutral_600' : 'primary_300'
							}`}
						/>
					</div>
				</div>
			)}
			<div className="back_bar display_flex flex_gap_xs">
				<Back onClick={() => goBack()} className="dim_xxl" />
			</div>
			<div
				ref={mapContainer}
				className={`map_container_mobile ${view || details ? '' : 'hide'}`}
			/>
			<div
				className={`pos_absolute z_index_2 details_container_mobile ${
					details ? 'open' : ''
				}`}
			>
				{details && loan && (
					<div className="dso_card bg_neutral_0 w_100_per p_t_xxxl">
						<div
							className={`dso_card_img br_xxs bg_neutral_200 recovery_mobile_photo display_flex overflow_hidden border_${
								(OzonerStatusValues as any)[
									ozonersData[details.repossession.ozoner._id]?.status ?? ''
								]?.color ?? 'primary_300'
							}`}
						>
							<img
								src={
									ozonersData[details.repossession.ozoner._id]?.image?.url ||
									ozonersSelfies[details.repossession.ozoner._id] ||
									profileDefaultOzoner
								}
								alt=""
								className="recovery_photo flex_shrink_0"
							/>
						</div>
						<div className=" flex_center_col flex_gap_xs border_1 border_b_solid border_neutral_400">
							<Typography scale="large" weight="600" className="text_center">
								{details.repossession?.ozonerName}
							</Typography>
							<Typography
								scale="small"
								weight="600"
								className={`
                                dso_chip_small 
                                br_
                                bg_${
																	RecoveryStatusValues[
																		details?.status as keyof typeof RecoveryStatus
																	]?.color
																}_nocontrast text_neutral_0 overflow_hidden overflow_ellipsis text_center
                            `}
							>
								{RecoveryStatusValues[
									details?.status as keyof typeof RecoveryStatus
								]?.title ?? details?.status}
							</Typography>
							<div className="flex_center flex_gap_xl m_b_md">
								<div className="br_md overflow_hidden flex_shrink_0 vehicle_details_mobile_img">
									<img
										className="img_contain"
										src={
											details.repossession.vehicle?.images[0]
												? details.repossession.vehicle.images[0].url
												: `${process.env.REACT_APP_STORAGE_BASE_URL}public/uploads/generic-moto.png`
										}
										alt="vehicle"
									/>
								</div>
								<div className="display_flex flex_col flex_gap_xxs">
									<Typography
										scale="medium"
										weight="600"
										className=""
										textColor="neutral_700"
									>
										{details.repossession.vehicle?.internalId}
									</Typography>
									<Typography
										scale="small"
										weight="400"
										className=""
										textColor="neutral_700"
									>
										{details.repossession.vehicle?.brand?.name || '-'}{' '}
										{details.repossession.vehicle?.model?.name || '-'}{' '}
										{details.repossession.vehicle?.cylindersCapacity || '-'}
									</Typography>
								</div>
							</div>
						</div>
						<div className="p_md flex_center_col flex_gap_md">
							<div className="m_b_sm bg_neutral_200 display_flex flex_col w_100_per br_xs">
								<div className="border_solid border_1 border_neutral_400 p_y_xs br_t_xs flex_center flex_gap_xs">
									<Typography
										scale="medium"
										weight="600"
										textColor="red_300"
										className="text_no_break text_center"
									>
										{loan.lateQuotas} cuotas
									</Typography>
									<Typography
										scale="medium"
										weight="400"
										textColor="neutral_900"
										className="text_no_break text_center"
									>
										{' '}
										en mora
									</Typography>
								</div>
								<div className="display_flex flex_gap_xs flex_justify_center flex_align_center border_solid border_1 border_neutral_400  flex_center flex_gap_xs p_y_xs">
									<Typography scale="medium" weight="600" textColor="green_300">
										{Math.round((loan.totalDebt / loan.totalLoan) * 100)}%
									</Typography>
									<Typography
										scale="medium"
										weight="400"
										textColor="neutral_900"
									>
										cuotas pagadas
									</Typography>
								</div>
								<div className="border_solid border_1 border_neutral_400  flex_center flex_gap_xs p_y_xs br_r_xs">
									<Typography
										scale="medium"
										weight="600"
										textColor="neutral_900"
										className="text_center"
									>
										{priceFormatFinancial(loan.totalDebt, 0)}
									</Typography>
									<Typography
										scale="medium"
										weight="400"
										textColor="neutral_900"
										className="text_no_break text_center"
									>
										para regularizar
									</Typography>
								</div>
							</div>
							<Button
								scale="small"
								color="light_grey"
								className="w_100_per"
								onClick={() => openLocation(details)}
							>
								Ver ubicación
							</Button>
							{details.canChangeStatus ? (
								<div className="pos_relative w_100_per">
									<Button
										scale="small"
										color="primary"
										className="w_100_per "
										// onClick={()=>openLocation(details)}
									>
										Cambiar Estado
									</Button>
									<select
										name="status"
										id="status"
										className="select_test"
										value={details.status}
										onChange={(e) =>
											changeStatus(
												details._id,
												e.target.value as RecoveryStatus,
											)
										}
									>
										{Object.keys(RecoveryStatusValues)
											.filter((key) => key !== RecoveryStatus.canceled)
											.map((key) => (
												<option value={key}>
													{RecoveryStatusValues[key as RecoveryStatus].title}
												</option>
											))}
									</select>
								</div>
							) : (
								<>
									<Typography
										scale="small"
										weight="400"
										className="text_center"
									>
										Define el estado de la parada anterior para continuar la
										ruta
									</Typography>
									<Button
										variant="link"
										scale="small"
										color="primary"
										onClick={() => goPrevious(details.index)}
									>
										Ir a parada anterior
									</Button>
								</>
							)}
						</div>
					</div>
				)}
			</div>
			{!view && !details && route && recoveries ? (
				<>
					<Typography
						scale="heading4"
						weight="600"
						className="text_capitalize text_center m_b_xxs"
					>
						Ruta{' '}
						{momentLocal(route.dateEstimate)
							.locale('es')
							.format('dddd, D MMMM')}
					</Typography>

					<div className=" p_y_xs flex_center_col flex_gap_xxs m_b_xs">
						<Typography
							scale="large"
							weight="600"
							textColor="primary_300"
							className=""
						>
							{totals ? totals.recovered + totals.failed : 0}/
							{recoveries?.length}{' '}
						</Typography>
						<ProgressBar
							containerClassName="bg_neutral_400 h_sm"
							fillClassName="bg_primary_300 h_sm"
							percentage={
								((totals ? totals.recovered + totals.failed : 0) * 100) /
								(recoveries?.length ?? 1)
							}
						/>
					</div>
					<Typography scale="large" weight="600" className="m_b_xxs">
						En curso (
						{totals ? totals.inSite + totals.onWay + totals.pending : 0})
					</Typography>
					<div className="bg_neutral_500 h_xxs w_100_per m_b_md" />

					<div className="flex_center_col flex_gap_xs m_b_md">
						{recoveries
							.filter(
								(recovery) =>
									recovery.status !== RecoveryStatus.canceled &&
									recovery.status !== RecoveryStatus.recovered &&
									recovery.status !== RecoveryStatus.failed,
							)
							.map((recovery) => (
								<div
									className="bg_neutral_0 br_xs p_y_sm p_x_md w_100_per"
									key={recovery._id}
									role="button"
									tabIndex={0}
									onClick={() =>
										goToVehicle(recovery._id, recovery.interestPoint)
									}
									onKeyDown={() =>
										goToVehicle(recovery._id, recovery.interestPoint)
									}
								>
									<div className="flex_center flex_justify_between m_b_xs">
										<div className="flex_center flex_gap_md">
											<div className="br_md overflow_hidden flex_shrink_0 vehicle_mobile_img">
												<img
													className="img_contain"
													src={
														recovery.repossession.vehicle?.images[0]
															? recovery.repossession.vehicle.images[0].url
															: `${process.env.REACT_APP_STORAGE_BASE_URL}public/uploads/generic-moto.png`
													}
													alt="vehicle"
												/>
											</div>
											<div className="display_flex flex_col flex_gap_xxs">
												<Typography
													scale="large"
													weight="600"
													className=""
													textColor="neutral_1000"
												>
													{recovery.repossession.vehicle?.internalId}
												</Typography>
												<Typography
													scale="small"
													weight="400"
													className=""
													textColor="neutral_700"
												>
													{recovery.repossession.vehicle?.brand?.name || '-'}{' '}
													{recovery.repossession.vehicle?.model?.name || '-'}{' '}
													{recovery.repossession.vehicle?.cylindersCapacity ||
														'-'}
												</Typography>
											</div>
										</div>
										<Typography
											scale="medium"
											weight="600"
											className={`
                                                            dso_chip_small 
                                                            bg_${
																															RecoveryStatusValues[
																																recovery?.status as keyof typeof RecoveryStatus
																															]?.color
																														}_nocontrast text_neutral_0 overflow_hidden overflow_ellipsis text_center
                                                            `}
										>
											{RecoveryStatusValues[
												recovery?.status as keyof typeof RecoveryStatus
											]?.title ?? recovery?.status}
										</Typography>
									</div>
									<div className="flex_center flex_justify_between">
										<Typography scale="medium" weight="400" className="">
											{recovery.repossession?.ozonerName}
										</Typography>
										<Typography
											scale="small"
											weight="600"
											textColor="neutral_600"
											className=""
										>
											{'Ver mas >'}
										</Typography>
									</div>
								</div>
							))}
					</div>
					<Typography scale="large" weight="600" className="m_b_xxs">
						No recuperadas ({totals ? totals.failed : 0})
					</Typography>
					<div className="bg_neutral_500 h_xxs w_100_per m_b_md" />
					<div className="flex_center_col flex_gap_xs m_b_md">
						{recoveries
							.filter((recovery) => recovery.status === RecoveryStatus.failed)
							.map((recovery) => (
								<div
									className="bg_neutral_0 br_xs p_y_sm p_x_md w_100_per"
									key={recovery._id}
									role="button"
									tabIndex={0}
									onClick={() =>
										goToVehicle(recovery._id, recovery.interestPoint)
									}
									onKeyDown={() =>
										goToVehicle(recovery._id, recovery.interestPoint)
									}
								>
									<div className="flex_center flex_justify_between m_b_xs">
										<div className="flex_center flex_gap_md">
											<div className="br_md overflow_hidden flex_shrink_0 vehicle_mobile_img">
												<img
													className="img_contain"
													src={
														recovery.repossession.vehicle?.images[0]
															? recovery.repossession.vehicle.images[0].url
															: `${process.env.REACT_APP_STORAGE_BASE_URL}public/uploads/generic-moto.png`
													}
													alt="vehicle"
												/>
											</div>
											<div className="display_flex flex_col flex_gap_xxs">
												<Typography
													scale="large"
													weight="600"
													className=""
													textColor="neutral_1000"
												>
													{recovery.repossession.vehicle?.internalId}
												</Typography>
												<Typography
													scale="small"
													weight="400"
													className=""
													textColor="neutral_700"
												>
													{recovery.repossession.vehicle?.brand?.name || '-'}{' '}
													{recovery.repossession.vehicle?.model?.name || '-'}{' '}
													{recovery.repossession.vehicle?.cylindersCapacity ||
														'-'}
												</Typography>
											</div>
										</div>
										<Typography
											scale="medium"
											weight="600"
											className={`
                                                            dso_chip_small 
                                                            bg_${
																															RecoveryStatusValues[
																																recovery?.status as keyof typeof RecoveryStatus
																															]?.color
																														}_nocontrast text_neutral_0 overflow_hidden overflow_ellipsis text_center
                                                            `}
										>
											{RecoveryStatusValues[
												recovery?.status as keyof typeof RecoveryStatus
											]?.title ?? recovery?.status}
										</Typography>
									</div>
									<div className="flex_center flex_justify_between">
										<Typography scale="medium" weight="400" className="">
											{recovery.repossession?.ozonerName}
										</Typography>
										<Typography
											scale="small"
											weight="600"
											textColor="neutral_600"
											className=""
										>
											{'Ver mas >'}
										</Typography>
									</div>
								</div>
							))}
					</div>
					<Typography scale="large" weight="600" className="m_b_xxs">
						Recuperadas ({totals ? totals.recovered : 0})
					</Typography>
					<div className="bg_neutral_500 h_xxs w_100_per m_b_md" />
					<div className="flex_center_col flex_gap_xs m_b_md">
						{recoveries
							.filter(
								(recovery) => recovery.status === RecoveryStatus.recovered,
							)
							.map((recovery) => (
								<div
									className="bg_neutral_0 br_xs p_y_sm p_x_md w_100_per"
									key={recovery._id}
									role="button"
									tabIndex={0}
									onClick={() =>
										goToVehicle(recovery._id, recovery.interestPoint)
									}
									onKeyDown={() =>
										goToVehicle(recovery._id, recovery.interestPoint)
									}
								>
									<div className="flex_center flex_justify_between m_b_xs">
										<div className="flex_center flex_gap_md">
											<div className="br_md overflow_hidden flex_shrink_0 vehicle_mobile_img">
												<img
													className="img_contain"
													src={
														recovery.repossession.vehicle?.images[0]
															? recovery.repossession.vehicle.images[0].url
															: `${process.env.REACT_APP_STORAGE_BASE_URL}public/uploads/generic-moto.png`
													}
													alt="vehicle"
												/>
											</div>
											<div className="display_flex flex_col flex_gap_xxs">
												<Typography
													scale="large"
													weight="600"
													className=""
													textColor="neutral_1000"
												>
													{recovery.repossession.vehicle?.internalId}
												</Typography>
												<Typography
													scale="small"
													weight="400"
													className=""
													textColor="neutral_700"
												>
													{recovery.repossession.vehicle?.brand?.name || '-'}{' '}
													{recovery.repossession.vehicle?.model?.name || '-'}{' '}
													{recovery.repossession.vehicle?.cylindersCapacity ||
														'-'}
												</Typography>
											</div>
										</div>
										<Typography
											scale="medium"
											weight="600"
											className={`
                                                            dso_chip_small 
                                                            bg_${
																															RecoveryStatusValues[
																																recovery?.status as keyof typeof RecoveryStatus
																															]?.color
																														}_nocontrast text_neutral_0 overflow_hidden overflow_ellipsis text_center
                                                            `}
										>
											{RecoveryStatusValues[
												recovery?.status as keyof typeof RecoveryStatus
											]?.title ?? recovery?.status}
										</Typography>
									</div>
									<div className="flex_center flex_justify_between">
										<Typography scale="medium" weight="400" className="">
											{recovery.repossession?.ozonerName}
										</Typography>
										<Typography
											scale="small"
											weight="600"
											textColor="neutral_600"
											className=""
										>
											{'Ver mas >'}
										</Typography>
									</div>
								</div>
							))}
					</div>
					{/* <ModalConfirmation
                        handleClose={() => setOpenConfirm(false)}
                        openConfirmation={openConfirm}
                        onConfirmationCallBack={() => {
                            changeStatus(confirmData);
                            setOpenConfirm(false);
                        }}
                        title={
                            <Typography
                                scale="large"
                                weight="600"
                                textColor="neutral_900"
                            >
                                ¿Moto Recuperada?
                            </Typography>
                        }
                        subtitle={
                            <Typography
                                scale="medium"
                                weight="400"
                                textColor="neutral_900"
                            >
                                Al cambiar a este estado todos los pagos
                                pendientes del crédito quedaran anulados esta
                                seguro que quiere hacer el cambio
                            </Typography>
                        }
                        copyReject="Cancelar"
                        copyConfirm="Cambiar"
                    /> */}
				</>
			) : null}
		</>
	);
};
