/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';

// icons

import {
	Typography,
	Pager,
	Select,
	Button,
	ListTable,
} from 'design_system/src';

import './devices.scss';

import { ReactComponent as Eye } from 'design_system/src/static/icons/eye.svg';
import { ReactComponent as Edit } from 'design_system/src/static/icons/edit-ui-2.svg';
import { ReactComponent as AddCircle } from 'design_system/src/static/icons/add-circle.svg';

import { getDevicesFilter, setDevicesFilter } from 'localStorage/filterStorage';
import { handleEventGoogle } from 'models/shared/helpers/googleAnalytics';
import { fetchDevices } from 'models/geozon/controllers/device.controller';
import { Device } from 'models/geozon/interfaces/device.interface';
import { momentLocal } from 'models/shared/helpers/dates';
import EditDevice from './EditDevice';

let petition: number;

const hasVehicleOptions = [
	{
		label: 'Dispositivo asignado',
		value: 'true',
	},
	{
		label: 'Dispositivo sin asignar',
		value: 'false',
	},
];

function Devices() {
	const [loading, setLoading] = useState<boolean>(false);
	const [totalPages, setTotalPages] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(1);

	const [hasVehicleFilter, setHasVehicleFilter] = useState([]);

	const [devices, setDevices] = useState<Device[]>();
	const [openDetails, setOpenDetails] = useState<boolean>(false);
	const [openDetailsDisabled, setOpenDetailsDisabled] =
		useState<boolean>(false);
	const [selectedDevice, setSelectedDevice] = useState<null | any>(null);
	const [count, setCount] = useState(0);

	useEffect(() => {
		handleEventGoogle(
			'VIEW_Panda_Devices',
			'Panda',
			'page view to the devices list view',
		);
		petition = 0;
		const information = getDevicesFilter();
		if (information.currentPage) {
			setCurrentPage(information.currentPage);
		}

		if (information.hasVehicleFilter) {
			setHasVehicleFilter(information.readFilter);
		}
	}, []);

	const requestDevices = () => {
		setLoading(true);
		const num = petition + 1;
		petition += 1;

		const filters = [];

		let hasVehicle;
		if (
			hasVehicleFilter.length &&
			hasVehicleFilter.length !== hasVehicleOptions.length
		)
			[hasVehicle] = hasVehicleFilter;

		fetchDevices({
			page: currentPage,
			limit: 30,
			hasVehicle,
		}).then((info: any) => {
			const { data } = info;
			const finalData = data.map((device: Device) => {
				const vehicle = device.vehiclesList?.filter(
					(omx: any) => !omx.endedAt,
				)[0];
				return {
					...device,
					vehicle,
				};
			});
			setLoading(false);
			setDevices(finalData);
			setTotalPages(info.pages);
			setCount(info.count);
		});
	};
	useEffect(() => {
		requestDevices();
	}, [hasVehicleFilter, currentPage]);

	useEffect(() => {
		setDevicesFilter({
			readFilter: hasVehicleFilter,
			currentPage,
		});
	}, [hasVehicleFilter, currentPage]);

	return (
		<>
			<div className="dim_100_per flex_content_col shadow_hard inner_container bg_neutral_400">
				<div className="flex_header">
					<div className="bg_neutral_0 shadow_hard p_y_md p_x_xxxl">
						<div className="display_flex flex_gap_sm flex_align_center">
							<Typography
								scale="heading3"
								weight="600"
								textColor="neutral_900"
								className="m_y_md"
							>
								Dispositivos
							</Typography>
						</div>
						<div className="display_flex flex_gap_xl">
							<Select
								fixedPlaceholder
								variant="checkbox"
								placeholder="Seleccione estado"
								options={hasVehicleOptions}
								value={hasVehicleFilter}
								onChange={(e: any) => {
									setHasVehicleFilter(e.target.value);
								}}
							/>
						</div>
					</div>
				</div>
				<div className="flex_body p_x_xxxl overflow_x_hidden p_y_md">
					{loading || !devices ? (
						<div className="p_y_xl flex_center">Cargando Dispositivos</div>
					) : (
						<ListTable
							canSelect={false}
							onRowClick={() => {}}
							data={devices}
							cols={[
								{
									label: '',
									weight: 2,
									render: (row) => (
										<div className="flex_align">
											<Eye
												className="dim_lg cursor_pointer text_primary_200"
												onClick={() => {
													setOpenDetails(true);
													setSelectedDevice(row);
													setOpenDetailsDisabled(true);
												}}
											/>
										</div>
									),
								},
								{
									label: 'Teléfono',
									weight: 4,
									render: (row) => (
										<Typography
											scale="medium"
											weight="600"
											className="text_center"
										>
											{row.phone ?? '--'}
										</Typography>
									),
								},
								{
									label: 'IMEI',
									weight: 4,
									render: (row) => (
										<Typography
											scale="medium"
											weight="400"
											className="text_center"
										>
											{row.imei.substring(9) ?? '--'}
										</Typography>
									),
								},
								{
									label: 'Operador SIM',
									weight: 4,
									render: (row) => (
										<Typography
											scale="medium"
											weight="400"
											className="text_center"
										>
											{row.simBrand ?? '--'}
										</Typography>
									),
								},
								{
									label: 'Marca',
									weight: 4,
									render: (row) => (
										<Typography
											scale="medium"
											weight="400"
											className="text_center"
										>
											{row.brand ?? '--'}
										</Typography>
									),
								},
								{
									label: 'Vehiculo',
									weight: 4,
									render: (row) => (
										<Typography
											scale="medium"
											weight="400"
											className="text_center"
										>
											{row.vehicle?.internalId ?? 'Sin asignar'}
										</Typography>
									),
								},
								{
									label: 'Fecha de creacion',
									weight: 4,
									render: (row) => (
										<Typography
											scale="medium"
											weight="400"
											className="text_center"
										>
											{row.createdAt
												? momentLocal(row.createdAt)
														.locale('es')
														.format('DD/MM/YYYY')
												: '--'}
										</Typography>
									),
								},
								{
									label: ' ',
									weight: 2,
									render: (row) => (
										<div className="flex_align">
											<Edit
												className="dim_lg cursor_pointer text_primary_200"
												onClick={() => {
													setOpenDetails(true);
													setSelectedDevice(row);
													setOpenDetailsDisabled(false);
												}}
											/>
										</div>
									),
								},
							]}
						/>
					)}
				</div>

				<div className="display_flex flex_align_center flex_justify_between flex_footer bg_neutral_0 p_x_xxxl p_y_md">
					<Button
						scale="small"
						icon={<AddCircle />}
						className="w_30_per"
						onClick={() => {
							setOpenDetails(true);
							setSelectedDevice(null);
							setOpenDetailsDisabled(false);
						}}
					>
						Añadir Dispositivo
					</Button>
					<Pager
						totalPages={totalPages}
						setPage={setCurrentPage}
						page={currentPage}
						count={count}
						className="w_fit"
					/>
				</div>
			</div>
			<EditDevice
				open={openDetails}
				setOpen={setOpenDetails}
				setResult={requestDevices}
				disabled={openDetailsDisabled}
				values={selectedDevice}
			/>
		</>
	);
}

export default Devices;
