import { DiscountType } from 'models/inventory/enums/vehicle.enum';
import { VehiclePopulated } from 'models/inventory/interfaces/vehicle.interface';

export const getVehicleCurrentPrice = (
	vehicle: VehiclePopulated,
	duration: number,
): number => {
	if (!vehicle) return 0;

	let price = vehicle.salePrices[duration]?.paymentWeek;
	const discount = vehicle.discounts?.filter((e) => e.status === 'active')[0];
	if (discount) {
		switch (discount.type) {
			case DiscountType.PERCENTAGE:
				price *= 1 - discount.value;
				break;
			case DiscountType.VALUE:
				price -= discount.value;
				break;
			default:
				break;
		}
	}
	return price ?? NaN;
};
