/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';

// icons
import { ReactComponent as AddCircle } from 'design_system/src/static/icons/add-circle.svg';
import { ReactComponent as Recipt } from 'design_system/src/static/icons/invoice.svg';
import { ReactComponent as Document } from 'design_system/src/static/icons/document.svg';
import { ReactComponent as Pin } from 'design_system/src/static/icons/pin.svg';
import { ReactComponent as Action } from 'design_system/src/static/icons/more-v.svg';
import { ReactComponent as List } from 'design_system/src/static/icons/bulleted-list-2.svg';
import { ReactComponent as Boxes } from 'design_system/src/static/icons/box_2.svg';
import { ReactComponent as Excel } from 'design_system/src/static/icons/excel.svg';
import { ReactComponent as Monitor } from 'design_system/src/static/icons/monitor.svg';
import { ReactComponent as Panda } from 'design_system/src/static/icons/panda.svg';

import motoLoading from 'static/icons/loading_moto.gif';

import { LightTooltip } from 'components/hocs/tooltip/Tooltip';
import {
	Button,
	Typography,
	Pager,
	ModalConfirmation as Modal,
	Select,
	DateSelect,
	Popup,
	ListTable,
} from 'design_system/src';

// css
import moment from 'moment';
import 'moment/locale/es';

import './ozocio.scss';

import { getOzonerFilter, setOzonerFilter } from 'localStorage/filterStorage';
import { useLocation, useNavigate } from 'react-router-dom';
import SaveFilter from 'components/hocs/personalFiltersSection/saveFilter/SaveFilter';
import { getUser } from 'localStorage/authStorage';
import {
	TOzocio,
	StatusOfferVehicle,
	StatusOfferVehicleValues,
	TOzocioOneOffer,
} from 'models/ozocio.interface';
import { FetchOzocios } from 'models/ozocios';
import { handleEventGoogle } from 'models/shared/helpers/googleAnalytics';
import { UserFilter } from 'models/ozonio/interfaces/ozonio.interface';
import {
	createUserFilter,
	deleteUserFilter,
	findOzonioById,
} from 'models/ozonio/controllers/ozonios.controller';
import { FilterSections } from 'models/ozonio/enums/ozonio.enum';
import { priceFormatFinancial } from 'models/shared/helpers/prices';
import { momentLocal } from 'models/shared/helpers/dates';
import SearchBar from '../../components/hocs/searchBar/searchBar';
import EditOzocio from './EditOzocio';

const createdOnOptions = [
	{
		label: 'Pandaboard',
		color: 'primary_200',
		value: 'pandaboard',
	},
	{
		label: 'Webpage',
		color: 'yellow_100',
		value: 'webpage',
	},
];
let petition: number;
const getStatusFilterName = (status: string) =>
	`statusPersonalFilter_${status}`;

const sortOptions = [
	{
		value: 'createdAt',
		label: 'Más antiguos',
	},
	{
		value: '-createdAt',
		label: 'Más recientes',
	},
];

function Ozoner() {
	const navigate = useNavigate();

	const [openDetails, setOpenDetails] = useState<boolean>(false);
	const [ozocios, setOzocios] = useState<TOzocioOneOffer[] | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [totalPages, setTotalPages] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [inputQuery, setInputQuery] = useState<string>('');
	const [query, setQuery] = useState<string>('');

	// -----------------FILTERS STATES------------------- //
	const [sortValue, setSortValue] = useState('-createdAt');
	const [openConfirmFilter, setOpenConfirmFilter] = useState(false);
	const [callbackConfirm, setCallbackConfirm] = useState<any>();
	const [openOptions, setOpenOptions] = useState('');
	const [personalFilters, setPersonalFilters] = useState<UserFilter[]>([]);
	const [selectedPersonalFilter, setSelectedPersonalFilter] =
		useState<string>();
	const [openSaveFilter, setOpenSaveFilter] = useState<boolean>(false);
	const [createdOnFilter, setCreatedOnFilter] = useState([]);
	const [statusFilter, setStatusFilter] = useState<string[]>([]);

	const [datesFilter, setDatesFilter] = useState({
		initial: new Date(),
		last: new Date(),
		option: -1,
	});
	const [hasDatesFilter, setHasDatesFilter] = useState(false);
	const [count, setCount] = useState(0);

	const requestFilters = () => {
		const userL = getUser();
		if (userL)
			findOzonioById(userL.userId).then((data) => {
				if (data?.personalFilters) {
					setPersonalFilters(
						data.personalFilters.filter(
							(fil: UserFilter) => fil.section === FilterSections.ozociosList,
						),
					);
				}
			});
	};
	const changePersonalFilter = (uFilter: UserFilter) => {
		if (uFilter._id !== selectedPersonalFilter) {
			setSelectedPersonalFilter(uFilter._id);
			setCreatedOnFilter(uFilter.values.createdOnFilter ?? []);
			setStatusFilter(uFilter.values.statusFilter ?? []);
			if (uFilter.values.datesFilter) {
				setDatesFilter({
					...uFilter.values.datesFilter,
					initial: new Date(uFilter.values.datesFilter.initial),
					last: new Date(uFilter.values.datesFilter.last),
				});
				setHasDatesFilter(true);
			} else {
				setHasDatesFilter(false);
			}
		}
	};

	const setStatusPersonalFilter = (value: string) => {
		const name = getStatusFilterName(value);
		setSelectedPersonalFilter(name);
		setCreatedOnFilter([]);
		setHasDatesFilter(false);
		setStatusFilter([value]);
	};

	const deletePersonalFilter = (uFilter: UserFilter) => {
		const userL = getUser();

		if (userL)
			deleteUserFilter(userL.userId, uFilter._id).then(() => {
				setSelectedPersonalFilter('');
				requestFilters();
			});
	};

	const confirmChangeFilter = () => {
		const { type, value } = callbackConfirm;
		if (type) {
			setStatusPersonalFilter(value);
		} else {
			changePersonalFilter(value);
		}
	};

	const changingFilter = (type: boolean, value: any) => {
		if (
			selectedPersonalFilter ||
			(!createdOnFilter.length && !statusFilter.length && !hasDatesFilter)
		) {
			if (type) {
				setStatusPersonalFilter(value);
			} else {
				changePersonalFilter(value);
			}
		} else {
			setCallbackConfirm({ type, value });
			setOpenConfirmFilter(true);
		}
	};

	const saveFilter = (name: string) => {
		const userL = getUser();
		if (userL)
			return createUserFilter(userL.userId, name, FilterSections.ozociosList, {
				statusFilter,
				createdOnFilter,
				datesFilter: hasDatesFilter ? datesFilter : undefined,
			}).then((e: any) => {
				requestFilters();
				setSelectedPersonalFilter(
					e.personalFilters[e.personalFilters.length - 1]._id,
				);
				return e;
			});
		return Promise.resolve();
	};

	useEffect(() => {
		handleEventGoogle(
			'VIEW_Panda_ozocios',
			'Panda',
			'page view to the ozocios list view',
		);
		petition = 0;
		const information = getOzonerFilter();
		if (information.query) {
			setQuery(information.query);
			setInputQuery(information.query);
		}
		if (information.currentPage) {
			setCurrentPage(information.currentPage);
		}
		if (information.selectedPersonalFilter) {
			setSelectedPersonalFilter(information.selectedPersonalFilter);
		}
		if (information.createdOnFilter) {
			setCreatedOnFilter(information.createdOnFilter);
		}
		if (information?.datesFilter) {
			setDatesFilter({
				...information.datesFilter,
				initial: new Date(information.datesFilter.initial),
				last: new Date(information.datesFilter.last),
			});

			setHasDatesFilter(true);
		}
		if (information.statusFilter) {
			setStatusFilter(information.statusFilter);
		} else {
			setStatusFilter([]);
		}
		requestFilters();
	}, []);

	const requestOzocios = () => {
		setLoading(true);
		const num = petition + 1;
		petition += 1;

		const filters = [];

		if (
			createdOnFilter.length &&
			createdOnFilter.length !== createdOnOptions.length
		)
			filters.push(`createdOn=${createdOnFilter}`);
		if (
			statusFilter.length &&
			statusFilter.length !== Object.keys(StatusOfferVehicleValues).length
		)
			filters.push(`status=${statusFilter}`);

		if (datesFilter && hasDatesFilter) {
			filters.push(
				`minCreatedAt=${moment
					.utc(datesFilter.initial)
					.format('YYYY-MM-DD')}&maxCreatedAt=${moment
					.utc(datesFilter.last)
					.format('YYYY-MM-DD')}`,
			);
		}
		if (sortValue && sortValue !== '') {
			filters.push(`sort=${sortValue}`);
		}

		FetchOzocios(
			currentPage,
			query,
			undefined,
			undefined,
			undefined,
			filters.join('&'),
		)
			.then((res) => {
				if (res && num === petition) {
					setLoading(false);
					setOzocios(res.data);
					setCount(res.count);
					setTotalPages(res.pages);
					if (res.pages < currentPage) {
						setCurrentPage(1);
					}
				}
			})
			.catch(() => {
				setLoading(false);
				setOzocios([]);
			});
	};
	useEffect(() => {
		if (currentPage === 1) {
			requestOzocios();
		} else {
			setCurrentPage(1);
		}
	}, [
		statusFilter,
		query,
		createdOnFilter,
		datesFilter,
		hasDatesFilter,
		sortValue,
	]);
	useEffect(() => {
		setOzocios([]);
		requestOzocios();
	}, [currentPage]);

	useEffect(() => {
		setOzonerFilter({
			statusFilter,
			query,
			currentPage,
			createdOnFilter,
			datesFilter: hasDatesFilter ? datesFilter : null,
			selectedPersonalFilter,
		});
	}, [
		statusFilter,
		query,
		currentPage,
		createdOnFilter,
		datesFilter,
		hasDatesFilter,
		selectedPersonalFilter,
	]);

	const handleDetailsOzo = (id: string, offerId: string) => {
		navigate(`/ozocio/${id}?offerId=${offerId}`);
	};

	const handleSearch = (event: any) => {
		event.preventDefault();
		setQuery(inputQuery);
	};

	return (
		<div className="dim_100_per flex_content_col shadow_hard inner_container bg_neutral_400">
			<div className="flex_header">
				<div className="bg_neutral_0 shadow_hard p_t_xs p_b_md p_x_lg">
					<div className="display_flex flex_gap_sm flex_align_center">
						<Typography scale="heading3" weight="600" textColor="neutral_900">
							Ofertas de Ozocios
						</Typography>
						<SearchBar
							className="flex_grow_1"
							handleSearch={handleSearch}
							query={inputQuery}
							setQuery={setInputQuery}
							sortOptions={sortOptions}
							sortValue={sortValue}
							setsortValue={setSortValue}
						/>
					</div>
					<div className="display_flex flex_gap_xl m_b_xs">
						<div className="display_flex flex_gap_xl flex_grow_1">
							<Select
								fixedPlaceholder
								variant="checkbox"
								placeholder="Estado"
								options={Object.keys(StatusOfferVehicleValues).map(
									(key: any) => ({
										label:
											StatusOfferVehicleValues[key as StatusOfferVehicle].title,
										value: key,
									}),
								)}
								value={statusFilter}
								onChange={(e: any) => {
									setStatusFilter(e.target.value);
									setSelectedPersonalFilter('');
								}}
							/>
							<Select
								fixedPlaceholder
								variant="checkbox"
								placeholder="Origen"
								options={createdOnOptions}
								value={createdOnFilter}
								onChange={(e: any) => {
									setCreatedOnFilter(e.target.value);
									setSelectedPersonalFilter('');
								}}
							/>
							<DateSelect
								value={datesFilter}
								hasValue={hasDatesFilter}
								setHasValue={setHasDatesFilter}
								placeholder="Fecha de creación"
								setValue={(e: any) => {
									setDatesFilter(e);
									setHasDatesFilter(true);
									setSelectedPersonalFilter('');
								}}
							/>
						</div>
						<div className="display_flex flex_gap_xs flex_grow_0 flex_align_center">
							<Button
								variant="ghost"
								scale="small"
								icon={<Pin />}
								onClick={() => setOpenSaveFilter(true)}
							>
								Fijar filtro
							</Button>
						</div>
					</div>
					<div className="display_flex flex_gap_xl">
						{personalFilters.map((uFilter) => (
							<div
								className={`display_flex flex_gap_sm p_x_md p_y_xs status_tab pos_relative ${
									selectedPersonalFilter === uFilter._id ? 'active' : ''
								}
              `}
							>
								<Typography
									scale="small"
									weight={
										selectedPersonalFilter === uFilter._id ? '600' : '400'
									}
									onClick={() => changingFilter(false, uFilter)}
									key={`status-filter-${uFilter._id}`}
									className="cursor_pointer "
								>
									{uFilter.name}
								</Typography>
								<Action
									className="dim_md cursor_pointer text_neutral_600"
									onClick={() => setOpenOptions(uFilter._id)}
								/>
								<Popup
									open={openOptions === uFilter._id}
									setOpen={() => setOpenOptions('')}
									placementX="right-inset"
									className="bg_neutral_100 br_xxs m_r_md"
								>
									<Typography
										scale="xsmall"
										weight="400"
										className="p_xs border_b_solid border_b_1 border_neutral_400 cursor_pointer menu_selectable"
										onClick={() => deletePersonalFilter(uFilter)}
										onKeyPress={() => deletePersonalFilter(uFilter)}
										tabIndex={0}
										role="button"
									>
										Eliminar filtro
									</Typography>
								</Popup>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className="flex_body p_x_lg overflow_x_hidden">
				{loading ? (
					<div className="flex_center_col dim_100_per">
						<Typography
							weight="600"
							scale="heading4"
							textColor="neutral_800"
							className="m_b_xs"
						>
							Cargando los ozocios...
						</Typography>
						<img src={motoLoading} alt="" className="h_xxxl" />
					</div>
				) : ozocios?.length ? (
					<ListTable
						data={ozocios as any[]}
						canSelect={false}
						rowClassName="br_none"
						hideHeader
						className="m_t_md"
						onRowClick={(ozocio) =>
							handleDetailsOzo(ozocio._id, ozocio.OfferVehicles._id)
						}
						cols={[
							{
								label: 'Nombre',
								weight: 2,
								render: (row: any) => (
									// eslint-disable-next-line no-nested-ternary
									<div className="display_flex flex_align_center flex_gap_md w_100_per">
										{row?.createdOn === 'pandaboard' ? (
											<Panda className="dim_md text_primary_300" />
										) : (
											<Monitor className="dim_md text_primary_300" />
										)}
										<div className="flex_grow_1">
											<Typography
												scale="medium"
												weight="400"
												className="flex_1 text_no_break"
											>
												{row?.name || '-'}
											</Typography>
											<Typography
												scale="xsmall"
												weight="400"
												className="flex_1"
											>
												{row?.phone || '-'}
											</Typography>
										</div>
									</div>
								),
							},
							{
								label: 'Offer',
								render: (row: any, index: number) => (
									<Typography
										scale="medium"
										weight="600"
										className="text_no_break"
									>
										{row.OfferVehicles.brand ?? ''}{' '}
										{row.OfferVehicles.model ?? ''}{' '}
										{row.OfferVehicles.cylinderCapacity ?? ''}{' '}
										{row.OfferVehicles.year ?? ''}{' '}
									</Typography>
								),
							},
							{
								label: 'price_Offer',
								render: (row: any, index: number) => (
									<div className="display_flex flex_align_center flex_col w_100_per">
										<Typography
											scale="medium"
											weight="600"
											className="text_no_break"
										>
											Oferta:{' '}
											{priceFormatFinancial(row.OfferVehicles.amountOffer)}
										</Typography>
										<Typography
											scale="small"
											weight="600"
											textColor="neutral_600"
											className="text_no_break"
										>
											Fecha:{' '}
											{row.OfferVehicles.createdAt
												? momentLocal(row.OfferVehicles.createdAt).format(
														'DD/MM/YY',
												  )
												: 'No registra'}
										</Typography>
									</div>
								),
							},
							{
								label: 'Estado',
								render: (row: any) => (
									<LightTooltip
										title={
											(StatusOfferVehicleValues as any)[
												row.OfferVehicles.status
											]?.tooltip ?? 'Sin informacion'
										}
									>
										<div>
											<Typography
												weight="400"
												scale="medium"
												textColor="neutral_700"
												className="text_center flex_grow_1 flex_basis_0"
											>
												<div
													className={`
                                                  dso_chip_small flex_grow_1
                                                  bg_${
																										(
																											StatusOfferVehicleValues as any
																										)[row.OfferVehicles.status]
																											?.color ?? 'neutral_600'
																									}_nocontrast
                                                  text_neutral_0 flex_basis_0 overflow_hidden overflow_ellipsis text_center
                                                `}
												>
													{(StatusOfferVehicleValues as any)[
														row.OfferVehicles.status
													]?.title ?? 'No registrado'}
												</div>
											</Typography>
										</div>
									</LightTooltip>
								),
							},
						]}
					/>
				) : (
					<div className="flex_center dim_100_per">
						<Typography
							scale="heading4"
							weight="600"
							className="text_neutral_900 m_xl text_center"
						>
							Tus filtros no generaron resultados
						</Typography>
					</div>
				)}
			</div>

			<div className="display_flex flex_align_center flex_justify_between flex_footer bg_neutral_0 p_x_xxxl p_y_md">
				<Button
					scale="small"
					icon={<AddCircle />}
					className="w_30_per"
					onClick={() => setOpenDetails(true)}
				>
					Añadir Oferta
				</Button>
				<Pager
					totalPages={totalPages}
					setPage={setCurrentPage}
					page={currentPage}
					count={count}
					className="w_fit"
				/>
			</div>
			<EditOzocio
				open={openDetails}
				setOpen={setOpenDetails}
				setResult={() => {
					setOzocios([]);
					requestOzocios();
				}}
			/>
			{(openSaveFilter || openConfirmFilter) && (
				<SaveFilter
					open={openSaveFilter}
					setOpen={setOpenSaveFilter}
					callback={saveFilter}
					openConfirm={openConfirmFilter}
					setOpenConfirm={setOpenConfirmFilter}
					callbackConfirm={confirmChangeFilter}
				/>
			)}
		</div>
	);
}

export default Ozoner;
