import React, { useContext, useState } from 'react';
import {
	Button,
	ModalConfirmation,
	Select,
	Typography,
} from 'design_system/src';
import { ReactComponent as Reject } from 'design_system/src/static/icons/delete-folder.svg';
import { toasterNotification } from 'models/shared/helpers/toasterNotification';
import { toasterOptions } from 'models/shared/enums/toaster.enum';
import {
	LoanDevolutionReasons,
	LoanDevolutionReasonsValues,
	LoanStatus,
} from 'models/loan/enums/loan.enum';
import { updateLoan } from 'models/loan/controllers/loan.controller';
import { commonErrorHandler } from 'models/shared/helpers/errors';
import { ozonerContext } from '../OzonerContext';

export default function DevolutionLoan() {
	const { financialForm, initializeContext } = useContext(ozonerContext);

	const [loading, setLoading] = useState(false);
	const [openCancelReason, setOpenCancelReason] = useState(false);
	const [devolutionReason, setDevolutionReason] =
		useState<LoanDevolutionReasons>();

	const closeCancelReason = () => {
		setOpenCancelReason(false);
		setDevolutionReason(undefined);
	};

	const loanDevolution = () => {
		if (devolutionReason && financialForm?.loan?._id) {
			setLoading(true);
			updateLoan(financialForm.loan._id, {
				devolutionReason,
				status: LoanStatus.devolution,
			})
				.then(() => {
					toasterNotification({
						msg: 'Credito marcado como devolucion',
						style: 'light',
						toasterType: toasterOptions.success,
					});
					setLoading(false);
					initializeContext();
				})
				.catch((e) => {
					setLoading(false);
					commonErrorHandler(e);
				});
		}
	};

	return (
		<>
			<div
				id="actions-soli-ozoner"
				className="p_md border_b_solid border_1 border_neutral_400 display_flex flex_col flex_gap_xs"
			>
				<Button
					scale="small"
					color="neutral"
					onClick={() => setOpenCancelReason(true)}
				>
					Devolucion de moto
				</Button>
			</div>
			{openCancelReason && (
				<ModalConfirmation
					openConfirmation={openCancelReason}
					handleClose={closeCancelReason}
					icon={<Reject className="text_primary_300" />}
					widthDesktop={40}
					heightDesktop={80}
					isConfirmationLoading={false}
					actions={[
						{
							label: 'Volver',
							color: 'grey',
							action: closeCancelReason,
						},
						{
							label: 'Confirmar devolucion',
							color: 'primary',
							action: () => loanDevolution(),
							disabled: !devolutionReason || loading,
						},
					]}
				>
					<>
						<Typography scale="large" weight="600" className="m_b_xs">
							Deseas marcar la devolucion de esta moto
						</Typography>
						<Typography
							scale="medium"
							weight="400"
							className="m_b_md"
							textColor="neutral_700"
						>
							Esta accion generara la anulacion de todas las cuotas sin mora que
							tuviera pendientes el ozoner y marcara la moto como reingreso
						</Typography>

						<Typography scale="medium" weight="400" className="m_b_md">
							Seleccione el motivo de devolucion de la moto:
						</Typography>
						<Select
							value={devolutionReason}
							onChange={(e: any) => setDevolutionReason(e.target.value)}
							className="w_100_per"
							options={Object.entries(LoanDevolutionReasonsValues).map(
								([key, value]) => ({
									value: key,
									label: value,
								}),
							)}
						/>
					</>
				</ModalConfirmation>
			)}
		</>
	);
}
