/* eslint-disable no-plusplus */
import './LoanPaymentsTable.scss';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Typography, ListTable } from 'design_system/src';
import { ReactComponent as Up } from 'design_system/src/static/icons/up-md.svg';
import { ReactComponent as Down } from 'design_system/src/static/icons/down-md.svg';
import { LoanWithExtraFields } from 'models/loan/interfaces/loan.interface';
import { OrderWithPayments } from 'models/loan/interfaces/order.interface';
import { OrderStatusValues } from 'models/loan/enums/order.enum';
import { priceFormatFinancial } from 'models/shared/helpers/prices';
import { momentLocal } from 'models/shared/helpers/dates';
import { PaymentStatusValues } from 'models/loan/enums/payment.enum';
import { LightTooltip } from '../tooltip/Tooltip';

interface Props {
	loan: LoanWithExtraFields;
	loading: boolean;
}

const LoanPaymentsTable: React.FC<Props> = ({ loan, loading }) => {
	const [orders, setOrders] = useState<OrderWithPayments[]>([]);
	const [openOrder, setOpenOrder] = useState(-1);

	useEffect(() => {
		const key = loan.vehicle.salePrices
			? Object.keys(loan.vehicle.salePrices).find(
					(time: string) =>
						loan.vehicle.salePrices![time].weeks === loan.duration,
			  )
			: null;
		const percentageIncrease = key
			? loan.vehicle.salePrices![key].percentageIncrease
			: 0;
		const ordersList = loan.orders
			.sort((objA, objB) => objA.quota - objB.quota)
			.map((order) => {
				const payments = loan.payments.filter(
					(payment) => payment.quota === order.quota,
				);
				const interest =
					((order.amount - (order.quota === 1 ? loan.deposit : 0)) *
						percentageIncrease) /
					100;
				const capital = order.amount - interest;
				const total = order.amount + order.toLateInterest - order.discount;
				return { ...order, capital, interest, total, payments };
			});
		setOrders(ordersList);
	}, [loan]);

	const renderChild = useCallback(
		(order: OrderWithPayments, index: number) =>
			index === openOrder ? (
				<div className="bg_neutral_0 shadow_soft">
					<div className="display_flex bg_neutral_400 m_t_xxs p_y_sm">
						<Typography
							scale="small"
							weight="400"
							className="flex_grow_1 flex_basis_0 text_center"
						>
							Fecha
						</Typography>
						<Typography
							scale="small"
							weight="400"
							className="flex_grow_1 flex_basis_0 text_center"
						>
							Acción
						</Typography>
						<Typography
							scale="small"
							weight="400"
							className="flex_grow_1 flex_basis_0 text_center"
						>
							Monto Pagado
						</Typography>
					</div>
					{order.payments?.length ? (
						order.payments.map((payment) => (
							<div className="display_flex p_y_sm">
								<Typography
									scale="small"
									weight="600"
									className="flex_grow_1 flex_basis_0 text_center"
								>
									{momentLocal(payment.paidDate).format('DD/MM/YYYY')}
								</Typography>
								<div className="table_divider flex_grow_0 h_lg" />
								<Typography
									scale="small"
									weight="600"
									className="flex_grow_1 flex_basis_0 text_center"
								>
									{PaymentStatusValues[payment.status]}
								</Typography>
								<div className="table_divider flex_grow_0 h_lg" />
								<Typography
									scale="small"
									weight="600"
									className="flex_grow_1 flex_basis_0 text_center"
								>
									{priceFormatFinancial(payment.paidAmount)}
								</Typography>
							</div>
						))
					) : (
						<Typography
							scale="small"
							weight="600"
							className="flex_grow_1 text_center p_y_xs"
						>
							No hay pagos para esta orden
						</Typography>
					)}
				</div>
			) : (
				<div />
			),
		[openOrder],
	);

	return (
		<ListTable
			data={orders}
			canSelect={false}
			dividers
			headerClassName="bg_transparent text_xsmall_600"
			rowClassName="table_row_custom border_1 border_solid border_neutral_400"
			cols={[
				{
					label: 'Cuota',
					render: (order: OrderWithPayments) => (
						<div className="flex_center_col">
							<Typography scale="small" weight="600">
								{order.quota ?? '-'}
							</Typography>
						</div>
					),
				},
				{
					label: 'F. Vencimiento',
					render: (order: OrderWithPayments) => (
						<div className="flex_center_col">
							<Typography scale="small" weight="600">
								{momentLocal(order.dueDate).format('DD/MM/YYYY')}
							</Typography>
						</div>
					),
				},
				{
					label: 'F. Pago',
					render: (order: OrderWithPayments) => (
						<div className="flex_center_col">
							<Typography scale="small" weight="600">
								{order?.payments[0]?.paidDate
									? momentLocal(order.payments[0].paidDate).format('DD/MM/YYYY')
									: '--'}
							</Typography>
						</div>
					),
				},
				{
					label: 'Monto',
					render: (order: OrderWithPayments) => (
						<div className="flex_center_col">
							<Typography scale="small" weight="600" textColor="primary_300">
								{priceFormatFinancial(order.amount)}
							</Typography>
						</div>
					),
				},
				{
					label: 'Mora',
					render: (order: OrderWithPayments) => (
						<Typography
							weight="400"
							scale="small"
							textColor={order.toLateInterest ? 'red_300' : 'neutral_700'}
							className="text_center flex_grow_1 flex_basis_0"
						>
							{priceFormatFinancial(order.toLateInterest ?? 0)}
						</Typography>
					),
				},
				{
					label: 'Descuento',
					render: (order: OrderWithPayments) => (
						<Typography
							weight="400"
							scale="small"
							textColor={order.discount ? 'green_300' : 'neutral_700'}
							className="text_center flex_grow_1 flex_basis_0"
						>
							{priceFormatFinancial(order.discount ?? 0)}
						</Typography>
					),
				},
				{
					label: 'Total',
					render: (order: OrderWithPayments) => (
						<div className="flex_center_col">
							<Typography scale="small" weight="600" textColor="primary_300">
								{priceFormatFinancial(order.total)}
							</Typography>
						</div>
					),
				},
				{
					label: 'Estado',
					render: (order: OrderWithPayments) => (
						// TODO: Mostrar cuando las ordenes estan vencidas o se pagaron con mora
						<Typography
							weight="400"
							scale="small"
							textColor="neutral_700"
							className="text_center flex_grow_1 flex_basis_0"
						>
							<div
								className={`dso_chip_small w_fit center_x cardStatus bg_${
									OrderStatusValues[
										order.status as keyof typeof OrderStatusValues
									]?.color
								}_nocontrast text_neutral_0`}
							>
								{
									OrderStatusValues[
										order.status as keyof typeof OrderStatusValues
									]?.title
								}
							</div>
						</Typography>
					),
				},
				{
					label: 'Acciones',
					render: (order: OrderWithPayments, i: number) =>
						order.payments.length ? (
							<div className="flex_center flex_gap_md">
								<LightTooltip title="Detalle de los pagos">
									<div className="">
										{openOrder === i ? (
											<Up
												className="text_neutral_1000 dim_lg"
												onClick={() => setOpenOrder(-1)}
											/>
										) : (
											<Down
												className="text_neutral_1000 dim_lg"
												onClick={() => setOpenOrder(i)}
											/>
										)}
									</div>
								</LightTooltip>
							</div>
						) : (
							<div />
						),
				},
			]}
			child={renderChild}
		/>
	);
};

export default LoanPaymentsTable;
