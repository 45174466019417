/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from 'react';

import { Button, ModalConfirmation, Typography } from 'design_system/src';

import '../../../ozoner.scss';
import { ReactComponent as Check } from 'design_system/src/static/icons/checkmark.svg';

import { EvidenceDocumentType } from 'models/shared/types/documents';
import { priceFormatFinancial } from 'models/shared/helpers/prices';
import { validationItem } from 'components/hocs/messages/messages';
import { toasterNotification } from 'models/shared/helpers/toasterNotification';
import { toasterOptions } from 'models/shared/enums/toaster.enum';
import { commonErrorHandler } from 'models/shared/helpers/errors';
import { updateFullPaymentFinancialForm } from 'models/financial-form/controllers/financial-form.controller';
import { FormStatus } from 'models/financial-form/enums/financial-form.enums';
import { ozonerContext } from '../../OzonerContext';

function FullPaymentSection() {
	const {
		financialForm,
		vehicle,
		handleDetailsVehicle,
		initializeContext,
		setTab,
	} = useContext(ozonerContext);

	const [hasContract, setHasContract] = useState(false);
	const [hasDelivery, setHasDelivery] = useState(false);
	const [hasDeposit, setHasDeposit] = useState(false);
	const [openActivate, setOpenActivate] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setHasContract(false);
		setHasDelivery(false);
		setHasDeposit(false);
		financialForm?.form?.documents?.forEach((doc) => {
			if (doc.typeEvidence === EvidenceDocumentType.contract) {
				setHasContract(true);
			}
			if (doc.typeEvidence === EvidenceDocumentType.delivery_photo) {
				setHasDelivery(true);
			}
			if (doc.typeEvidence === EvidenceDocumentType.deposit) {
				setHasDeposit(true);
			}
		});
	}, [financialForm]);

	const activateContract = () => {
		setLoading(true);
		updateFullPaymentFinancialForm(financialForm.form._id, {
			status: FormStatus.completed,
		})
			.then(() => {
				toasterNotification({
					msg: 'Credito activado correctamente',
					style: 'light',
					toasterType: toasterOptions.success,
				});
				initializeContext();
				setOpenActivate(false);
				setLoading(false);
				setTab(4);
			})
			.catch((err) => {
				setLoading(false);
				commonErrorHandler(err);
			});
	};

	return (
		<>
			<div
				id="validate-soli-ozoner"
				className="p_md border_b_solid border_1 border_neutral_400 display_flex flex_col"
			>
				<Typography scale="medium" weight="400" className="m_b_xs">
					Activacion credito de contado
				</Typography>

				{vehicle ? (
					<>
						<div className="display_flex">
							<div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
								<Typography
									scale="xxsmall"
									weight="400"
									textColor="neutral_600"
									className="text_no_break text_center m_b_xxs"
								>
									Total credito
								</Typography>
								<Typography
									scale="small"
									weight="600"
									textColor="green_300"
									className="text_no_break text_center"
								>
									{vehicle.salePrices?.[1]?.loanAmount
										? priceFormatFinancial(vehicle.salePrices?.[1]?.loanAmount)
										: '--'}
								</Typography>
							</div>
						</div>
						<div className="display_flex flex_gap_md flex_align_center border_solid border_1 border_neutral_400 p_xs">
							<div className="br_xs overflow_hidden foto_moto">
								<img
									src={
										vehicle.images[0]
											? vehicle.images[0].url
											: `${process.env.REACT_APP_STORAGE_BASE_URL}public/uploads/generic-moto.png`
									}
									alt="moto detail"
								/>
							</div>
							<div className="flex_grow_1 flex_center_col">
								<Typography
									scale="small"
									weight="600"
									className="inventory_link cursor_pointer"
									onClick={(e) => handleDetailsVehicle(e, vehicle.internalId)}
								>
									{vehicle.internalId}
								</Typography>

								<Typography scale="xxsmall" weight="400" className="m_b_xxs">
									{vehicle.brand && vehicle.model
										? `${vehicle.brand.name} ${vehicle.model.name} ${
												vehicle.suffix ?? ''
										  }`
										: 'no definido'}
								</Typography>
							</div>
						</div>
					</>
				) : null}

				<div
					id="validate-soli-ozoner"
					className="p_md border_b_solid border_1 border_neutral_400 display_flex flex_col flex_gap_md"
				>
					<div className="display_flex flex_gap_xs flex_align_center">
						{validationItem(hasDeposit, 'Pago adjunto', 'Pago sin subir')}
					</div>
					<div className="display_flex flex_gap_xs flex_align_center">
						{validationItem(
							hasContract,
							'Contrato adjunto',
							'Contrato sin subir',
						)}
					</div>
					<div className="display_flex flex_gap_xs flex_align_center">
						{validationItem(
							hasDelivery,
							'Foto entrega adjunto',
							'Foto entrega sin subir',
						)}
					</div>
					<Button
						scale="small"
						color="primary"
						disabled={!hasDelivery || !hasDeposit || !hasContract}
						onClick={() => setOpenActivate(true)}
					>
						Entregar Moto
					</Button>
				</div>
			</div>

			{openActivate && (
				<ModalConfirmation
					openConfirmation={openActivate}
					handleClose={() => setOpenActivate(false)}
					icon={<Check className="text_green_300" />}
					widthDesktop={40}
					isConfirmationLoading={false}
					actions={[
						{
							label: 'Volver',
							color: 'grey',
							action: () => setOpenActivate(false),
						},
						{
							label: 'Activar credito',
							color: 'primary',
							action: activateContract,
							disabled: loading,
						},
					]}
					title={
						<Typography scale="large" weight="400">
							¿Estás seguro de que{' '}
							<Typography scale="large" weight="600" component="span">
								deseas activar este credito?
							</Typography>
						</Typography>
					}
					subtitle={
						<Typography scale="medium" weight="400" textColor="neutral_700">
							Esta accion se debe realizar una vez se haya entregado la moto, se
							hayan firmado todos los documentos pertinentes y se haya realizado
							el pago total de la moto
						</Typography>
					}
				/>
			)}
		</>
	);
}
export default FullPaymentSection;
