import {
	BrakeType,
	VehicleColor,
	VehicleStatus,
} from 'models/inventory/enums/vehicle.enum';

import {
	UpdateVehicleDto,
	CreateVehicleDto,
} from 'models/inventory/interfaces/vehicle.interface';
import * as yup from 'yup';

export const vehicleTypes = [
	{
		label: 'Deportiva',
		shortDescription:
			'Es una motocicleta deportiva de alto rendimiento con un diseño aerodinámico y elegante.',
		description:
			'Es una motocicleta deportiva diseñada para ofrecer un alto rendimiento y una experiencia de conducción emocionante. Con un motor potente y un chasis ligero, esta moto está construida para la velocidad y la agilidad en carreteras y circuitos. El diseño aerodinámico y elegante también la convierte en una moto impresionante que no pasará desapercibida en la carretera.',
	},
	{
		label: 'Aventurera',
		shortDescription:
			'Es una moto de aventura versátil que combina comodidad y capacidad todoterreno.',
		description:
			'Es una motocicleta de aventura diseñada para brindar una experiencia de conducción cómoda y versátil, tanto en la carretera como fuera de ella. Con una posición de conducción erguida y una suspensión de largo recorrido, esta moto es ideal para viajes largos y aventuras todoterreno. También cuenta con una potencia suficiente para ofrecer una experiencia de conducción emocionante y un diseño robusto que la hace perfecta para los caminos más difíciles.',
	},
	{
		label: 'Clásica',
		shortDescription:
			' Es una motocicleta clásica con un diseño atemporal y elegante.',
		description:
			'Es una motocicleta clásica que combina la elegancia atemporal del diseño vintage con la tecnología moderna de hoy en día. Con un motor potente y un chasis de alta calidad, ofrece una experiencia de conducción suave y emocionante. Además, el diseño elegante y atemporal de esta moto la convierte en una obra de arte en movimiento que sin duda llamará la atención en la carretera.',
	},
	{
		label: 'Crucero',
		shortDescription:
			'Es una motocicleta de crucero de estilo americano con un motor potente y un diseño clásico.',
		description:
			'Es una motocicleta de crucero de estilo americano diseñada para ofrecer una experiencia de conducción cómoda y emocionante en carreteras largas. Con un motor potente y un diseño clásico, esta moto es ideal para aquellos que buscan un estilo de vida relajado y con estilo. Además, cuenta con una gran cantidad de características de confort, incluyendo un asiento cómodo y un sistema de sonido de alta calidad.',
	},
	{
		label: 'Naked',
		shortDescription:
			'Es una motocicleta de estilo naked con un diseño agresivo y minimalista.',
		description:
			'Es una motocicleta de estilo naked diseñada para aquellos que buscan una experiencia de conducción emocionante y minimalista. Con un diseño agresivo y minimalista, esta moto cuenta con una potencia impresionante y una maniobrabilidad excepcional, lo que la hace ideal para la conducción en ciudad y carreteras sinuosas. Además, cuenta con características modernas como un panel de instrumentos modernos.',
	},
];

const vehicleValidations = {
	internalId: yup.string().required('Campo requerido'),
	suffix: yup.string().nullable(),
	cylindersCapacity: yup.number().required('Campo requerido'),
	year: yup
		.number()
		.required('Campo requerido')
		.min(0, 'Debe ser un numero positivo'),
	color: yup.string().required('Campo requerido'),
	secondaryColor: yup.string().nullable(),
	plate: yup.string().nullable(),
	shortDescription: yup.string().required('Campo requerido'),
	description: yup.string().required('Campo requerido'),
	brakeType: yup.string().required('Campo requerido'),
	vehicleSN: yup.string().required('Campo requerido'),
	engineSN: yup.string().nullable(),
	registrationCard: yup.string().nullable(),
	isPresale: yup.boolean(),
	isNewVehicle: yup.boolean(),
	maintenanceDays: yup
		.number()
		.required('Campo requerido')
		.min(0, 'Debe ser un numero mayor a 0'),
	isInternalFraud: yup.boolean(),
	isCorralon: yup.boolean(),
	purchaseDate: yup.date().required('Campo requerido'),
	purchaseCost: yup
		.number()
		.required('Campo requerido')
		.min(0, 'Debe ser un numero positivo'),
	mileage: yup.number().min(0, 'Debe ser un numero positivo').nullable(),
	marketPrice: yup.number().min(0, 'Debe ser un numero positivo'),
	extraCosts: yup.object().shape({
		basicExtraCost: yup.number().min(0, 'Debe ser un numero positivo'),
		gpsMonthlySoftwareCost: yup
			.number()
			.required('Campo requerido')
			.min(0, 'Debe ser un numero positivo'),
		gpsHardwareCost: yup
			.number()
			.required('Campo requerido')
			.min(0, 'Debe ser un numero positivo'),
		repairsCost: yup.number().min(0, 'Debe ser un numero positivo'),
		debtsCost: yup.number().min(0, 'Debe ser un numero positivo'),
	}),
	location: yup.object().shape({
		hub: yup.string().required('Campo requerido'),
	}),
	brand: yup.string().required('Campo requerido'),
	model: yup.string().required('Campo requerido'),
	hasTileDevice: yup.boolean(),
	hasGpsTracker: yup.boolean(),
	visualStatus: yup.string().required('Campo requerido'),
	documentalStatus: yup.string().required('Campo requerido'),
	gpsNoAssignReason: yup.string().nullable(),
	salesRanking: yup
		.number()
		.required('Campo requerido')
		.min(0, 'Debe ser un numero igual o mayor a 0'),
};

export const vehicleValidationSchema = yup.object(vehicleValidations);

export const vehicleDefaults: UpdateVehicleDto | CreateVehicleDto = {
	status: VehicleStatus.DRAFT,
	internalId: undefined,
	suffix: undefined,
	cylindersCapacity: undefined,
	year: undefined,
	mileage: undefined,
	color: undefined as unknown as VehicleColor,
	secondaryColor: undefined as unknown as VehicleColor,
	plate: undefined,
	shortDescription: undefined,
	description: undefined,
	brakeType: undefined as unknown as BrakeType,
	vehicleSN: undefined,
	engineSN: undefined,
	registrationCard: undefined,
	isPresale: undefined,
	isNewVehicle: undefined,
	maintenanceDays: undefined,
	isInternalFraud: undefined,
	isCorralon: undefined,
	purchaseCost: undefined,
	purchaseDate: new Date(),
	marketPrice: undefined,
	extraCosts: {
		basicExtraCost: undefined,
		gpsMonthlySoftwareCost: undefined,
		gpsHardwareCost: undefined,
		repairsCost: undefined,
		debtsCost: undefined,
	},
	location: {
		hub: undefined,
	},
	brand: undefined,
	model: undefined,
	salePlans: undefined,
	hasTileDevice: undefined,
	hasGpsTracker: undefined,
	visualStatus: undefined,
	documentalStatus: undefined,
	gpsNoAssignReason: undefined,
	salesRanking: undefined,
};

export const colorsOptions = [
	{ label: 'Amarillo', value: 'yellow' },
	{ label: 'Rosa', value: 'pink' },
	{ label: 'Morado', value: 'purple' },
	{ label: 'Azul', value: 'blue' },
	{ label: 'Naranja', value: 'orange' },
	{ label: 'Verde', value: 'green' },
	{ label: 'Blanco', value: 'white' },
	{ label: 'Gris', value: 'gray' },
	{ label: 'Rojo', value: 'red' },
	{ label: 'Negro', value: 'black' },
	{ label: 'Grafito ', value: 'graphite' },
];

export const brakeTypeOptions = [
	{
		label: 'Tambor',
		value: BrakeType.DRUM,
	},
	{
		label: 'Discos',
		value: BrakeType.DISC,
	},
];
