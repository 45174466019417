/* eslint-disable no-nested-ternary */
import React, { useState, useContext, useEffect } from 'react';

// -----------------LIBRARIES IMPORTS------------------- //
import { Input, Typography } from 'design_system/src';

// -----------------DATA IMPORTS------------------- //

// -----------------STATIC IMPORTS------------------- //
import '../../../ozoner.scss';

// -----------------COMPONENTS IMPORTS------------------- //
import { toasterOptions } from 'models/shared/enums/toaster.enum';
import { toasterNotification } from 'models/shared/helpers/toasterNotification';
import { updateFinancialForm } from 'models/financial-form/controllers/financial-form.controller';
import {
	CollectionSegmentationEnum,
	LoanSegmentationEnum,
	PartnershipsEnum,
} from 'models/financial-form/enums/financial-form.enums';
import { updateLoan } from 'models/loan/controllers/loan.controller';
import { commonErrorHandler } from 'models/shared/helpers/errors';
import { ozonerContext } from '../../OzonerContext';
import {
	collectionSegmentationOptions,
	loanSegmentationOptions,
	partnershipSegmentationOptions,
} from './constants';

export function PartnershipSegmentation() {
	const { financialForm, updateFormData } = useContext(ozonerContext);

	const [loading, setLoading] = useState(false);

	const changeSegmentation = (newSegmentation: string) => {
		if (newSegmentation && financialForm?.form?._id) {
			setLoading(true);
			updateFinancialForm(financialForm.form._id, {
				loanApplication: { partnership: newSegmentation as PartnershipsEnum },
			})
				.then((newForm) => {
					toasterNotification({
						msg: 'Solicitud Exitosa',
						style: 'light',
						toasterType: toasterOptions.success,
					});
					updateFormData(newForm);
					setLoading(false);
				})
				.catch((err) => {
					commonErrorHandler(err);
					setLoading(false);
				});
		}
	};

	return (
		<div
			id="segmentacion-alianza"
			className="display_flex flex_justify_between flex_align_center flex_gap_md"
		>
			<Typography scale="medium" weight="400" className="text_no_break">
				Alianza lead
			</Typography>
			<Input
				title=""
				disabled={loading}
				name="segmentacion"
				placeholder="Selecciona alianza"
				value={financialForm.form.loanApplication?.partnership}
				onChange={(e: any) => changeSegmentation(e.target.value)}
				className="w_100_per"
				type="select"
				options={partnershipSegmentationOptions}
				variant="default"
			/>
		</div>
	);
}
export function LoanSegmentation() {
	const { financialForm, updateFormData } = useContext(ozonerContext);

	const [loading, setLoading] = useState(false);

	const changeSegmentation = (newSegmentation: string) => {
		if (newSegmentation && financialForm?.form?._id) {
			setLoading(true);
			updateFinancialForm(financialForm.form._id, {
				loanApplication: {
					loanSegmentation: newSegmentation as LoanSegmentationEnum,
				},
			})
				.then((newForm) => {
					toasterNotification({
						msg: 'Solicitud Exitosa',
						style: 'light',
						toasterType: toasterOptions.success,
					});
					updateFormData(newForm);
					setLoading(false);
				})
				.catch((err) => {
					commonErrorHandler(err);
					setLoading(false);
				});
		}
	};

	return (
		<div
			id="segmentacion-credito"
			className="display_flex flex_justify_between flex_align_center flex_gap_md"
		>
			<Typography scale="medium" weight="400" className="">
				Segmentacion de credito
			</Typography>
			<Input
				title=""
				disabled={loading}
				name="segmentacion"
				placeholder="Selecciona Segmentación"
				value={financialForm.form.loanApplication?.loanSegmentation}
				onChange={(e: any) => changeSegmentation(e.target.value)}
				className="w_100_per"
				type="select"
				options={loanSegmentationOptions}
				variant="default"
			/>
		</div>
	);
}
export function CollectionSegmentation() {
	const { financialForm, updateFormData } = useContext(ozonerContext);

	const [loading, setLoading] = useState(false);

	const changeSegmentation = (newSegmentation: string) => {
		if (newSegmentation && financialForm?.form?._id) {
			setLoading(true);
			updateFinancialForm(financialForm.form._id, {
				loanApplication: {
					collectionSegmentation: newSegmentation as CollectionSegmentationEnum,
				},
			})
				.then((newForm) => {
					toasterNotification({
						msg: 'Solicitud Exitosa',
						style: 'light',
						toasterType: toasterOptions.success,
					});
					updateFormData(newForm);
					setLoading(false);
				})
				.catch((err) => {
					commonErrorHandler(err);
					setLoading(false);
				});
		}
	};

	return (
		<div
			id="segmentacion-cobranza"
			className="display_flex flex_justify_between flex_align_center flex_gap_md"
		>
			<Typography scale="medium" weight="400" className="">
				Segmentacion de cobranza
			</Typography>
			<Input
				title=""
				disabled={loading}
				name="segmentacion"
				placeholder="Selecciona Segmentación"
				value={financialForm.form.loanApplication?.collectionSegmentation}
				onChange={(e: any) => changeSegmentation(e.target.value)}
				className="w_100_per"
				type="select"
				options={collectionSegmentationOptions}
				variant="default"
			/>
		</div>
	);
}

export function CollectionResponsible() {
	const { financialForm, updateFormData, ozoniosList, updateLoanData } =
		useContext(ozonerContext);

	const [loading, setLoading] = useState(false);
	const [collectionOptions, setCollectionOptions] = useState<
		{
			value: string;
			label: string;
		}[]
	>([]);

	const changeResponsible = (newResponsible: string) => {
		if (newResponsible) {
			setLoading(true);
			if (financialForm?.loan?._id) {
				updateLoan(financialForm.loan._id, {
					collectionResponsible: newResponsible,
				})
					.then((newLoan) => {
						toasterNotification({
							msg: 'Solicitud Exitosa',
							style: 'light',
							toasterType: toasterOptions.success,
						});
						updateLoanData(newLoan);
						setLoading(false);
					})
					.catch((err) => {
						commonErrorHandler(err);
						setLoading(false);
					});
			} else {
				updateFinancialForm(financialForm.form._id, {
					loanApplication: {
						collectionResponsible: newResponsible,
					},
				})
					.then((newForm) => {
						toasterNotification({
							msg: 'Solicitud Exitosa',
							style: 'light',
							toasterType: toasterOptions.success,
						});
						updateFormData(newForm);
						setLoading(false);
					})
					.catch((err) => {
						commonErrorHandler(err);
						setLoading(false);
					});
			}
		}
	};
	useEffect(() => {
		const ozoniosDesc = ozoniosList
			.filter((ozonio) =>
				['62e43be83fce23d2fb978518', '64f62a459df5f1f39e704cea'].includes(
					ozonio.value,
				),
			)
			.sort(({ label: a }, { label: b }) => a.localeCompare(b));
		setCollectionOptions(ozoniosDesc);
	}, [ozoniosList]);

	return (
		<div
			id="responsable-cobranza"
			className="display_flex flex_justify_between flex_align_center flex_gap_md"
		>
			<Typography scale="medium" weight="400" className="">
				Responsable de Cobranza
			</Typography>
			<Input
				title=""
				disabled={loading}
				name="estados"
				placeholder="Ingresa el nombre del Ozonio"
				value={
					financialForm?.loan?._id
						? financialForm.loan.collectionResponsible
						: financialForm?.form?.loanApplication?.collectionResponsible
				}
				onChange={(e: any) => changeResponsible(e.target.value)}
				className="w_100_per"
				type="select"
				options={collectionOptions}
				variant="default"
			/>
		</div>
	);
}
