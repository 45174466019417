import { PaginatedDocuments } from 'models/shared/types/pagination';
import { EvidenceDocumentType } from 'models/shared/types/documents';
import { API_BASE_ROUTES } from 'models/shared/types/apiRoutes';
import { buildPathWithQueryParams } from 'models/shared/helpers/utils';
import {
	OzonerFilterDto,
	PopulatedOzoner,
	UpdateOzonerDto,
	CreateOzonerDto,
	Ozoner,
} from '../interfaces/ozoner.interface';
import {
	get,
	put,
	post,
	deletes,
	axiosErrorHandler,
} from '../../shared/helpers/axiosInstance';

export const searchOzoner = async (
	query: string,
): Promise<PopulatedOzoner[]> => {
	try {
		const res = await get<PopulatedOzoner[]>(
			`${API_BASE_ROUTES.ozoner}/search?query=${query}`,
		);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};

export const findAllOzoners = async (
	dto: OzonerFilterDto,
): Promise<PaginatedDocuments<PopulatedOzoner>> => {
	try {
		const res = await get<PaginatedDocuments<PopulatedOzoner>>(
			buildPathWithQueryParams(API_BASE_ROUTES.ozoner, dto),
		);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};

export const findOzonerByCurp = async (
	query: string,
): Promise<PopulatedOzoner> => {
	try {
		const res = await get<PopulatedOzoner>(
			`${API_BASE_ROUTES.ozoner}/curp/${query}`,
		);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};

export const findOneOzoner = async (ozonerId: string): Promise<Ozoner> => {
	try {
		const res = await get<Ozoner>(`${API_BASE_ROUTES.ozoner}/${ozonerId}`);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};

export const createOzoner = async (
	dto: CreateOzonerDto,
): Promise<PopulatedOzoner> => {
	try {
		const res = await post<PopulatedOzoner>(API_BASE_ROUTES.ozoner, dto);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};

export const updateOzoner = async (
	ozonerId: string,
	dto: UpdateOzonerDto,
): Promise<PopulatedOzoner> => {
	try {
		const res = await put<PopulatedOzoner>(
			`${API_BASE_ROUTES.ozoner}/${ozonerId}`,
			dto,
		);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};

export const uploadFileOzoner = async (
	ozonerId: string,
	file: File,
	type: EvidenceDocumentType,
	alternativeName = '',
): Promise<PopulatedOzoner> => {
	try {
		const data = new FormData();
		data.append('name', alternativeName !== '' ? alternativeName : file.name);
		data.append('file', file);

		const res = await put<PopulatedOzoner>(
			`${API_BASE_ROUTES.ozoner}/${ozonerId}/${type}`,
			data,
			{
				headers: { 'Content-Type': 'multipart/form-data' },
			},
		);
		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};

export const deleteFileOzoner = async (
	ozonerId: string,
	docId: string,
): Promise<PopulatedOzoner> => {
	try {
		const res = await deletes<PopulatedOzoner>(
			`${API_BASE_ROUTES.ozoner}/${ozonerId}/document/${docId}`,
		);

		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};

export const sendPalencaLink = async (ozonerId: string): Promise<boolean> => {
	try {
		const res = await post<boolean>(
			`${API_BASE_ROUTES.ozoner}/${ozonerId}/palencaLink`,
		);

		return res.data;
	} catch (err: any) {
		throw axiosErrorHandler(err);
	}
};

export const getOzonerByVehicle = async (id: string): Promise<Ozoner> => {
	try {
		const res = await get<Ozoner>(`${API_BASE_ROUTES.ozoner}/vehicle/${id}`);
		return res.data;
	} catch (err: any) {
		throw err.response.data;
	}
};

export const getTruoraPdf = async (checkId: string): Promise<any> => {
	try {
		const path = `v1/truora/pdf/${checkId}`;

		const res = await get(path, {
			responseType: 'arraybuffer',
			responseEncoding: 'binary',
			maxBodyLength: Infinity,
		});
		return res.data;
	} catch (err: any) {
		throw err.response.data;
	}
};
