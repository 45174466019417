/* eslint-disable no-shadow */
export enum PaymentStatus {
	paid = 'paid',
	deleted = 'deleted',
}

export const PaymentStatusValues = {
	[PaymentStatus.paid]: 'Pago',
	[PaymentStatus.deleted]: 'Anulacion de pago',
};
