import moment from 'moment';
import {
	VehicleStatusValues,
	vehicleFields,
} from 'models/inventory/enums/vehicle.enum';
import {
	ozonerFields,
	OzonerStatusValues,
} from 'models/ozoner/enums/ozoner.enums';
import { EvidenceDocumentTypeValues } from 'models/shared/types/documents';
import {
	DevolutionReasonValues,
	financialFormFields,
	FormStatusValues,
	RejectedReasonValues,
} from 'models/financial-form/enums/financial-form.enums';
import { momentLocal } from 'models/shared/helpers/dates';
import {
	Change,
	Historic,
	HistoricFormated,
} from '../interfaces/historic.interface';

export const getTime = (time: string) => {
	const date = moment(time);

	const now = moment();
	const diffInHours = now.diff(date, 'hours');
	const diffInDays = now.diff(date, 'days');

	let result;

	if (diffInHours < 24) {
		result = `${diffInHours} Horas`;
	} else if (diffInDays < 7) {
		result = `${diffInDays} Dias ${date.format('YYYY-MM-DD')}`;
	} else {
		result = momentLocal(time).format('YYYY-MM-DD');
	}
	return result;
};

export const getOzonerMsg = (
	base: HistoricFormated,
	changes: Change[],
	context: any,
) => {
	const answer: HistoricFormated[] = [];
	if (changes.length > 5) {
		answer.push({
			...base,
			msg: `Se modificaron ${changes.length} campos en el ozoner`,
		});
	} else {
		let iterableChanges = changes.filter(
			(change) => change.field !== 'internalId',
		);
		if (
			iterableChanges.filter(
				(change) => change.field === 'status' || change.field === 'subStatus',
			).length === 2
		) {
			iterableChanges = iterableChanges.filter(
				(change) => change.field !== 'status',
			);
		}
		iterableChanges.forEach((change) => {
			let msg = '';
			let icon = 'refresh';
			switch (change.field) {
				case 'truoraId':
					msg = 'Se asigno un id de truora al ozoner';
					icon = 'eye';
					break;
				case 'palencaId':
					msg = 'Se asigno un id de palenca al ozoner';
					icon = 'eye';
					break;
				case 'truoraData':
					msg = 'Se actualizo la informacion de truora del ozoner';
					icon = 'eye';
					break;
				case 'palencaData':
					msg = 'Se actualizo la informacion de palenca del ozoner';
					icon = 'eye';
					break;
				case 'status':
					msg = `El estado del ozoner cambio de ${
						(OzonerStatusValues as any)[change.old]?.title
					} a ${(OzonerStatusValues as any)[change.updated]?.title}`;
					break;
				default:
					if (change.field.includes('documents.')) {
						icon = change.updated === '' ? 'document_red' : 'document';
						msg = `Se ${
							change.updated === '' ? 'elimino' : 'modifico'
						} el documento ${
							(EvidenceDocumentTypeValues as any)[
								change.field.split('.').reverse()[0]
							]?.title ?? 'otro'
						} del ozoner`;
					} else {
						msg = `Se modifico el campo ${
							(ozonerFields as any)[change.field]
						} del ozoner`;
					}
					break;
			}
			answer.push({ ...base, msg, icon });
		});
	}
	return answer;
};

const formMsg = (field: string, value: string, old: string) => {
	let msg = '';
	let icon = 'refresh';
	switch (field) {
		case 'vehicle':
			msg = `Se asigno el vehiculo ${value} a la solicitud`;
			break;
		case 'status':
			msg = `El estado de la solicitud cambio a ${
				(FormStatusValues as any)[value].title
			}`;
			break;
		case 'rejectedReason':
			msg = `La solicitud se rechazo por ${
				(RejectedReasonValues as any)[value]
			}`;
			break;
		case 'devolutionReason':
			msg = `La solicitud se devolvio por ${
				(DevolutionReasonValues as any)[value]
			}`;
			break;
		case 'loanDuration':
			msg = `El tiempo de financiamiento se cambio de ${old} a ${value}`;
			icon = 'document';
			break;
		default:
			if (field.includes('documents.')) {
				icon = value === '' ? 'document_red' : 'document';
				msg = `Se ${value === '' ? 'elimino' : 'modifico'} el documento ${
					(EvidenceDocumentTypeValues as any)[field.split('.').reverse()[0]]
						?.title ?? 'otro'
				} de la solicitud`;
			} else {
				msg = `Se modifico el campo ${
					(financialFormFields as any)[field]
				} de la solicitud`;
			}
			break;
	}
	return { msg, icon };
};
export const getFinancialFormMsg = (
	base: HistoricFormated,
	changes: Change[],
	context: any,
) => {
	const answer: HistoricFormated[] = [];
	if (changes.length > 5) {
		answer.push({
			...base,
			msg: `Se modificaron ${changes.length} campos en el ozoner`,
		});
	}
	//  else if (context.modelComplement === modelComplementEnum.payment) {
	// 	let type: any;
	// 	let amount;
	// 	let status;
	// 	changes.forEach((change) => {
	// 		switch (change.field) {
	// 			case 'typePayment':
	// 				type = change.updated;
	// 				break;
	// 			case 'payment.total':
	// 				amount = change.updated;
	// 				break;
	// 			case 'payment.status':
	// 				status = change.updated;
	// 				break;
	// 			default:
	// 				break;
	// 		}
	// 	});
	// 	const icon = 'document';
	// 	const msg = `Pago de ${
	// 		(typePayments as any)[type]
	// 	} recibido por valor de: ${amount}`;
	// 	answer.push({ ...base, msg, icon });
	// }
	else {
		let iterableChanges = changes.filter(
			(change) => change.field !== 'internalId',
		);
		if (
			iterableChanges.filter(
				(change) => change.field === 'status' || change.field === 'subStatus',
			).length === 2
		) {
			iterableChanges = iterableChanges.filter(
				(change) => change.field !== 'status',
			);
		}
		iterableChanges.forEach((change) => {
			if (typeof change.updated === 'string') {
				answer.push({
					...base,
					...formMsg(change.field, change.updated, change.old),
				});
			} else if (typeof change.updated === 'object') {
				Object.entries(change.updated as {}).forEach(([field, value]) => {
					answer.push({
						...base,
						...formMsg(field, value as any, (change.old as any)[field]),
					});
				});
			}
		});
	}
	return answer;
};

export const getVehicleMsg = (base: HistoricFormated, changes: Change[]) => {
	const answer: HistoricFormated[] = [];
	if (changes.length > 20) {
		answer.push({
			...base,
			msg: `Se modificaron ${changes.length} campos en el ozoner`,
		});
	} else {
		const iterableChanges = changes;
		iterableChanges.forEach((change) => {
			let msg = '';
			let icon = 'refresh';
			switch (change.field) {
				case 'created':
					msg = 'Se creo el vehiculo';
					icon = 'document';
					break;
				case 'status':
					msg = `El estado del vehículo cambio a ${
						(VehicleStatusValues as any)[change.updated]?.title
					}`;
					icon = 'eye';
					break;

				default:
					if (change.field.startsWith('documents.')) {
						icon = 'document';
						msg = `Se modifico el documento ${
							(EvidenceDocumentTypeValues as any)[
								change.field.replace('documents.', '')
							]?.title ?? 'otro'
						} del vehiculo`;
					} else {
						msg = `Se modifico el campo ${
							(vehicleFields as any)[change.field]
						} del vehiculo`;
					}
					break;
			}
			answer.push({ ...base, msg, icon });
		});
	}
	return answer;
};

export const translateHistory = (historyData: Historic[]) => {
	return historyData.reduce((acc: HistoricFormated[], history: Historic) => {
		const resume: HistoricFormated = {
			by: history.by,
			createdAt: getTime(history.createdAt),
			msg: '',
			icon: 'refresh',
		};
		switch (history.model) {
			case 'ozoner':
				return [...acc, ...getOzonerMsg(resume, history.changes, history)];
			case 'financialForm':
				return [
					...acc,
					...getFinancialFormMsg(resume, history.changes, history),
				];
			case 'vehicle':
				return [...acc, ...getVehicleMsg(resume, history.changes)];
			default:
				return [...acc, resume];
		}
	}, []);
};
