import React, { useContext, useEffect, useState } from 'react';

import Swal from 'sweetalert2';
import { Grid } from '@mui/material';

import { ReactComponent as Download } from 'design_system/src/static/icons/download-file.svg';
import { ReactComponent as Moto } from 'design_system/src/static/icons/motorcycle.svg';
import { ReactComponent as User } from 'design_system/src/static/icons/user-card-2.svg';
import { ReactComponent as Eye } from 'design_system/src/static/icons/eye.svg';
import { ReactComponent as Google } from 'design_system/src/static/icons/google.svg';

import '../../ozoner.scss';

import LoadDocuments from 'components/hocs/loadDocuments/loadDocuments';
import {
	Button,
	Typography,
	ModalConfirmation as Modal,
	Input,
} from 'design_system/src';
import { toasterOptions } from 'models/shared/enums/toaster.enum';
import { toasterNotification } from 'models/shared/helpers/toasterNotification';
import {
	EvidenceDocument,
	EvidenceDocumentType,
} from 'models/shared/types/documents';
import {
	deleteFileOzoner,
	updateOzoner,
	uploadFileOzoner,
} from 'models/ozoner/controllers/ozoner.controller';
import {
	createDocuments,
	deleteFileFinancialForm,
	deleteFileFullPaymentFinancialForm,
	uploadFileFinancialForm,
	uploadFileFullPaymentFinancialForm,
} from 'models/financial-form/controllers/financial-form.controller';
import { GenericFinancialForm } from 'models/financial-form/interfaces/financial-form.interfaces';
import { FinancialFormTypeEnum } from 'models/financial-form/enums/financial-form.enums';
import { commonErrorHandler } from 'models/shared/helpers/errors';
import { ozonerContext } from '../OzonerContext';

const formatName = (name: string) => name.replaceAll(' ', '_');

function DocumentsOzoner() {
	// -----------------DATA STATES------------------- //
	const [comment, setComment] = useState<string>('');
	const [openDownloadFiles, setOpenDownloadFiles] = useState(false);
	const [openFiles, setOpenFiles] = useState(false);
	const [rfc, setRfc] = useState('');
	const [loadingFiles, setLoadingFiles] = useState(false);

	const { ozoner, setOzoner, financialForm, updateFormData, updateComment } =
		useContext(ozonerContext);
	const [reloadDocuments, setReloadDocuments] = useState(false);
	useEffect(() => {
		setReloadDocuments(true);
		setTimeout(() => {
			setReloadDocuments(false);
		}, 50);
		console.log('reload');
	}, [financialForm]);

	useEffect(() => {
		setComment(ozoner?.comment ?? '');
		setRfc(ozoner?.rfc || ozoner?.curp.substring(0, 10) || '');
	}, [ozoner]);

	const deleteFiles = (file: { _id: string; typeEvidence: string }) => {
		if (ozoner) {
			deleteFileOzoner(ozoner._id, file._id)
				.then((newData) => {
					setOzoner({ ...ozoner, documents: newData.documents });
					toasterNotification({
						msg: 'Documento eliminado satisfactoriamente',
						style: 'light',
						toasterType: toasterOptions.success,
					});
				})
				.catch(commonErrorHandler);
		}
	};
	const deleteFilesForm = (file: { _id: string; typeEvidence: string }) => {
		if (ozoner && financialForm?.form) {
			(financialForm.form.type === FinancialFormTypeEnum.fullPayment
				? deleteFileFullPaymentFinancialForm
				: deleteFileFinancialForm)(financialForm.form._id, file._id)
				.then((newData) => {
					toasterNotification({
						msg: 'Documento eliminado satisfactoriamente',
						style: 'light',
						toasterType: toasterOptions.success,
					});
					updateFormData({
						...financialForm.form,
						documents: newData.documents,
					});
				})
				.catch(commonErrorHandler);
		}
	};

	const handleFile = (
		e: any,
		fileType: EvidenceDocumentType,
		isOzoner = true,
	) => {
		const file = e.target.files[0];
		if (
			file.size >
			(fileType === EvidenceDocumentType.videoEvidenceAddress ||
			fileType === EvidenceDocumentType.videoEvidenceWork
				? 30000000
				: 10000000)
		) {
			Swal.fire({
				text:
					fileType === EvidenceDocumentType.videoEvidenceAddress ||
					fileType === EvidenceDocumentType.videoEvidenceWork
						? 'El video no debe pesar mas de 30mb'
						: 'La imagen no debe pesar mas de 10mb',
				icon: 'warning',
				confirmButtonText: 'Aceptar',
			});
		} else if (ozoner) {
			const blob = file.slice(0, file.size, file.type);
			const newFile = new File([blob], formatName(file.name), {
				type: file.type,
			});
			if (isOzoner) {
				uploadFileOzoner(ozoner._id, newFile, fileType)
					.then((newData) => {
						setOzoner({ ...ozoner, documents: newData.documents });
						toasterNotification({
							msg: 'Documento cargado satisfactoriamente',
							style: 'light',
							toasterType: toasterOptions.success,
						});
					})
					.catch(commonErrorHandler);
			} else {
				(financialForm.form.type === FinancialFormTypeEnum.fullPayment
					? uploadFileFullPaymentFinancialForm
					: uploadFileFinancialForm)(
					financialForm?.form?._id,
					newFile,
					fileType,
				)
					.then((newData) => {
						updateFormData({
							...financialForm.form,
							documents: newData.documents,
						});
						toasterNotification({
							msg: 'Documento cargado satisfactoriamente',
							style: 'light',
							toasterType: toasterOptions.success,
						});
					})
					.catch(commonErrorHandler);
			}
		}
	};

	const openDrive = () => {
		window.open(process.env.REACT_APP_DOCUMENTS_DRIVE, '_blank');
	};

	const downloadFiles = () => {
		if (!ozoner) return;
		setLoadingFiles(true);
		updateOzoner(ozoner._id, { rfc })
			.then((data) => {
				setOzoner({ ...ozoner, rfc: data.rfc });
				return createDocuments(financialForm.form._id);
			})
			.then(() => {
				setLoadingFiles(false);
				setOpenDownloadFiles(false);
				setOpenFiles(true);
				toasterNotification({
					msg: 'Documentos generados',
					toasterType: toasterOptions.success,
					style: 'light',
				});
			})
			.catch((e) => {
				setLoadingFiles(false);
				toasterNotification({
					msg: `Algo fallo: ${e.message}`,
					toasterType: toasterOptions.error,
					style: 'light',
				});
			});
	};

	return (
		<>
			<div className="br_xxs bg_neutral_0 p_xs border_1 border_solid border_neutral_400 m_b_md">
				<div className="bg_neutral_100 br_xxs p_xs border_1 border_solid border_neutral_300">
					<div className="display_flex flex_justify_between flex_align_center">
						<Typography
							scale="medium"
							weight="600"
							textColor="neutral_900"
							className="m_y_md"
						>
							Documentación del Ozoner
						</Typography>
						<div className="flex_center flex_gap_md">
							<Button
								scale="small"
								onClick={() => {
									setOpenDownloadFiles(true);
								}}
								icon={<Download />}
							>
								Generar documentación
							</Button>

							<Button
								scale="small"
								onClick={() => {
									setOpenFiles(true);
								}}
								icon={<Eye />}
							>
								Ver documentación
							</Button>
						</div>
					</div>

					<Grid
						container
						direction="row"
						spacing={1}
						className="m_none w_100_per"
					>
						<LoadDocuments
							documents={ozoner?.documents ?? []}
							onDelete={(file: EvidenceDocument) => deleteFiles(file)}
							onAdd={handleFile}
							availableDocs={[
								EvidenceDocumentType.selfie,
								EvidenceDocumentType.ine_front,
								EvidenceDocumentType.ine_back,
								EvidenceDocumentType.license_front,
								EvidenceDocumentType.license_back,
								EvidenceDocumentType.proof_of_address,
								EvidenceDocumentType.videoEvidenceAddress,
								EvidenceDocumentType.videoEvidenceWork,
								EvidenceDocumentType.truora,
							]}
						/>
					</Grid>
					<Typography
						scale="medium"
						weight="600"
						textColor="neutral_900"
						className="m_y_md"
					>
						Documentación del Crédito
					</Typography>

					<Grid
						container
						direction="row"
						spacing={1}
						className="m_none w_100_per"
					>
						{!reloadDocuments ? (
							<LoadDocuments
								documents={[
									...(financialForm?.form?.documents ?? []),
									...(financialForm?.loan?.documents ?? []),
								]}
								onDelete={(file: any) => deleteFilesForm(file)}
								onAdd={(e: any, type: EvidenceDocumentType) =>
									handleFile(e, type, false)
								}
								availableDocs={
									financialForm.form.type === FinancialFormTypeEnum.loan
										? [
												EvidenceDocumentType.bank_certificate_1,
												EvidenceDocumentType.bank_certificate_2,
												EvidenceDocumentType.bank_certificate_3,
												EvidenceDocumentType.bank_certificate_4,
												EvidenceDocumentType.bank_certificate_5,
												EvidenceDocumentType.bank_certificate_6,
												EvidenceDocumentType.advancedmoney,
												EvidenceDocumentType.tax_situation,
												EvidenceDocumentType.deposit,
												EvidenceDocumentType.contract,
												EvidenceDocumentType.layawayReceipt,
												EvidenceDocumentType.promissory_note,
												EvidenceDocumentType.delivery_photo,
										  ]
										: [
												EvidenceDocumentType.contract,
												EvidenceDocumentType.deposit,
												EvidenceDocumentType.delivery_photo,
										  ]
								}
							/>
						) : null}
					</Grid>
				</div>
			</div>
			<div className="bg_neutral_0 br_xs border_1 border_solid border_neutral_400 p_x_lg p_y_sm">
				<Typography
					scale="large"
					weight="600"
					textColor="neutral_900"
					className="m_y_md"
				>
					Observaciones del ozoner :
				</Typography>
				<textarea
					className="observaciones w_100_per p_md border_1 border_solid border_neutral_400"
					placeholder="Agregue todas las observaciones del ozoner que se detecten"
					value={comment}
					onChange={(e) => setComment(e.target.value)}
				/>
				{comment !== ozoner?.comment && (
					<div className="display_flex flex_justify_end">
						<Button
							scale="small"
							variant="ghost"
							onClick={() => updateComment(comment)}
						>
							Guardar Cambios
						</Button>
						<Button
							scale="small"
							variant="ghost"
							onClick={() => setComment(ozoner?.comment ?? '')}
						>
							Cancelar
						</Button>
					</div>
				)}
			</div>
			{openFiles && (
				<Modal
					openConfirmation={openFiles}
					handleClose={() => setOpenFiles(false)}
					icon={<Eye className="text_primary_300" />}
					widthDesktop={40}
					isConfirmationLoading={false}
					actions={[
						{
							label: 'Salir',
							color: 'grey',
							action: () => setOpenFiles(false),
						},
					]}
				>
					<>
						<Typography scale="large" weight="600" className="m_b_xs">
							Ver documentación{' '}
						</Typography>
						<Typography
							scale="medium"
							weight="400"
							className="m_b_md"
							textColor="neutral_700"
						>
							Los documentos documentos fueron generados con exito
						</Typography>
						<Button
							scale="small"
							onClick={openDrive}
							icon={<Google />}
							className="w_100_per"
						>
							Ver documentos en Google Drive
						</Button>
					</>
				</Modal>
			)}
			{openDownloadFiles && (
				<Modal
					openConfirmation={openDownloadFiles}
					handleClose={() => setOpenDownloadFiles(false)}
					icon={<Moto className="text_primary_300" />}
					widthDesktop={40}
					isConfirmationLoading={false}
					actions={[
						{
							label: 'Cancelar',
							color: 'grey',
							action: () => setOpenDownloadFiles(false),
						},
						{
							label: 'Aceptar',
							color: 'primary',
							action: () => downloadFiles(),
							disabled: !rfc || loadingFiles,
						},
					]}
				>
					<>
						<Typography scale="large" weight="600" className="m_b_xs">
							Generación de documentos{' '}
						</Typography>
						<Typography
							scale="medium"
							weight="400"
							className="m_b_md"
							textColor="neutral_700"
						>
							Los documentos necesarios para el crédito se generaran con la
							información subida en la plataforma, Asegúrate que la información
							en la pestaña de datos este completa y sea correcta.
						</Typography>
						<Typography scale="medium" weight="600" className="m_b_xs">
							Evidencia de recuperación
						</Typography>
						<Input
							title="RFC del Ozoner"
							icon={<User />}
							value={rfc}
							onChange={(e) => setRfc(e.target.value)}
							name="rfc"
							type="text"
						/>
					</>
				</Modal>
			)}
		</>
	);
}
export default DocumentsOzoner;
