/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useState } from 'react';
import { Form, Formik, FormikValues } from 'formik';
import {
	SideModal,
	Typography,
	Input,
	Button,
	ChipPercentage,
} from 'design_system/src';
import * as yup from 'yup';
import _ from 'lodash';
import { ReactComponent as Refresh } from 'design_system/src/static/icons/refresh.svg';
import { ReactComponent as Document } from 'design_system/src/static/icons/document-ui.svg';
import { ReactComponent as Phone } from 'design_system/src/static/icons/phone.svg';
import { ReactComponent as Eye } from 'design_system/src/static/icons/eye.svg';
import { ReactComponent as Money } from 'design_system/src/static/icons/money.svg';
import './history.scss';
import { translateHistory } from 'models/historic/helpers/historic.helper';
import { findAllHistoric } from 'models/historic/controllers/historic.controller';
import {
	Historic,
	HistoricFormated,
} from 'models/historic/interfaces/historic.interface';
import { commonErrorHandler } from 'models/shared/helpers/errors';

interface HistoryProps {
	open: boolean;
	setOpen: Function;
	entityId: string[];
}

const icons: any = {
	refresh: (
		<div className="p_xs br_circle overflow_hidden display_flex bg_primary_100 flex_shrink_0">
			<Refresh className="dim_lg text_primary_300" />
		</div>
	),
	document: (
		<div className="p_xs br_circle overflow_hidden display_flex bg_green_100 flex_shrink_0">
			<Document className="dim_lg text_green_300" />
		</div>
	),
	document_red: (
		<div className="p_xs br_circle overflow_hidden display_flex bg_red_100 flex_shrink_0">
			<Document className="dim_lg text_red_300" />
		</div>
	),
	phone: (
		<div className="p_xs br_circle overflow_hidden display_flex bg_secondary_100 flex_shrink_0">
			<Phone className="dim_lg text_secondary_600" />
		</div>
	),
	eye: (
		<div className="p_xs br_circle overflow_hidden display_flex bg_secondary_100 flex_shrink_0">
			<Eye className="dim_lg text_secondary_600" />
		</div>
	),
	money: (
		<div className="p_xs br_circle overflow_hidden display_flex bg_green_100 flex_shrink_0">
			<Money className="dim_lg text_green_300" />
		</div>
	),
};

const History: FC<HistoryProps> = ({ open, setOpen, entityId }) => {
	const [historic, setHistoric] = useState<HistoricFormated[]>([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		let data: Historic[] = [];
		Promise.all(
			entityId.map((id) =>
				findAllHistoric(id).then((res) => {
					data = [...data, ...res.data];
				}),
			),
		)
			.then(() => {
				setLoading(false);
				setHistoric(
					translateHistory(
						data.sort(
							(a, b) =>
								new Date(b.createdAt).getTime() -
								new Date(a.createdAt).getTime(),
						),
					),
				);
			})
			.catch((e) => {
				setLoading(false);
				commonErrorHandler(e);
			});
	}, [entityId]);

	return (
		<SideModal open={open} setOpen={setOpen} size="small">
			<div className="flex_content_col">
				<Typography
					scale="heading4"
					weight="600"
					textColor="primary_300"
					className="text_center flex_header p_b_md"
				>
					Historial
				</Typography>
				<div className="flex_body">
					{loading ? (
						<Typography
							scale="medium"
							weight="400"
							textColor="neutral_900"
							className="text_center flex_header p_y_md"
						>
							Cargando datos...
						</Typography>
					) : historic.length ? (
						historic.map((change, i) => (
							<div
								className="p_y_lg historic_change bg_neutral_100 display_flex flex_gap_md flex_align_center"
								key={`${i}-${change.msg}`}
							>
								{icons[change.icon]}
								<div className="flex_grow_1">
									<Typography
										scale="small"
										weight="400"
										textColor="neutral_600"
										className=""
									>
										{`${change.by?.name ?? 'Sistema'}, ${change.createdAt}`}
									</Typography>
									<Typography
										scale="medium"
										weight="600"
										textColor="neutral_1000"
										className=""
									>
										{change.msg}
									</Typography>
								</div>
							</div>
						))
					) : (
						<Typography
							scale="medium"
							weight="400"
							textColor="neutral_900"
							className="text_center flex_header p_y_md"
						>
							No hay datos para mostrar
						</Typography>
					)}
				</div>
			</div>
		</SideModal>
	);
};

export default History;
