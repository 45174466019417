/* eslint-disable no-nested-ternary */
/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as yup from 'yup';

// icons
import { ReactComponent as Close } from 'design_system/src/static/icons/close-circle.svg';
import { ReactComponent as Stars } from 'design_system/src/static/icons/stars-profile.svg';
import { ReactComponent as Phone } from 'design_system/src/static/icons/numeric-password.svg';
import { ReactComponent as GPS } from 'design_system/src/static/icons/gps.svg';

import {
	Button,
	Input,
	Typography,
	SideModal,
	ModalConfirmation,
} from 'design_system/src';

import {
	createDevice,
	deleteDevice,
	updateDevice,
} from 'models/geozon/controllers/device.controller';
import { DeviceBrands } from 'models/geozon/enums/device.enum';
// css
import './devices.scss';
import { toasterOptions } from 'models/shared/enums/toaster.enum';
import {
	Device,
	DeviceCreateDto,
	DeviceUpdateDto,
} from 'models/geozon/interfaces/device.interface';
import { toasterNotification } from 'models/shared/helpers/toasterNotification';
import _ from 'lodash';
import { filterObjectByKeys } from 'models/shared/helpers/utils';
import { commonErrorHandler } from 'models/shared/helpers/errors';

const validationSchema = yup.object({
	phone: yup.string().required('Campo requerido'),
	iccid: yup.string().required('Campo requerido'),
	imei: yup.string().required('Campo requerido'),
	sn: yup.string().required('Campo requerido'),
	simBrand: yup.string().required('Campo requerido'),
	brand: yup.string().required('Campo requerido'),
});

const defaults: DeviceCreateDto | DeviceUpdateDto = {
	phone: '',
	iccid: '',
	imei: '',
	sn: '',
	simBrand: '',
	brand: undefined,
	status: undefined,
};
const brandOptions = ['TELCEL PREPAGO', 'EMNIFY'];

interface EditProps {
	open: boolean;
	setOpen: Function;
	setResult: Function;
	values?: Partial<Device> | null;
	disabled?: boolean;
}

function EditDevice({
	open,
	setOpen,
	values = null,
	setResult,
	disabled = false,
}: EditProps) {
	const [initial, setInitial] = useState<DeviceCreateDto | DeviceUpdateDto>(
		defaults,
	);
	const [loading, setLoading] = useState<boolean>(false);
	const [openDelete, setOpenDelete] = useState(false);

	useEffect(() => {
		let initialValue = defaults;
		if (values?._id) {
			initialValue = filterObjectByKeys(values, Object.keys(defaults) as any);
		}
		setInitial(initialValue);
	}, [values]);

	const deleteD = () => {
		if (values?._id) {
			deleteDevice(values!._id).then(() => setResult());
			setOpen(false);
			setOpenDelete(false);
		}
	};

	const onSubmit = (
		vals: DeviceCreateDto | DeviceUpdateDto,
		formik: FormikHelpers<DeviceCreateDto | DeviceUpdateDto>,
	) => {
		setLoading(true);

		if (values?._id) {
			updateDevice(vals as DeviceUpdateDto, values!._id)
				.then(async (ele) => {
					setOpen(false);
					setResult(ele);
					setInitial(defaults);
					formik.resetForm();
					setLoading(false);
				})
				.catch((e) => {
					setLoading(false);
					commonErrorHandler(e);
				});
		} else {
			createDevice(vals as DeviceCreateDto)
				.then(async (ele) => {
					setOpen(false);
					setResult(ele);
					setInitial(defaults);
					formik.resetForm();
					setLoading(false);
				})
				.catch((e) => {
					setLoading(false);
					commonErrorHandler(e);
				});
		}
	};

	return (
		<>
			<SideModal open={open} setOpen={setOpen} size="small">
				<Formik
					validateOnMount
					enableReinitialize
					initialValues={initial}
					onSubmit={onSubmit}
					validationSchema={validationSchema}
				>
					{(formik) => (
						<Form className="flex_content_col">
							<Typography
								scale="heading4"
								weight="600"
								textColor="neutral_900"
								className="text_center flex_header m_b_xl  "
							>
								{disabled
									? 'Informacion del Dispositivo'
									: values?.['_id']
									? 'Editar Dispositivo'
									: 'Nuevo Dispositivo'}
							</Typography>

							<div className="flex_body pretty_slider overflow_x_visible overflow_y_auto">
								<Input
									disabled={disabled}
									title="Teléfono"
									placeholder="Escribe el teléfono aquí"
									name="phone"
									type="text"
									icon={<Phone />}
									className="m_b_xs w_100_per"
									value={formik.values.phone}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										!!formik.errors['phone'] &&
										formik.getFieldMeta('phone').touched
									}
									subtitle={
										formik.getFieldMeta('phone').touched
											? (formik.errors['phone'] as string)
											: undefined
									}
								/>
								<Input
									disabled={disabled}
									title="ICCID"
									placeholder="Escribe el ICCID aquí"
									name="iccid"
									type="text"
									icon={<GPS />}
									className="m_b_xs w_100_per"
									value={formik.values.iccid}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										!!formik.errors['iccid'] &&
										formik.getFieldMeta('iccid').touched
									}
									subtitle={
										formik.getFieldMeta('iccid').touched
											? (formik.errors['iccid'] as string)
											: undefined
									}
								/>
								<Input
									disabled={disabled || values?.vehicle != null}
									title="IMEI"
									placeholder="Escribe el IMEI aquí"
									name="imei"
									type="text"
									icon={<GPS />}
									className="m_b_xs w_100_per"
									value={formik.values.imei}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										!!formik.errors['imei'] &&
										formik.getFieldMeta('imei').touched
									}
									subtitle={
										formik.getFieldMeta('imei').touched
											? (formik.errors['imei'] as string)
											: undefined
									}
								/>
								<Input
									disabled={disabled}
									title="SN"
									placeholder="Escribe el SN aquí"
									name="sn"
									type="text"
									icon={<GPS />}
									className="m_b_xs w_100_per"
									value={formik.values.sn}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										!!formik.errors['sn'] && formik.getFieldMeta('sn').touched
									}
									subtitle={
										formik.getFieldMeta('sn').touched
											? (formik.errors['sn'] as string)
											: undefined
									}
								/>
								<Input
									disabled={disabled}
									title="Operador Telefónico"
									placeholder="Escribe el operador telefónico aquí"
									name="simBrand"
									type="select"
									icon={<GPS />}
									className="m_b_xs w_100_per"
									value={formik.values.simBrand}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									options={brandOptions}
									error={
										!!formik.errors['simBrand'] &&
										formik.getFieldMeta('simBrand').touched
									}
									subtitle={
										formik.getFieldMeta('simBrand').touched
											? (formik.errors['simBrand'] as string)
											: undefined
									}
								/>
								<Input
									disabled={disabled}
									title="Marca GPS"
									placeholder="Selecciona la marca"
									name="brand"
									type="select"
									options={[DeviceBrands.COBAN_4G, DeviceBrands.SEEWORLD]}
									icon={<Stars />}
									className="m_b_xs w_100_per"
									value={formik.values.brand}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										!!formik.errors['brand'] &&
										formik.getFieldMeta('brand').touched
									}
									subtitle={
										formik.getFieldMeta('brand').touched
											? (formik.errors['brand'] as string)
											: undefined
									}
								/>
							</div>
							{!disabled && (
								<div className="flex_footer">
									<Button
										scale="large"
										orientation="right"
										className="w_100_per"
										type="submit"
										disabled={loading || !formik.isValid || !formik.dirty}
									>
										Guardar
									</Button>
									<Button
										scale="small"
										orientation="right"
										variant="ghost"
										icon={<Close />}
										className={`m_b_xs w_100_per text_red_300 ${
											values?._id ? '' : 'display_none'
										}`}
										onClick={() => setOpenDelete(true)}
									>
										Eliminar Dispositivo
									</Button>
								</div>
							)}
						</Form>
					)}
				</Formik>
			</SideModal>
			<ModalConfirmation
				openConfirmation={openDelete}
				handleClose={() => {
					setOpenDelete(false);
				}}
				icon={<Close />}
				isConfirmationLoading={false}
				widthDesktop={40}
				actions={[
					{
						label: 'Cancelar',
						color: 'grey',
						action: () => {
							setOpenDelete(false);
						},
					},
					{
						label: 'Eliminar dispositivo',
						color: 'primary',
						action: () => {
							deleteD();
						},
						loading,
					},
				]}
				title={
					<Typography scale="large" weight="400">
						¿Estás seguro de que{' '}
						<Typography scale="large" weight="600" component="span">
							deseas eliminar el dispositivo?
						</Typography>
					</Typography>
				}
				subtitle={
					<Typography scale="medium" weight="400" textColor="neutral_700">
						Se perderan todos los datos del mismo
					</Typography>
				}
			/>
		</>
	);
}

export default EditDevice;
