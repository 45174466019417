import React, { useState } from 'react';

import { useSearchParams } from 'react-router-dom';

// images
import img1 from 'static/images/login1.svg';
import img2 from 'static/images/login2.svg';

// css
import '../auth/auth.scss';

import { useFormik } from 'formik';
import * as yup from 'yup';
import CircularProgress from '@mui/material/CircularProgress';

// logos
import logo from 'static/logos/logo_pandaboard.svg';

// css
import './recover.scss';

// icons
import { ReactComponent as Lock } from 'design_system/src/static/icons/lock-square.svg';
import { Input, Button, Typography } from 'design_system/src';
import Swal from 'sweetalert2';
import { fetchRecover } from 'models/ozonio/controllers/ozonios.controller';

const validationSchema = yup.object({
	password: yup
		.string()
		.min(8, 'Contraseña muy corta - se necesitan mínimo 8 caracteres')
		.required('Campo requerido'),
	repassword: yup
		.string()
		.oneOf([yup.ref('password'), null], 'Las contraseñas deben ser iguales')
		.required('Campo requerido'),
});

const Recover = () => {
	const [searchParams] = useSearchParams();
	const token = searchParams.getAll('token')[0] || '';
	if (token === '') {
		window.location.href = '/auth';
	}

	const [loading, setloading] = useState<boolean>(false);

	const onSubmit = (value: { password: string; repassword: string }) => {
		setloading(true);
		fetchRecover({ token, password: value.password })
			.then(() => {
				setloading(false);
				Swal.fire({
					title: 'Contraseña actualizada!',
					text: 'Ir a login',
					icon: 'success',
					confirmButtonText: 'Ir a Login',
				}).then((result) => {
					if (result.isConfirmed) {
						window.location.href = '/auth';
					}
				});
			})
			.catch(() => {
				setloading(false);
				Swal.fire({
					title: 'Error!',
					text: 'Token expirado',
					icon: 'error',
					confirmButtonText: 'Aceptar',
				}).then((result) => {
					if (result.isConfirmed) {
						window.location.href = '/auth';
					}
				});
			});
	};

	const formik = useFormik({
		initialValues: {
			password: '',
			repassword: '',
			token: '',
		},
		validationSchema,
		onSubmit,
	});
	return (
		<div className="root flex_center">
			<img alt="circulos" src={img1} className="img1 display_none_mobile" />
			<img alt="orange" src={img2} className="img2  display_none_mobile" />
			<div
				className="
        dso_card 
        login_card 
        bg_neutral_0 
        p_xxl 
        shadow_hard"
			>
				<img alt="Logo" src={logo} className="logo center_x m_b_xxl" />
				<form onSubmit={formik.handleSubmit}>
					<Typography
						scale="medium"
						weight="600"
						className=" m_b_md text_center"
					>
						Restaurar Contraseña
					</Typography>

					<Input
						title="Nueva Contraseña"
						type="password"
						name="password"
						placeholder="Escriba su contraseña aquí"
						value={formik.values.password}
						onChange={formik.handleChange}
						icon={<Lock />}
						className="m_b_md dso_width_100_per"
					/>
					<Input
						title="Repite tu Nueva Contraseña"
						type="password"
						name="repassword"
						placeholder="Repita su contraseña nuevamente"
						value={formik.values.repassword}
						onChange={formik.handleChange}
						icon={<Lock />}
						className="m_b_md dso_width_100_per"
					/>
					<Button
						type="submit"
						disabled={loading || !formik.isValid}
						scale="small"
						className="w_100_per m_b_md m_t_xxs"
					>
						{loading ? <CircularProgress className="loading" /> : 'Ingresar'}
					</Button>
				</form>
			</div>
		</div>
	);
};

export default Recover;
